import React from 'react'
import GeneralNovelyContainer from '../components/GeneralNovelyContainer'
import Novelty from './Novelty'


const NoveltyContainer = (props) => {
    return (
        <GeneralNovelyContainer
            isMobile = {true}
            novelty = {Novelty}
            id = {props.id}
        />
    )
}

export default NoveltyContainer