export const dataSection_1 = [
  ["MINS-2.0-S-4.0-TI", "MINS-2.0-S-5.0-TI", "MINS-2.0-S-6.0-TI", "MINS-2.0-S-7.0-TI", "MINS-2.0-S-8.0-TI", "MINS-2.0-S-9.0-TI", "MINS-2.0-S-10.0-TI", "MINS-2.0-S-11.0-TI", "MINS-2.0-S-12.0-TI", "MINS-2.0-S-13.0-TI", "MINS-2.0-S-14.0-TI", "MINS-2.0-S-15.0-TI", "MINS-2.0-S-16.0-TI", "MINS-2.0-S-17.0-TI", "MINS-2.0-S-18.0-TI", "MINS-2.0-S-19.0-TI", "MINS-2.0-S-20.0-TI"],
  ["Ø2.0 x 4 mm.", "Ø2.0 x 5 mm.", "Ø2.0 x 6 mm.", "Ø2.0 x 7 mm.", "Ø2.0 x 8 mm.", "Ø2.0 x 9 mm.", "Ø2.0 x 10 mm.", "Ø2.0 x 11 mm.", "Ø2.0 x 12 mm.", "Ø2.0 x 13 mm.", "Ø2.0 x 14 mm.", "Ø2.0 x 15 mm.", "Ø2.0 x 16 mm.", "Ø2.0 x 17 mm.", "Ø2.0 x 18 mm.", "Ø2.0 x 19 mm.", "Ø2.0 x 20 mm."]
]
export const dataSection_2 = [
  ["MINS-2.0-C-4.0-TI-SC", "MINS-2.0-C-5.0-TI-SC", "MINS-2.0-C-6.0-TI-SC", "MINS-2.0-C-7.0-TI-SC", "MINS-2.0-C-8.0-TI-SC", "MINS-2.0-C-9.0-TI-SC", "MINS-2.0-C-10.0-TI-SC", "MINS-2.0-C-11.0-TI-SC", "MINS-2.0-C-12.0-TI-SC", "MINS-2.0-C-13.0-TI-SC", "MINS-2.0-C-14.0-TI-SC", "MINS-2.0-C-15.0-TI-SC", "MINS-2.0-C-16.0-TI-SC", "MINS-2.0-C-17.0-TI-SC", "MINS-2.0-C-18.0-TI-SC", "MINS-2.0-C-19.0-TI-SC", "MINS-2.0-C-20.0-TI-SC"],
  ["Ø2.0 x 4 mm.", "Ø2.0 x 5 mm.", "Ø2.0 x 6 mm.", "Ø2.0 x 7 mm.", "Ø2.0 x 8 mm.", "Ø2.0 x 9 mm.", "Ø2.0 x 10 mm.", "Ø2.0 x 11 mm.", "Ø2.0 x 12 mm.", "Ø2.0 x 13 mm.", "Ø2.0 x 14 mm.", "Ø2.0 x 15 mm.", "Ø2.0 x 16 mm.", "Ø2.0 x 17 mm.", "Ø2.0 x 18 mm.", "Ø2.0 x 19 mm.", "Ø2.0 x 20 mm."]
]
export const dataSection_3 = [
  ["MINS-2.0-C-4.0-TI-SCD", "MINS-2.0-C-5.0-TI-SCD", "MINS-2.0-C-6.0-TI-SCD", "MINS-2.0-C-7.0-TI-SCD", "MINS-2.0-C-8.0-TI-SCD", "MINS-2.0-C-9.0-TI-SCD", "MINS-2.0-C-10.0-TI-SCD", "MINS-2.0-C-11.0-TI-SCD", "MINS-2.0-C-12.0-TI-SCD", "MINS-2.0-C-13.0-TI-SCD", "MINS-2.0-C-14.0-TI-SCD", "MINS-2.0-C-15.0-TI-SCD", "MINS-2.0-C-16.0-TI-SCD", "MINS-2.0-C-17.0-TI-SCD", "MINS-2.0-C-18.0-TI-SCD", "MINS-2.0-C-19.0-TI-SCD", "MINS-2.0-C-20.0-TI-SCD"],
  ["Ø2.0 x 4 mm.", "Ø2.0 x 5 mm.", "Ø2.0 x 6 mm.", "Ø2.0 x 7 mm.", "Ø2.0 x 8 mm.", "Ø2.0 x 9 mm.", "Ø2.0 x 10 mm.", "Ø2.0 x 11 mm.", "Ø2.0 x 12 mm.", "Ø2.0 x 13 mm.", "Ø2.0 x 14 mm.", "Ø2.0 x 15 mm.", "Ø2.0 x 16 mm.", "Ø2.0 x 17 mm.", "Ø2.0 x 18 mm.", "Ø2.0 x 19 mm.", "Ø2.0 x 20 mm."]
]
export const dataSection_4 = [
  ["MINS-2.0-H-4.0-TI-SC", "MINS-2.0-H-5.0-TI-SC", "MINS-2.0-H-6.0-TI-SC", "MINS-2.0-H-7.0-TI-SC", "MINS-2.0-H-8.0-TI-SC", "MINS-2.0-H-9.0-TI-SC", "MINS-2.0-H-10.0-TI-SC", "MINS-2.0-H-11.0-TI-SC", "MINS-2.0-H-12.0-TI-SC", "MINS-2.0-H-13.0-TI-SC", "MINS-2.0-H-14.0-TI-SC", "MINS-2.0-H-15.0-TI-SC", "MINS-2.0-H-16.0-TI-SC", "MINS-2.0-H-17.0-TI-SC", "MINS-2.0-H-18.0-TI-SC", "MINS-2.0-H-19.0-TI-SC", "MINS-2.0-H-20.0-TI-SC"],
  ["Ø2.0 x 4 mm.", "Ø2.0 x 5 mm.", "Ø2.0 x 6 mm.", "Ø2.0 x 7 mm.", "Ø2.0 x 8 mm.", "Ø2.0 x 9 mm.", "Ø2.0 x 10 mm.", "Ø2.0 x 11 mm.", "Ø2.0 x 12 mm.", "Ø2.0 x 13 mm.", "Ø2.0 x 14 mm.", "Ø2.0 x 15 mm.", "Ø2.0 x 16 mm.", "Ø2.0 x 17 mm.", "Ø2.0 x 18 mm.", "Ø2.0 x 19 mm.", "Ø2.0 x 20 mm."]
]
export const dataSection_5 = [
  ["MINS-2.0-Q-4.0-TI-SC", "MINS-2.0-Q-5.0-TI-SC", "MINS-2.0-Q-6.0-TI-SC", "MINS-2.0-Q-7.0-TI-SC", "MINS-2.0-Q-8.0-TI-SC", "MINS-2.0-Q-9.0-TI-SC", "MINS-2.0-Q-10.0-TI-SC", "MINS-2.0-Q-11.0-TI-SC", "MINS-2.0-Q-12.0-TI-SC", "MINS-2.0-Q-13.0-TI-SC", "MINS-2.0-Q-14.0-TI-SC", "MINS-2.0-Q-15.0-TI-SC", "MINS-2.0-Q-16.0-TI-SC", "MINS-2.0-Q-17.0-TI-SC", "MINS-2.0-Q-18.0-TI-SC", "MINS-2.0-Q-19.0-TI-SC", "MINS-2.0-Q-20.0-TI-SC"],
  ["Ø2.0 x 4 mm.", "Ø2.0 x 5 mm.", "Ø2.0 x 6 mm.", "Ø2.0 x 7 mm.", "Ø2.0 x 8 mm.", "Ø2.0 x 9 mm.", "Ø2.0 x 10 mm.", "Ø2.0 x 11 mm.", "Ø2.0 x 12 mm.", "Ø2.0 x 13 mm.", "Ø2.0 x 14 mm.", "Ø2.0 x 15 mm.", "Ø2.0 x 16 mm.", "Ø2.0 x 17 mm.", "Ø2.0 x 18 mm.", "Ø2.0 x 19 mm.", "Ø2.0 x 20 mm."]
]
export const dataSection_6 = [
  ["MINS-2.3-ES-4.0-TI", "MINS-2.3-ES-5.0-TI", "MINS-2.3-ES-6.0-TI", "MINS-2.3-ES-7.0-TI", "MINS-2.3-ES-8.0-TI", "MINS-2.3-ES-9.0-TI", "MINS-2.3-ES-10.0-TI", "MINS-2.3-ES-11.0-TI", "MINS-2.3-ES-12.0-TI", "MINS-2.3-ES-13.0-TI", "MINS-2.3-ES-14.0-TI", "MINS-2.3-ES-15.0-TI", "MINS-2.3-ES-16.0-TI", "MINS-2.3-ES-17.0-TI", "MINS-2.3-ES-18.0-TI", "MINS-2.3-ES-19.0-TI", "MINS-2.3-ES-20.0-TI"],
  ["Ø2.3 x 4 mm.", "Ø2.3 x 5 mm.", "Ø2.3 x 6 mm.", "Ø2.3 x 7 mm.", "Ø2.3 x 8 mm.", "Ø2.3 x 9 mm.", "Ø2.3 x 10 mm.", "Ø2.3 x 11 mm.", "Ø2.3 x 12 mm.", "Ø2.3 x 13 mm.", "Ø2.3 x 14 mm.", "Ø2.3 x 15 mm.", "Ø2.3 x 16 mm.", "Ø2.3 x 17 mm.", "Ø2.3 x 18 mm.", "Ø2.3 x 19 mm.", "Ø2.3 x 20 mm."]
]
export const dataSection_7 = [
  ["MINS-2.3-EC-4.0-TI-SC", "MINS-2.3-EC-5.0-TI-SC", "MINS-2.3-EC-6.0-TI-SC", "MINS-2.3-EC-7.0-TI-SC", "MINS-2.3-EC-8.0-TI-SC", "MINS-2.3-EC-9.0-TI-SC", "MINS-2.3-EC-10.0-TI-SC", "MINS-2.3-EC-11.0-TI-SC", "MINS-2.3-EC-12.0-TI-SC", "MINS-2.3-EC-13.0-TI-SC", "MINS-2.3-EC-14.0-TI-SC", "MINS-2.3-EC-15.0-TI-SC", "MINS-2.3-EC-16.0-TI-SC", "MINS-2.3-EC-17.0-TI-SC", "MINS-2.3-EC-18.0-TI-SC", "MINS-2.3-EC-19.0-TI-SC", "MINS-2.3-EC-20.0-TI-SC"],
  ["Ø2.3 x 4 mm.", "Ø2.3 x 5 mm.", "Ø2.3 x 6 mm.", "Ø2.3 x 7 mm.", "Ø2.3 x 8 mm.", "Ø2.3 x 9 mm.", "Ø2.3 x 10 mm.", "Ø2.3 x 11 mm.", "Ø2.3 x 12 mm.", "Ø2.3 x 13 mm.", "Ø2.3 x 14 mm.", "Ø2.3 x 15 mm.", "Ø2.3 x 16 mm.", "Ø2.3 x 17 mm.", "Ø2.3 x 18 mm.", "Ø2.3 x 19 mm.", "Ø2.3 x 20 mm."]
]
export const dataSection_8 = [
  ["MINS-2.3-EH-4.0-TI-SC", "MINS-2.3-EH-5.0-TI-SC", "MINS-2.3-EH-6.0-TI-SC", "MINS-2.3-EH-7.0-TI-SC", "MINS-2.3-EH-8.0-TI-SC", "MINS-2.3-EH-9.0-TI-SC", "MINS-2.3-EH-10.0-TI-SC", "MINS-2.3-EH-11.0-TI-SC", "MINS-2.3-EH-12.0-TI-SC", "MINS-2.3-EH-13.0-TI-SC", "MINS-2.3-EH-14.0-TI-SC", "MINS-2.3-EH-15.0-TI-SC", "MINS-2.3-EH-16.0-TI-SC", "MINS-2.3-EH-17.0-TI-SC", "MINS-2.3-EH-18.0-TI-SC", "MINS-2.3-EH-19.0-TI-SC", "MINS-2.3-EH-20.0-TI-SC"],
  ["Ø2.3 x 4 mm.", "Ø2.3 x 5 mm.", "Ø2.3 x 6 mm.", "Ø2.3 x 7 mm.", "Ø2.3 x 8 mm.", "Ø2.3 x 9 mm.", "Ø2.3 x 10 mm.", "Ø2.3 x 11 mm.", "Ø2.3 x 12 mm.", "Ø2.3 x 13 mm.", "Ø2.3 x 14 mm.", "Ø2.3 x 15 mm.", "Ø2.3 x 16 mm.", "Ø2.3 x 17 mm.", "Ø2.3 x 18 mm.", "Ø2.3 x 19 mm.", "Ø2.3 x 20 mm."]
]
export const dataSection_9 = [
  ["MINS-2.3-EQ-4.0-TI-SC", "MINS-2.3-EQ-5.0-TI-SC", "MINS-2.3-EQ-6.0-TI-SC", "MINS-2.3-EQ-7.0-TI-SC", "MINS-2.3-EQ-8.0-TI-SC", "MINS-2.3-EQ-9.0-TI-SC", "MINS-2.3-EQ-10.0-TI-SC", "MINS-2.3-EQ-11.0-TI-SC", "MINS-2.3-EQ-12.0-TI-SC", "MINS-2.3-EQ-13.0-TI-SC", "MINS-2.3-EQ-14.0-TI-SC", "MINS-2.3-EQ-15.0-TI-SC", "MINS-2.3-EQ-16.0-TI-SC", "MINS-2.3-EQ-17.0-TI-SC", "MINS-2.3-EQ-18.0-TI-SC", "MINS-2.3-EQ-19.0-TI-SC", "MINS-2.3-EQ-20.0-TI-SC"],
  ["Ø2.3 x 4 mm.", "Ø2.3 x 5 mm.", "Ø2.3 x 6 mm.", "Ø2.3 x 7 mm.", "Ø2.3 x 8 mm.", "Ø2.3 x 9 mm.", "Ø2.3 x 10 mm.", "Ø2.3 x 11 mm.", "Ø2.3 x 12 mm.", "Ø2.3 x 13 mm.", "Ø2.3 x 14 mm.", "Ø2.3 x 15 mm.", "Ø2.3 x 16 mm.", "Ø2.3 x 17 mm.", "Ø2.3 x 18 mm.", "Ø2.3 x 19 mm.", "Ø2.3 x 20 mm."]
]