import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_1.png"
import Image_2_2 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_2.png"
import Image_2_3 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_3.png"
import Image_2_4 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_4.png"
import Image_2_5 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_5.png"
import Image_2_6 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_6.png"
import Image_2_7 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Instruments/image_8.png"
import Image_2_8 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_8.png"
import Image_2_9 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_9.png"
import Image_2_10 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_10.png"
import Image_2_11 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_11.png"
import Image_2_12 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_12.png"
import Image_2_13 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_13.png"
import Image_2_14 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_14.png"
import Image_2_15 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_15.png"
import Image_2_18 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_18.png"
import Image_2_19 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_19.png"
import Image_2_20 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Instruments/image_20.png"

export default function dataGenerator(lng) {

  const instrumentsData_1_en = [" Plate and Wire cutting Plier with TC 18 cm.", " Plate bending plier with pin 13.5 cm.", " ADERER Plate bending plier 12.5 cm.", " Flat plier 13.5 cm."]
  const instrumentsData_1_es = [" Alicate de corte de placa y alambre con TC 18 cm.s", "Alicate para doblar placas con pin 13.5 cm.", "ADERER Alicate para doblar placas 12.5 cm.", "Alicate plano 13.5 cm."]

  const instrumentsData_2_en = [" Plate holding Clamps 14 cm. Straight.", " CUSHING Plate/Screw holding forcep 17.5 cm. Straight.", " CUSHING Plate/Screw holding forcep 17.5 cm. Angled."]
  const instrumentsData_2_es = [" Clamps de sujeción de placa 14 cm. Recta.", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Recta.", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Angulada."]

  const instrumentsData_3_en = [" Screwdriver detachable complete. Standard Head.", " Screwdriver detachable complete. Cross Head.", " Screwdriver detachable complete. Square Head."]
  const instrumentsData_3_es = [" Destornillador desmontable completo. Standard Head.", " Destornillador desmontable completo. Cross Head.", " Destornillador desmontable completo. Square Head."]

  const instrumentsData_4_en = [" Screwdriver detachable complete. Self Fitting. Cross Head.", " Screwdriver detachable complete. Self Fitting. Square Head."]
  const instrumentsData_4_es = [" Destornillador desmontable completo. Autoajustable. Cross Head.", " Destornillador desmontable completo. Autoajustable. Square Head."]

  const instrumentsData_5_en = [" Screwdriver fixed complete. Standard Head.", " Screwdriver fixed complete. Cross Head.", " Screwdriver fixed complete. Square Head."]
  const instrumentsData_5_es = [" Destornillador fijo completo. Standard Head.", " Destornillador fijo completo. Cross Head.", " Destornillador fijo completo. Square Head."]

  const instrumentsData_6_en = [" Screwdriver fixed complete. Self Fitting. Cross Head.", " Screwdriver fixed complete. Self Fitting. Square Head.", " Depth Gauge 18 cm."]
  const instrumentsData_6_es = [" Destornillador fijo completo. Autoajustable. Cross Head.", " Destornillador fijo completo. Autoajustable. Square Head.", " Medidor de profundidad 18 cm."]

  const instrumentsData_7_en = [" Spiral-Drill with Sk-connection"," Spiral-Drill with Sk-connection"," Spiral-Drill with Sk-connection"," Spiral-Drill with Sk-connection"]
  const instrumentsData_7_es = [" Spiral-Drill con conexión-Sk", " Spiral-Drill con conexión-Sk", " Spiral-Drill con conexión-Sk", " Spiral-Drill con conexión-Sk"]

  const instrumentsData_8_en = [" Spiral-Drill with Sk-connection"," Spiral-Drill with Sk-connection"," Spiral-Drill with Sk-connection"," Spiral-Drill with Sk-connection"]
  const instrumentsData_8_es = [" Spiral-Drill con conexión-Sk", " Spiral-Drill con conexión-Sk", " Spiral-Drill con conexión-Sk", " Spiral-Drill con conexión-Sk"]

  const instrumentsData_1 = [
    ["F1.5-70418", "F1.5-70213", "F1.5-70112", "F1.5-70313"],
    lng === "en" ? instrumentsData_1_en : instrumentsData_1_es,
    [Image_2_1, Image_2_2, Image_2_3, Image_2_4]
  ]
  const instrumentsData_2 = [
    ["F1.5-50013", "F1.5-40018", "F1.5-45018"],
    lng === "en" ? instrumentsData_2_en : instrumentsData_2_es,
    [Image_2_5, Image_2_6, Image_2_7]
  ]
  const instrumentsData_3 = [
    ["F1.5-20117", "F1.5-20217", "F1.5-20417"],
    lng === "en" ? instrumentsData_3_en : instrumentsData_3_es,
    [Image_2_8, Image_2_9, Image_2_10]
  ]
  const instrumentsData_4 = [
    ["F1.5-20217S", "F1.5-20417S"],
    lng === "en" ? instrumentsData_4_en : instrumentsData_4_es,
    [Image_2_11, Image_2_12]
  ]
  const instrumentsData_5 = [
    ["F1.5-2F117", "F1.5-2F217", "F1.5-2F417"],
    lng === "en" ? instrumentsData_5_en : instrumentsData_5_es,
    [Image_2_13, Image_2_14, Image_2_15]
  ]
  const instrumentsData_6 = [
    ["F1.5-2F217S", "F1.5-2F417S", "F1.5-20018"],
    lng === "en" ? instrumentsData_6_en : instrumentsData_6_es,
    [Image_2_15, Image_2_15, Image_2_18]
  ]
  const instrumentsData_7 = [
    ["F1.5-11005", "F1.5-11007", "F1.5-11009", "F1.5-11015"],
    lng === "en" ? instrumentsData_7_en : instrumentsData_7_es,
    [" Ø 1.1 mm. 5 mm stop. 45 mm.", " Ø 1.1 mm. 7 mm stop. 45 mm.", " Ø 1.1 mm. 9 mm stop. 45 mm.", " Ø 1.1 mm. 15 mm stop. 45 mm."],
    [Image_2_19, Image_2_19, Image_2_19, Image_2_19]
  ]
  const instrumentsData_8 = [
    ["F1.5-14005", "F1.5-14007", "F1.5-14009", "F1.5-14015"],
    lng === "en" ? instrumentsData_8_en : instrumentsData_8_es,
    [" Ø 1.4 mm. 5 mm stop. 45 mm.", " Ø 1.4 mm. 7 mm stop. 45 mm.", " Ø 1.4 mm. 9 mm stop. 45 mm.", " Ø 1.4 mm. 15 mm stop. 45 mm."],
    [Image_2_20, Image_2_20, Image_2_20, Image_2_20]
  ]


  let data = {
    instrumentsData_1,
    instrumentsData_2,
    instrumentsData_3,
    instrumentsData_4,
    instrumentsData_5,
    instrumentsData_6,
    instrumentsData_7,
    instrumentsData_8
  }
  return data;
}