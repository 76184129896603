import styled from 'styled-components'

const Styles = styled.div`
  p{
    font-size:13px;
    font-weight:400;
    line-height:16px;
    z-index:100;
    text-align:left;
  }
`

export default Styles