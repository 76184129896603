import styled from "styled-components";

const ModalStyles = styled.div`
    position:relative;
		width:100%;
		height:100%;
    .border-h1{
        border-bottom: 1px solid #909090;
        width: 97.7%;
        position: absolute;
        right: 1.2%;
    }
    .card-info{
        background: linear-gradient(to bottom,#8965FF,#5930E3 ) !important;
        width:100%;
		border-radius:25px;
		padding:30px;
		h1{
		color:#FFFFFF;
		font-weight:700;
		font-family: 'Montserrat', sans-serif;
		margin-bottom:18px;
			@media (min-width:1500px){
				font-size:18px;
			}
			@media (min-width:1280px) and (max-width:1500px){
				font-size:16px;
			}
			@media (max-width:1280px){
				font-size:16px;
			}
		}
		.text{
			color:#FFFFFF;
			font-family: 'Montserrat', sans-serif;
			line-height: 21px;
			@media (min-width:1500px){
				font-size:18px;
			}
			@media (min-width:1024px) and (max-width:1500px){
				font-size:16px;
			}
		}
    }
	.card-table{
		width:100%;
		height:100%;
	}
	.close-button{
		background-color:transparent !important;
		border-width:0px !important;
		font-size:24px;
        position: absolute;
        top: 5%;
        right: 4%;
	}
	.circles-modal-1{
		position:absolute;
		width: 3vw;
    height: 3vw;
		border-radius:100%;
	}
	.circles-modal-2{
		position:absolute;
		width: 2vw;
        height: 2vw;
		border-radius:100%;
	}
	.first-col{
		margin-right:0%;
		margin-left:5%;
		display: flex;
		padding:0;
		flex-direction: column;
		height:max-content;
		position:relative;
	}
	.filter-blur{
		filter:blur(5px);
	}
	h1{

		color:#FFFFFF;
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
			@media (min-width:1500px){
				font-size:35px;
				margin-top:0%;
				margin-bottom: 16px;
			}
			@media (min-width:1024px) and (max-width:1500px){
				font-size:28px;
				margin-top:0px;
				margin-bottom: 0px;
			}
	}
	.row-content{
		position:relative;
        display:flex;
		height:100%;
	}
	.row-info{
		margin-top:3.2%;
	}
	.span-dot{
		color:#49D3EA;
	}
	.container-image{
		margin-top:65px;
		img{
			max-width:80%;
			.shortImage{
				max-width:90% !important;
			}
		}
	}

`

export default ModalStyles