import styled from "styled-components";

export const Styles = styled.div`
  background:#1C1C1C;
  position:relative;
	padding:2%;
  .col-md-2{
    z-index:10;
  }
  .content-row-cards{
    flex-direction:row;
    align-items:flex-end;
    width:100%;
    padding:1% 0;
    margin-bottom: 30px;
  }
  .content-images-wave{
    position:absolute;
    width:100%;
    .image-wave-reverse{
      position:relative;
      z-index: 1;
      left: -13%;
      transform: scaleX(-1);
    }
    .image-wave{
      position:relative;
      z-index: 1;
      z-index: 10;
      right: -10%;
    }
  }
  .title{
    font-weight:700;
    font-size: 45px;
    margin:0;
    font-family: 'Montserrat', sans-serif;
    color:white;
    text-align:center;
    z-index:100;
    border:0px
  }
  .subtitle{
    font-family: 'Montserrat', sans-serif;
    color:#F8F8F8;
    margin:20px 0px 25px 0px;
    text-align:center;
    display:block;
    font-size: 26px;
    font-weight: 300;
    @media (min-width:1500px) {
        font-size:26px;
    }
    @media (max-width:1500px) {
        font-size:24px;
    }
  }
  h3{
    font-weight:700;
    font-size: 23px;
    text-transform: uppercase;
		margin:0 0 1% 0;
		font-family: 'Montserrat', sans-serif;
		color:white;
		z-index: 10;
    position: relative;
  }
  .content-buttons{
    display:flex;
    width:100%;
    justify-content:center;
    padding-top:20px;
    z-index:50;
    button{
        background: transparent !important;
        width: 142px;
        height: 41px;
        z-index: 50;
        color: white;
        border: 2.5px solid white;
        font-size: 12px;
        font-weight: 700;
        border-radius: 25px;
        margin:0 10%;
    }
    button:hover {
        background:white !important;
        border:1px solid black !important;
        color:black !important;
        transform: scale(1.03);
    }
    button:active{
        border: 1px solid white;
        box-shadow:none !important;
    }
    button:focus{
        border: 1px solid white;
        box-shadow:none !important;
    }
	}
  .first-row-1, .first-row-2 {
    .size-0{
      width: 60% !important;
      height: 60% !important;
    }
    .size-1{
      width: 60% !important;
      height: 60% !important;
    }
    .size-2{
      width: 60% !important;
      height: 60% !important;
    }
    .size-3{
      width: 60% !important;
      height: 60% !important;
    }
  }
  .second-row-1, .second-row-2{
    .size-0{
      width: 100% !important;
      height: 100% !important;
    }
    .size-1{
      width: 100% !important;
      height: 100% !important;
    }
    .size-2{
      width: 100% !important;
      height: 100% !important;
    }
    .size-3{
      width: 100% !important;
      height: 100% !important;
    }
  }
  .first-row-3{
    .size-0{
      width: 30% !important;
      height: 30% !important;
    }
    .size-1{
      width: 30% !important;
      height: 30% !important;
    }
    .size-2{
      width: 80% !important;
      height: 80% !important;
    }
    .size-3{
      width: 80% !important;
      height: 80% !important;
    }
  }
`
export const ProductCardStyles = styled.div`
  background: linear-gradient(45deg,rgba(0,0,0,0.69) ,rgba(74,73,73,0) 85%);
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 15px;
  margin-top:10px;
  z-index: 50;
  .content-image{
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    z-index: 10;
    justify-content: center;
    .size-0{
      width: 40%;
      height: 40%;
    }
    .size-1{
      width: 60%;
      height: 60%;
    }
    .size-2{
      width: 80%;
      height: 80%;
    }
    .size-3{
      width: 100%;
      height: 100%;
    }
    img{
      z-index: 50;
    }
  }
  .content-text{
    h1{
      color: white;
      font-family: 'Montserrat',sans-serif;
      font-size: 16px;
      font-weight: 700;
      border:0;
      z-index: 10;
    }
    .description{
      display:flex;
      z-index: 10;
      justify-content:space-between;
      p{
        margin: 0;
        color: #D6D6D6;
        z-index: 10;
        font-size: 14px;
        display: inline;
      }
    }
  }
`