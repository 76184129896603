/*----- Core -----*/
import React from 'react'
import { Container } from "react-bootstrap";

/*----- Commons -----*/
import "../../../commons/i18n";
import { MobileStyles } from "../Styles.js"

/*----- Private -----*/
import data from "../Data"
import Catalog from "../Catalog"

const Download = () => {

	const getItems = () => {
		return data.map( (item, idx)=>(
			<Catalog
				key = {idx}
				title = {item.title}
				image = {item.image}
				pdf = {item.pdf}
				filename = {item.filename}
				links = {item.links}
			/>
		))
	}

	return (
		<MobileStyles>
			<Container>
				<div className="row-content">
					{getItems()}
				</div>
			</Container>
		</MobileStyles>
	);
};

export default Download;
