import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_1.png";
import Image_1_2 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_2.png";
import Image_1_3 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_3.png";
import Image_1_4 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_4.png";
import Image_1_5 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_5.png";
import Image_1_6 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_6.png";
import Image_1_7 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_7.png";
import Image_1_8 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_8.png";
import Image_1_9 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_9.png";
import Image_1_10 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_10.png";
import Image_1_11 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_11.png";
import Image_1_12 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_12.png";
import Image_1_13 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_13.png";
/* import Image_1_14 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_14.png"; */
import Image_14_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/MEDP_0.6_4_L_L.png";
import Image_1_15 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_15.png";
import Image_1_16 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_16.png";
import Image_1_17 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_17.png";
import Image_1_18 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_18.png";
import Image_1_19 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_19.png";
import Image_1_20 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_20.png";
import Image_1_21 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_21.png";
import Image_1_22 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_22.png";
import Image_1_23 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_23.png";
import Image_1_24 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_24.png";
import Image_1_25 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_25.png";
import Image_1_26 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_26.png";
import Image_1_27 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_27.png";
import Image_1_28 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_28.png";
import Image_1_29 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_29.png";
import Image_1_30 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_30.png";
import Image_1_31 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_31.png";
import Image_1_32 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_32.png";
import Image_1_33 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_33.png";
import Image_1_34 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_34.png";
import Image_1_35 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_35.png";
import Image_1_36 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_36.png";
import Image_1_37 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_37.png";
import Image_1_38 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_38.png";
import Image_1_39 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_39.png";
import Image_1_40 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_40.png";
import Image_1_41 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_41.png";
import Image_1_42 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_42.png";
import Image_1_43 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_43.png";
import Image_1_44 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_44.png";
import Image_1_45 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_45.png";
import Image_1_46 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_46.png";
import Image_1_47 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_47.png";
import Image_1_48 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_48.png";
import Image_1_49 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_49.png";
import Image_1_50 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_50.png";
import Image_1_51 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_51.png";
import Image_1_52 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_52.png";
import Image_1_53 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_53.png";
import Image_1_54 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_54.png";
import Image_1_55 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_55.png";
import Image_1_56 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_56.png";
import Image_1_57 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_57.png";
import Image_1_58 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_58.png";
import Image_1_59 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_59.png";
import Image_1_60 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_60.png";
import Image_1_61 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_61.png";
import Image_1_62 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_62.png";
import Image_1_63 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_63.png";
import Image_1_64 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_64.png";
import Image_1_65 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_65.png";
import Image_1_66 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_66.png";
import Image_1_67 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_67.png";
import Image_1_68 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_68.png";
import Image_1_69 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_69.png";
import Image_1_70 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_70.png";
import Image_1_71 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_71.png";
import Image_1_72 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_72.png";
import Image_1_73 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_73.png";
import Image_1_74 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Plates/image_74.png";


export default function data(lng) {
    const platesData_1_description_en = ["Straight Plate. 2-holes ", "Straight Plate. 2-holes ", "Straight Plate. 4-holes. ", "Straight Plate. 6-holes. ", "Straight Plate. 8-holes. ", "Straight Plate. 12-holes. ", "Straight Plate. 16-holes. ", "Straight Plate. 18-holes. ", "Straight Plate. 30-holes. ", "Straight Plate. 4-holes. ", "Straight Plate. 4-holes ", "Straight Plate. 4-holes ", "Straight Plate. 6-holes "]
    const platesData_1_description_es = ["Placa Recta. 2-agujeros", "Placa Recta. 2-agujeros", "Placa Recta. 4-agujeros. ", "Placa Recta. 6-agujeros. ", "Placa Recta. 8-agujeros. ", "Placa Recta. 12-agujeros. ", "Placa Recta. 16-agujeros. ", "Placa Recta. 18-agujeros. ", "Placa Recta. 30-agujeros. ", "Placa Recta. 4-agujeros. ", "Placa Recta. 4-agujeros. ", "Placa Recta. 4-agujeros. ", "Placa Recta. 6-agujeros. "]

    const platesData_1_detail_en = ["8 mm stem. ", "12 mm stem. ", "", "", "", "", "", "", "", "6 mm stem. ", "8 mm stem. ", "10 mm stem. ", "12 mm stem. "]
    const platesData_1_detail_es = ["8 mm puente. ", "12 mm puente. ", "", "", "", "", "", "", "", "6 mm puente. ", "8 mm puente. ", "10 mm puente. ", "12 mm puente. "]

    const platesData_2_description_en = ["L Plate. 4-holes. Left.", "L Plate. 4-holes. Right.", "L Plate. 4-holes", "L Plate. 4-holes", "L Plate. 4-holes", "L Plate. 4-holes", "L Plate. 4-holes", "L Plate. 4-holes", "L Plate. 5-holes", "L Plate. 5-holes"]
    const platesData_2_description_es = ["Placa L. 4-agujeros. Izquierda.", "Placa L. 4-agujeros. Derecha.", "Placa L. 4-agujeros. ", "Placa L. 4-agujeros. ", "Placa L. 4-agujeros. ", "Placa L. 4-agujeros. ", "Placa L. 4-agujeros. ", "Placa L. 4-agujeros. ", "Placa L. 5-agujeros. ", "Placa L. 5-agujeros. "]

    const platesData_2_detail_en = ["", "", "6 mm stem. Left.", "6 mm stem. Right.", "8 mm stem. Left.", "8 mm stem. Right.", "10 mm stem. Left.", "10 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_2_detail_es = ["", "", "6 mm puente. Izquierda.", "6 mm puente. Derecha.", "8 mm puente. Izquierda.", "8 mm puente. Derecha.", "10 mm puente. Izquierda.", "10 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_3_description_en = ["L Plate - 112°. 4-holes. Left.", "L Plate - 112°. 4-holes. Right.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 5-holes.", "L Plate - 112°. 5-holes.", "L Plate - 112°. 5-holes."]
    const platesData_3_description_es = ["Placa L - 112°. 4-agujeros. Izquierda.", "Placa L - 112°. 4-agujeros. Derecha.", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 5-agujeros. ", "Placa L - 112°. 5-agujeros. "]

    const platesData_3_detail_en = ["", "", "6 mm stem. Left.", "6 mm stem. Right.", "8 mm stem. Left.", "8 mm stem. Right.", "10 mm stem. Left.", "10 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_3_detail_es = ["", "", "6 mm puente. Izquierda.", "6 mm puente. Derecha.", "8 mm puente. Izquierda.", "8 mm puente. Derecha.", "10 mm puente. Izquierda.", "10 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_4_description_en = ["T Plate. 4-holes.", "T Plate. 4-holes.", "T Plate. 6-holes.", "T Plate. 5-holes."]
    const platesData_4_description_es = ["Placa T. 4-agujeros.", "Placa T. 4-agujeros.", "Placa T. 6-agujeros.", "Placa T. 5-agujeros."]

    const platesData_4_detail_en = ["8 mm stem.", "12 mm stem.", "", "10 mm stem."]
    const platesData_4_detail_es = ["8 mm puente.", "12 mm puente.", "", "10 mm puente."]

    const platesData_5_description_en = ["Double T Plate. 6-holes."]
    const platesData_5_description_es = ["Doble Placa T. 6-agujeros."]

    const platesData_6_description_en = ["Y Plate. 5-holes.", "Y Plate. 5-holes.", "Y Plate. 5-holes.", "Y Plate. 5-holes."]
    const platesData_6_description_es = ["Placa Y. 5-agujeros.", "Placa Y. 5-agujeros.", "Placa Y. 5-agujeros.", "Placa Y. 5-agujeros."]

    const platesData_6_detail_en = ["", "8 mm stem.", "12 mm stem.", "16 mm stem."]
    const platesData_6_detail_es = ["", "8 mm puente.", "12 mm puente.", "16 mm puente."]

    const platesData_7_description_en = ["Double Y Plate. 6-holes.", "Double Y Plate. 6-holes.", "Double Y Plate. 7-holes."]
    const platesData_7_description_es = ["Doble Placa Y. 6-agujeros.", "Doble Placa Y. 6-agujeros.", "Doble Placa Y. 7-agujeros."]

    const platesData_7_detail_en = ["", "8 mm stem.", ""]
    const platesData_7_detail_es = ["", "8 mm puente.", ""]

    const platesData_8_description_en = ["Orbital Plate. 4-holes.", "Orbital Plate. 6-holes.", "Orbital Plate. 8-holes.", "Orbital Plate. 10-holes.", "Orbital Plate. 4-holes.", "Orbital Plate. 6-holes."]
    const platesData_8_description_es = ["Placa Orbital. 4-agujeros.", "Placa Orbital. 6-agujeros.", "Placa Orbital. 8-agujeros.", "Placa Orbital. 10-agujeros.", "Placa Orbital. 4-agujeros.", "Placa Orbital. 6-agujeros."]

    const platesData_8_detail_en = ["", "", "", "", "6 mm stem.", "8 mm stem."]
    const platesData_8_detail_es = ["", "", "", "", "6 mm puente.", "8 mm puente."]

    const platesData_9_description_en = ["Orbital Plate. Zygoma."]
    const platesData_9_description_es = ["Placa Orbital. Zygoma."]

    const platesData_9_detail_en = ["4x2-holes"]
    const platesData_9_detail_es = ["4x2-agujeros"]

    const platesData_10_description_en = ["Burr Hole Cover.", "Burr Hole Cover.", "Burr Hole Cover."]
    const platesData_10_description_es = ["Placa de Recubrimiento de Tépano.", "Placa de Recubrimiento de Tépano.", "Placa de Recubrimiento de Tépano."]

    const platesData_11_description_en = ["Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate", "Quadratic Plate."]
    const platesData_11_description_es = ["Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática."]
    
    const platesData_11_detail_en = ["2x2-holes", "3x2-holes", "4x2-holes", "4x4-holes", "6x4-holes"]
    const platesData_11_detail_es = ["2x2-agujeros", "3x2-agujeros", "4x2-agujeros", "4x4-agujeros", "6x4-agujeros"]

    const platesData_12_description_en = ["Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate."]
    const platesData_12_description_es = ["Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular."]

    const platesData_12_detail_en = ["2x2-holes.", "3x2-holes", "4x2-holes", "4x4-holes", "6x4-holes."]
    const platesData_12_detail_es = ["2x2-agujeros", "3x2-agujeros", "4x2-agujeros", "4x4-agujeros", "6x4-agujeros"]

    const platesData_13_description_en = ["Gap Plate. ", "Gap Plate. ", "Gap Plate. "]
    const platesData_13_description_es = ["Placa Gap. ", "Placa Gap. ", "Placa Gap."]

    const platesData_13_detail_en = ["6-holes. Small.", "6-holes. Medium.", "6-holes. Large."]
    const platesData_13_detail_es = ["6-agujeros. Pequeña.", "6-agujeros. Mediana.", "6-agujeros. Grande."]

    const platesData_14_description_en = ["Sub-Temporal Plate.", "Sub-Temporal Plate."]
    const platesData_14_description_es = ["Placa Sub-Temporal.", "Placa Sub-Temporal."]

    const platesData_14_detail_en = ["8-holes. Small.", "8-holes. Large."]
    const platesData_14_detail_es = ["8-agujeros. Pequeña.", "8-agujeros. Grande."]

    const platesData_15_description_en = ["Titanium Medium Mesh.", "Titanium Medium Mesh."]
    const platesData_15_description_es = ["Mesh Medium Titanio.", "Mesh Medium Titanio."]

    const platesData_16_description_en = ["LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes."]
    const platesData_16_description_es = ["Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros. ", "Placa LeFort. 11-agujeros."]

    const platesData_16_detail_en = ["Depth 0 mm. Left.", "Depth 2 mm. Left.", "Depth 3 mm. Left.", "Depth 4 mm. Left.", "Depth 5 mm. Left.", "Depth 6 mm. Left.", "Depth 7 mm. Left.", "Depth 8 mm. Left.", "Depth 9 mm. Left.", "Depth 10 mm. Left.", "Depth 11 mm. Left.", "Depth 12 mm. Left.", "Depth 0 mm. Right.", "Depth 2 mm. Right.", "Depth 3 mm. Right.", "Depth 4 mm. Right.", "Depth 5 mm. Right.", "Depth 6 mm. Right.", "Depth 7 mm. Right.", "Depth 8 mm. Right.", "Depth 9 mm. Right.", "Depth 10 mm. Right.", "Depth 11 mm. Right.", "Depth 12 mm. Right."]
    const platesData_16_detail_es = ["Profundidad 0 mm. Izquierda.", "Profundidad 2 mm. Izquierda.", "Profundidad 3 mm. Izquierda.", "Profundidad 4 mm. Izquierda.", "Profundidad 5 mm. Izquierda.", "Profundidad 6 mm. Izquierda.", "Profundidad 7 mm. Izquierda.", "Profundidad 8 mm. Izquierda.", "Profundidad 9 mm. Izquierda.", "Profundidad 10 mm. Izquierda.", "Profundidad 11 mm. Izquierda.", "Profundidad 12 mm. Izquierda.", "Profundidad 0 mm. Derecha.", "Profundidad 2 mm. Derecha.", "Profundidad 3 mm. Derecha.", "Profundidad 4 mm. Derecha.", "Profundidad 5 mm. Derecha.", "Profundidad 6 mm. Derecha.", "Profundidad 7 mm. Derecha.", "Profundidad 8 mm. Derecha.", "Profundidad 9 mm. Derecha.", "Profundidad 10 mm. Derecha.", "Profundidad 11 mm. Derecha.", "Profundidad 12 mm. Derecha."]

    const platesData_1 = [
        ["MEDP-0.6-2-S-MB", "MEDP-0.6-2-S-ELB", "MEDP-0.6-4-S", "MEDP-0.6-6-S", "MEDP-0.6-8-S", "MEDP-0.6-12-S", "MEDP-0.6-16-S", "MEDP-0.6-18-S", "MEDP-0.6-30-S", "MEDP-0.6-4-S-SB", "MEDP-0.6-4-S-MB", "MEDP-0.6-4-S-LB", "MEDP-0.6-6-S-ELB"],
        lng === "en" ? platesData_1_description_en : platesData_1_description_es,
        lng === "en" ? platesData_1_detail_en : platesData_1_detail_es,
        [Image_1_1, Image_1_2, Image_1_3, Image_1_4, Image_1_5, Image_1_6, Image_1_7, Image_1_8, Image_1_9, Image_1_10, Image_1_11, Image_1_12, Image_1_13]
    ]
    const platesData_2 = [
        ["MEDP-0.6-4-L-L", "MEDP-0.6-4-L-R", "MEDP-0.6-4-L-L-SB", "MEDP-0.6-4-L-R-SB", "MEDP-0.6-4-L-L-MB", "MEDP-0.6-4-L-R-MB", "MEDP-0.6-4-L-L-LB", "MEDP-0.6-4-L-R-LB", "MEDP-0.6-5-L-L-ELB", "MEDP-0.6-5-L-R-ELB"],
        lng === "en" ? platesData_2_description_en : platesData_2_description_es,
        lng === "en" ? platesData_2_detail_en : platesData_2_detail_es,
        [Image_14_1, Image_1_15, Image_1_16, Image_1_17, Image_1_18, Image_1_19, Image_1_20, Image_1_21, Image_1_22, Image_1_23]
    ]
    const platesData_3 = [
        ["MEDP-0.6-4-L-L-112", "MEDP-0.6-4-L-R-112", "MEDP-0.6-4-L-L-SB-112", "MEDP-0.6-4-L-R-SB-112", "MEDP-0.6-4-L-L-MB-112", "MEDP-0.6-4-L-R-MB-112", "MEDP-0.6-4-L-L-LB-112", "MEDP-0.6-4-L-R-LB-112", "MEDP-0.6-5-L-L-ELB-112", "MEDP-0.6-5-L-R-ELB-112"],
        lng === "en" ? platesData_3_description_en : platesData_3_description_es,
        lng === "en" ? platesData_3_detail_en : platesData_3_detail_es,
        [Image_1_24, Image_1_25, Image_1_26, Image_1_27, Image_1_28, Image_1_29, Image_1_30, Image_1_31, Image_1_32, Image_1_33]
    ]
    const platesData_4 = [
        ["MEDP-0.6-4-T-MB", "MEDP-0.6-4-T-ELB", "MEDP-0.6-6-T", "MEDP-0.6-5-T-LB"],
        lng === "en" ? platesData_4_description_en : platesData_4_description_es,
        lng === "en" ? platesData_4_detail_en : platesData_4_detail_es,
        [Image_1_34, Image_1_35, Image_1_36, Image_1_37]
    ]
    const platesData_5 = [
        ["MEDP-0.6-6-TT"],
        lng === "en" ? platesData_5_description_en : platesData_5_description_es,
        [Image_1_38]
    ]
    const platesData_6 = [
        ["MEDP-0.6-5-Y", "MEDP-0.6-5-Y-MB", "MEDP-0.6-5-Y-ELB", "MEDP-0.6-5-Y-EELB"],
        lng === "en" ? platesData_6_description_en : platesData_6_description_es,
        lng === "en" ? platesData_6_detail_en : platesData_6_detail_es,
        [Image_1_39, Image_1_40, Image_1_41, Image_1_42]
    ]
    const platesData_7 = [
        ["MEDP-0.6-6-YY", "MEDP-0.6-6-YY-MB", "MEDP-0.6-7-YY"],
        lng === "en" ? platesData_7_description_en : platesData_7_description_es,
        lng === "en" ? platesData_7_detail_en : platesData_7_detail_es,
        [Image_1_43, Image_1_44, Image_1_45]
    ]
    const platesData_8 = [
        ["MEDP-0.6-4-O", "MEDP-0.6-6-O", "MEDP-0.6-8-O", "MEDP-0.6-10-O", "MEDP-0.6-4-O-SB", "MEDP-0.6-6-O-MB"],
        lng === "en" ? platesData_8_description_en : platesData_8_description_es,
        lng === "en" ? platesData_8_detail_en : platesData_8_detail_es,
        [Image_1_46, Image_1_47, Image_1_48, Image_1_49, Image_1_50, Image_1_51]
    ]
 
    const platesData_9 = [
        ["MEDP-0.6-8-ZY"],
        lng === "en" ? platesData_9_description_en : platesData_9_description_es,
        lng === "en" ? platesData_9_detail_en : platesData_9_detail_es,
        [Image_1_55]
    ]
    const platesData_10 = [
        ["MEDP-0.6-6-7-B", "MEDP-0.6-6-10-B", "MEDP-0.6-6-14-B"],
        lng === "en" ? platesData_10_description_en : platesData_10_description_es,
        ["Ø 7 mm.", "Ø 10 mm.", "Ø 14 mm."],
        [Image_1_52, Image_1_53, Image_1_54]
    ]
    const platesData_11 = [
        ["MEDP-0.6-4-Q", "MEDP-0.6-6-Q", "MEDP-0.6-8-Q", "MEDP-0.6-16-Q", "MEDP-0.6-24-Q"],
        lng === "en" ? platesData_11_description_en : platesData_11_description_es,
        lng === "en" ? platesData_11_detail_en : platesData_11_detail_es,
        [Image_1_56, Image_1_57, Image_1_58, Image_1_59, Image_1_60]
    ]
    const platesData_12 = [
        ["MEDP-0.6-4-RC", "MEDP-0.6-6-RC", "MEDP-0.6-8-RC", "MEDP-0.6-16-RC", "MEDP-0.6-24-RC"],
        lng === "en" ? platesData_12_description_en : platesData_12_description_es,
        lng === "en" ? platesData_12_detail_en : platesData_12_detail_es,
        [Image_1_61, Image_1_62, Image_1_63, Image_1_64, Image_1_65]
    ]
    const platesData_13 = [
        ["MEDP-0.6-6-NG-S", "MEDP-0.6-6-NG-M", "MEDP-0.6-6-NG-L"],
        lng === "en" ? platesData_13_description_en : platesData_13_description_es,
        lng === "en" ? platesData_13_detail_en : platesData_13_detail_es,
        [Image_1_66, Image_1_67, Image_1_68]
    ]
    const platesData_14 = [
        ["MEDP-0.6-8-NST-S", "MEDP-0.6-8-NST-L"],
        lng === "en" ? platesData_14_description_en : platesData_14_description_es,
        lng === "en" ? platesData_14_detail_en : platesData_14_detail_es,
        [Image_1_69, Image_1_70]
    ]
    const platesData_15 = [
        ["MEDMESH - 0.3 - 80x50", "MEDMESH - 0.6 - 80x50"],
        lng === "en" ? platesData_15_description_en : platesData_15_description_es,
        ["80x50x0.3 mm.", "80x50x0.6 mm."],
        [Image_1_71, Image_1_72]
    ]
    const platesData_16 = [
        ["MEDP-0.6-0-Lfort-L", "MEDP-0.6-2-Lfort-L", "MEDP-0.6-3-Lfort-L", "MEDP-0.6-4-Lfort-L", "MEDP-0.6-5-Lfort-L", "MEDP-0.6-6-Lfort-L", "MEDP-0.6-7-Lfort-L", "MEDP-0.6-8-Lfort-L", "MEDP-0.6-9-Lfort-L", "MEDP-0.6-10-Lfort-L", "MEDP-0.6-11-Lfort-L", "MEDP-0.6-12-Lfort-L", "MEDP-0.6-0-Lfort-R", "MEDP-0.6-2-Lfort-R", "MEDP-0.6-3-Lfort-R", "MEDP-0.6-4-Lfort-R", "MEDP-0.6-5-Lfort-R", "MEDP-0.6-6-Lfort-R", "MEDP-0.6-7-Lfort-R", "MEDP-0.6-8-Lfort-R", "MEDP-0.6-9-Lfort-R", "MEDP-0.6-10-Lfort-R", "MEDP-0.6-11-Lfort-R", "MEDP-0.6-12-Lfort-R"],
        lng === "en" ? platesData_16_description_en : platesData_16_description_es,
        lng === "en" ? platesData_16_detail_en : platesData_16_detail_es,
        [Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74]
    ]

    let data = {
        platesData_1,
        platesData_2,
        platesData_3,
        platesData_4,
        platesData_5,
        platesData_6,
        platesData_7,
        platesData_8,
        platesData_9,
        platesData_10,
        platesData_11,
        platesData_12,
        platesData_13,
        platesData_14,
        platesData_15,
        platesData_16
    }
    return data;
}