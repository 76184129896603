import styled from "styled-components";

export const Styles = styled.div`
  background:#1C1C1C;
  position:relative;
  .content-row-cards{
    flex-direction:row;
    align-items:flex-end;
    justify-content:center;
    width:100%;
    padding:1% 0;
    .card-col:first-child{
      margin-left:140px;
    }
    .card-col:last-child{
      margin-right:140px;
    }
  }
  h1{
    font-weight:700;
		margin:0 0 1% 0;
		font-family: 'Montserrat', sans-serif;
    font-size:45px;
		color:white;
		z-index: 50;
    position: relative;
		border-bottom:0.5px solid #EBEBEB;
  }
  .content-images-wave{
    position:absolute;
    width:100%;
    height:100%;
    .image-wave{
      position:relative;
      z-index: 10;
      width:100%;
      top:17%;
    }
  }
  .instruments-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title{
    font-weight:700;
    margin:0;
    font-family: 'Montserrat', sans-serif;
    font-size:45px;
    color:white;
    text-align:center;
    z-index:100;
    border:0px;
  }
  .subtitle{
    font-family: 'Montserrat', sans-serif;
    color:#F8F8F8;
    margin:20px 0px 25px 0px;
    text-align:center;
    display:block;
    width:50%;
    font-weight:300;
    font-size:26px;
  }
  .content-buttons{
			display:flex;
			width:100%;
			justify-content:center;
			padding-top:20px;
			z-index:50;
			button{
          background: transparent !important;
          width: 142px;
          height: 41px;
          z-index: 50;
          color: white;
          border: 2.5px solid white;
          font-size: 12px;
          font-weight: 700;
          font-family: 'Montserrat', sans-serif;
          border-radius: 25px;
          margin:0 10%;
      }
			button:hover {
					background:white !important;
					border:1px solid black !important;
					color:black !important;
					transform: scale(1.03); 
			}
			button:active{
					border: 1px solid white;
					box-shadow:none !important;
			}
			button:focus{
					border: 1px solid white;
					box-shadow:none !important;
			}
	}
`
export const ProductCardStyles = styled.div`
  background: linear-gradient(45deg,rgba(0,0,0,0.69) ,rgba(74,73,73,0) 85%);
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 15px;
  margin-top:10px;
  z-index: 50;
  .content-image{
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
    z-index: 50;
    img{
      z-index: 50;
    }
  }
  .content-text{
    h1{
      color: white;
      font-family: 'Montserrat';
      font-size: 18px;
      font-weight: 600;
      border:0;
      z-index: 50;
    }
    .description{
      display:grid;
      margin-top:10px;
      justify-content:space-between;
      p{
        margin: 0;
        color: #D6D6D6;
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight:300;
        display: inline;
        z-index: 50;
      }
    }
  }

`