/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { css } from "styled-components";

/*----- Commons -----*/
import Montserrat from "../../commons/mixins/fonts/Montserrat";

/*----- Styles -----*/

const H1 = styled.h1`
	${props => props.color && css`
		${Montserrat(33, 500, props.color)}
	`}
`;
const H2 = styled.h2`
	${props => props.color && css`
		${Montserrat(20, 500, props.color)}
	`}
`;
const H3 = styled.h3`
	${props => props.color && css`
		${Montserrat(18, 500, props.color)}
	`}
`;
const H4 = styled.h4`
	${props => props.color && css`
		${Montserrat(15, 500, props.color)}
	`}
`;

const H5 = styled.h5`
	${props => props.color && css`
		${Montserrat(14, 500, props.color)}
	`}
`;

const Home = styled.h3`
	${Montserrat(20, 500, 'white')};
	text-align: left;
	letter-spacing: 0px;
	line-height: 27px;
`;

/*----- Component -----*/
function Subtitle({ content, size, color = "Black" }) {
	switch (size) {
	case 1:
		return <H1 color={color}>{content}</H1>;
	case 2:
		return <H2 color={color}>{content}</H2>;
	case 3:
		return <H3 color={color}>{content}</H3>;
	case 4:
		return <H4 color={color}>{content}</H4>;
	case 5:
		return <H5 color={color}>{content}</H5>;
	case 'home':
		return <Home >{content}</Home>;
	default:
		return <H5 color={color}>{content}</H5>;
	}
}

export default Subtitle;

/*----- PropTypes -----*/
Subtitle.propTypes = {
	size: PropTypes.any.isRequired,
	content: PropTypes.object.isRequired,
	color: PropTypes.string
};
