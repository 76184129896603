import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
// Components
import SpinnerAndNoveltyContainer from '../desktop/SpinnerAndNoveltyContainer'
import Spinner from '../../../components/Spinner'
import { withRouter } from 'react-router-dom'

// HOC
import WithLoading from '../../../commons/HOC/WithLoading';
import { postSelector } from '../redux/NewSelectors';
/*----- Actions -----*/
import { fetchNew } from '../redux/NewsActions';

const GeneralNoveltyContainer = (props) => {
    const NoveltyWithLoading = WithLoading(props.novelty);
	const [postData, setPostData] = useState({});
	const [ID, setID] = useState(0);
	const [isLoading, setLoading] = useState(true);

	const {
		fetchNew,
		postProps
	} = props;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		setID(props.id);
		fetchNew(ID);
	},[props.id, fetchNew,ID]);

	useEffect(() => {
		if ((postProps.length !== 0) && (postProps.id === ID )) {
			setPostData(postProps);
			setLoading(false);
		}
	}, [postProps,ID])

	if ((!isLoading)) {
		return (
			<SpinnerAndNoveltyContainer isMobile={props.isMobile}>
				<NoveltyWithLoading
					isLoading={isLoading}
					post={postData}
				/>
			</SpinnerAndNoveltyContainer>
		)
	} else {
		return (
			<div className="d-flex justify-content-center mb-5 pb-5">
				<Spinner />
			</div>
		)
    }
}

function mapStateToProps(state) {
	return {
		postProps: postSelector(state),
	}
}

function mapDispatchToProps(dispatch) {
	return {
		fetchNew: (payload) => fetchNew(dispatch, payload),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(GeneralNoveltyContainer))