export const  data_1 = [
	["Ø 3.3 mm","Ø 3.75 mm","Ø 4.2 mm","Ø 5 mm"],
	["L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm"],
	["Ø 3.2 mm","Ø 3.2 mm","Ø 3.2 mm","Ø 3.8 mm",],
	["Ø 2.8 mm","Ø 2.8 mm","Ø 2.8 mm","Ø 2.8 mm"],
	["AIF33100N200C-AIF33115N200C-AIF33130N200C-AIF33150N200C","AIF37080N200C-AIF37100N200C-AIF37115N200C-AIF37130N200C-AIF37150N200C","AIF42080N200C-AIF42100N200C-AIF42115N200C-AIF42130N200C-AIF42150N200C","AIF50080N200C-AIF50100N200C-AIF50115N200C-AIF50130N200C-AIF50150N200C"]
]
export const data_2 = [
	["Ø 3.3 mm","Ø 3.75 mm","Ø 4 mm","Ø 4.5 mm","Ø 5 mm","Ø 6 mm"],
	["L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm"],
	["Ø 2.5 mm","Ø 2.5 mm","Ø 2.5 mm","Ø 2.5 mm","Ø 3.2 mm","Ø 3.2 mm"],
	["Ø 2.8 mm","Ø 2.8 mm","Ø 2.8 mm","Ø 2.8 mm","Ø 2.8 mm","Ø 2.8 mm"],
	["ACY33100N200C-ACY33115N200C-ACY33130N200C-ACY33150N200C","ACY37080N200C-ACY37100N200C-ACY37115N200C-ACY37130N200C-ACY37150N200C","ACY40080N200C-ACY40100N200C-ACY40115N200C-ACY40130N200C-ACY40150N200C","ACY45080N200C-ACY45100N200C-ACY45115N200C-ACY45130N200C-ACY45150N200C","ACY50080N200C-ACY50100N200C-ACY50115N200C-ACY50130N200C-ACY50150N200C ","ACY60080N200C-ACY60100N200C-ACY60115N200C-ACY60130N200C"]
]

