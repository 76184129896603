import React, { useState } from 'react';
import { Image, Accordion } from 'react-bootstrap';
import Styles from './Styles';
import { Trans } from 'react-i18next';
import AccordionNavigation from '../../../../components/accordion/AccordionMaxiloNavigation';
import CustomToggle from '../../../../components/accordion/CustomToggle';
import systems from '../systems';

import VioletWave from '../../../../assets/images/Dental/LevelOneMobile/onda-violeta.png';
import Screws from './Screws';
import Plates from './Plates';
import Instruments from './Instruments';
import Sets from './Sets';

const LevelOne = ({ system }) => {
	const [screws, setScrews] = useState(false);
	const [plates, setPlates] = useState(false);
	const [instruments, setInstruments] = useState(false);
	const [sets, setSets] = useState(false);


	const renderCommonSystem = () => {
		return (
			<div className="systems">
				<Accordion >
					<Image className="image-violet-wave" src={VioletWave} />
					<div className="title">
						<CustomToggle eventKey="1" setOpen={setScrews} open={screws}>
							<h1><Trans>{systems[system].screws.title}</Trans></h1>
						</CustomToggle>
					</div>
					<Accordion.Collapse eventKey="1">
						<Screws system={system} />
					</Accordion.Collapse>
				</Accordion>
				<Accordion >
					<div className="title">
						<CustomToggle eventKey="1" setOpen={setPlates} open={plates}>
							<h1><Trans>{systems[system].plates.title}</Trans></h1>
						</CustomToggle>
					</div>
					<Accordion.Collapse eventKey="1">
						<Plates system={system} />
					</Accordion.Collapse>
				</Accordion>
				<Accordion >
					<div className="title">
						<CustomToggle eventKey="1" setOpen={setInstruments} open={instruments}>
							<h1><Trans>{systems[system].instruments.title}</Trans></h1>
						</CustomToggle>
					</div>
					<Accordion.Collapse eventKey="1">
						<Instruments system={system} />
					</Accordion.Collapse>
				</Accordion>
				<Accordion >
					<div className="title">
						<CustomToggle eventKey="1" setOpen={setSets} open={sets}>
							<h1><Trans>{systems[system].sets.title}</Trans></h1>
						</CustomToggle>
					</div>
					<Accordion.Collapse eventKey="1">
						<Sets system={system} />
					</Accordion.Collapse>
				</Accordion>
			</div>
		)
	}
	const renderIMF = () => {
		return (
			<div className="systems">
				<Accordion >
					<Image className="image-violet-wave" src={VioletWave} />
					<div className="title">
						<CustomToggle eventKey="1" setOpen={setScrews} open={screws}>
							<h1><Trans>{systems[system].screws.title}</Trans></h1>
						</CustomToggle>
					</div>
					<Accordion.Collapse eventKey="1">
						<div className="item">
							<span className="dot"></span>
							<div className="text">
								<p><Trans>{systems[system].screws.items[0]}</Trans></p>
								<p><Trans>{systems[system].screws.items[1]}</Trans></p>
							</div>
						</div>
					</Accordion.Collapse>
				</Accordion>
				<Accordion >
					<div className="title">
						<CustomToggle eventKey="1" setOpen={setSets} open={sets}>
							<h1><Trans>{systems[system].sets.title}</Trans></h1>
						</CustomToggle>
					</div>
					<Accordion.Collapse eventKey="1">
						<p><Trans>{systems[system].sets.text}</Trans></p>
					</Accordion.Collapse>
				</Accordion>
			</div>
		)
	}
	const renderSystem = () => {
		if (system === "IMFSystem") {
			return renderIMF();
		} else {
			return renderCommonSystem();
		}
	}
	return (
		<Styles>
			<div className="system-container">
				<h1 className="title"><Trans>maxillofacial.self</Trans><span className="blue-dot">.</span></h1>
				<AccordionNavigation
					systems={systems}
					system={system}
				/>
				<div className="information-container">
					<p><Trans>{systems[system].text}</Trans></p>
					{renderSystem()}
					<div className="catalog-button">
						<a
							className="button zoom"
							role="button"
							href="/download"
						>
							<Trans>general.download_catalog</Trans>
						</a>
					</div>
				</div>
			</div>
		</Styles>
	)
}

export default LevelOne