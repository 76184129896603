import React from 'react'
import { Row, Col, Image, Table,Button } from "react-bootstrap";
import Styles from "./Styles.js";
import { navigate } from 'hookrouter';
import { Trans } from "react-i18next";

import VioletWaveImg from "../../../../assets/images/onda-violeta-2.png";

const Screws = (props) => {

  function renderCards() {
    let render = [];
    for (let i = 0; i < props.sectionTitles.length; i++) {
      render.push(
        <div className="card mt-5">
          <Col md={2} className="content-images">
            <div className="container-images">
              <Image src={`${props.sectionImagesFirst[i]}`} className="mr-2"></Image>
              <Image src={`${props.sectionImagesSecond[i]}`} className=""></Image>
            </div>
          </Col>
          <Col>
            <h3><Trans>{props.sectionTitles[i]}</Trans></h3>
            <Table className="mb-0 table" striped bordered variant="dark" size="sm">
              <thead className="head">
                <tr className="tr-head">
                  <th scope="col"><Trans>maxillofacial.tableHeader.products</Trans></th>
                  <th scope="col"><Trans>maxillofacial.tableHeader.description</Trans></th>
                </tr>
              </thead>
              <tbody>
                {renderCard(i)}
              </tbody>
            </Table>
          </Col>
        </div>
      )
    }
    return render;
  }
  function renderCard(j) {
    let render = [];
    for (let i = 0; i < props.sectionData[j][0].length; i++) {
      render.push(
        <tr>
          <td>{props.sectionData[j][0][i]}</td>
          <td>{props.sectionData[j][1][i]}</td>
        </tr>

      )
    }
    return render;
  }
  return (
    <Styles>
      <div className="black-gradient"></div>
      <Image src={VioletWaveImg} className="onda-gris img-fluid"></Image>
      <Col md={10} className="container-page">
        <Row className="first-row justify-content-center">
          <div className="titles">
            <h1><Trans>{props.title}</Trans></h1>
            <h2><Trans>{props.subtitle}</Trans></h2>
          </div>
        </Row>
        <Row className="second-row">
          {renderCards()}
        </Row>
      </Col>
      <div className="content-buttons pb-5">
        <Button onClick={() => navigate('/maxillofacial/' + props.navigate +'/plates')}><Trans>maxillofacial.button.plates</Trans></Button>
        <Button onClick={() => navigate('/maxillofacial/' + props.navigate +'/instruments')}><Trans>maxillofacial.button.instrument</Trans></Button>
        <Button onClick={() => navigate('/maxillofacial/' + props.navigate +'/sets')}><Trans>maxillofacial.button.sets</Trans></Button>
      </div>
    </Styles>
  );
}

export default (Screws)