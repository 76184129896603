import React,{useState} from 'react'
import Instruments from "../../maxillofacial/desktop/Instruments"
import {dataGenerator} from "./data.js";
import i18n from "i18next";

const MicroPlatingInstruments = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<Instruments
			self={"microPlatingSystem"}
			navigate={"microplating_system"}
      titles={["microPlatingSystem.instruments.titles.1","microPlatingSystem.instruments.titles.2","microPlatingSystem.instruments.titles.3","microPlatingSystem.instruments.titles.4","microPlatingSystem.instruments.titles.5"]}
      data={[data.instrumentsData_1,data.instrumentsData_2,data.instrumentsData_3,data.instrumentsData_4,data.instrumentsData_5]}
		/>
	)
}

export default (MicroPlatingInstruments)