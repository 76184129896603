import styled from 'styled-components'

export const SystemListStyles = styled.div`
    padding:0 20px;
    margin:40px 0px 70px 0px;
    .system-card{
      margin-top:15px;
      padding:10px;
      z-index:10;
      height:100px;
      background: transparent linear-gradient(90deg, #333D98 0%, #4345A6 10%, #695AC7 37%, #8469DF 62%, #9572ED 83%, #9B76F3 100%) 0% 0% no-repeat padding-box;
      border-radius:17px;
      display:flex;
      align-items:center;
      justify-content:space-between;
      .title-container{
        display:flex;
        flex-direction:column;
        height: 100%;
        justify-content: center;
        padding:0;
        margin:0px 0px 0px 25px;
        width:150px;
        h1{
          font-size:14px;
          font-weight:700;
          margin:0;
          color:white;
        }
      }
      .image-container{
        height:100%;
        img{
          height:100%;
        }
      }
    }
`
