import React from 'react'
import LevelOne from "../maxillofacial/desktop/LevelOne";
import Tornillos from "../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/level1/fracture-tornillo.png"
import Placas from "../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/level1/fracture-placa.png"
import Instrumental from "../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/level1/fracture-instrumental.png"
import Sets from "../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/level1/fracture-set.png"
const FracturePlatingSystem = (props) => {
	return (
		<LevelOne
			title={"maxillofacial.fracturePlatingSystem.self"}
			subtitle={"maxillofacial.fracturePlatingSystem.subtitle"}
			productImages={[Tornillos,Placas,Instrumental,Sets]}
			productTitle={"maxillofacial.fracturePlatingSystem.products"}
			productDescription={"maxillofacial.fracturePlatingSystem.products"}
			openPage={["/maxillofacial/fracture_system/screws","/maxillofacial/fracture_system/plates","/maxillofacial/fracture_system/instruments","/maxillofacial/fracture_system/sets"]}
			currentPage = {5}
		/>
	)
}

export default (FracturePlatingSystem)