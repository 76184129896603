import React from 'react'
import { Image } from 'react-bootstrap';
import { navigate } from 'hookrouter';
import styled from 'styled-components'
import AccordionInvertCard from './AccordionInvertCard';
import { Trans } from 'react-i18next';

const Styles = styled.div`
  .card{
    z-index:10;
    z-index:800;
    background: transparent linear-gradient(90deg, #333D98 0%, #4345A6 10%, #695AC7 37%, #8469DF 62%, #9572ED 83%, #9B76F3 100%) 0% 0% no-repeat padding-box;
    .card-header{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction:column;
      margin-top:15px;
    }
    .border-title{
      border:0.5px solid white;
      margin-bottom:5px;
      width:100%;
    }
    .card-title{
      h1{
        font-size:16px;
        font-weight:700;
      }
    }
    .container-toggle{
      height: 100%;
      width:100%;
      display: flex;
      align-items: flex-end;
    }
  }
  .accordion-collapse{
    z-index: 5;
    position: absolute;
    width: 100%;
    top: 84px;
    z-index:700;
  }
`

const MaxiloLevelOneCardStyles = styled.div`
  width:100%;
  background:black;
  padding: 40px 20px 20px 20px;
  z-index:999;
  .system-card{
    z-index:10;
    display:flex;
    align-items:center;
    margin:10px 0px;
    justify-content:space-between;
    .title-container{
      display:flex;
      flex-direction:column;
      height: 100%;
      justify-content: center;
      padding:0;
      margin:0px;
      width:150px;
      h1{
        font-size:14px;
        font-weight:700;
        margin:0;
        color:white;
      }
    }
    .image-container{
      height:78px;
      img{
        height:100%;
      }
    }
  }
  .card-container:last-child{
    .border-title{
      display:none;
    }
  }
`
export const AccordionMaxilo = ({ systems, system }) => {
	const renderSystems = () => {
		let render = [];
		for (const key in systems) {
			if (key !== system) {
				render.push(
					<div className="card-container">
						<div className="system-card" onClick={() => navigate(systems[key].url)}>
							<div className="title-container">
								<h1><Trans>{systems[key].title}</Trans></h1>
								<h1><Trans>{systems[key].title_2}</Trans></h1>
							</div>
							<div className="image-container">
								<Image src={systems[key].image}></Image>
							</div>
						</div>
						<div className='border-title' />
					</div>
				)
			}
		}
		return render;
	}
	const DentalLevelOneCard = () => {
		return (
			<MaxiloLevelOneCardStyles>
				{renderSystems()}
			</MaxiloLevelOneCardStyles>
		)
	}
	return (
		<Styles>
			<AccordionInvertCard
				image={systems[system].image}
				title={systems[system].title}
				subtitle={systems[system].title_2}
				imageInvert={true}
				inside={false}
				className="my-card"
				content={<DentalLevelOneCard />}
				arrowColor={"white"}
			/>
		</Styles>
	)
}
export default AccordionMaxilo