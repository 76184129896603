import React,{useState} from 'react'
import Plates from "./Plates"
import dataGenerator from "./data.js";
import i18n from "i18next";

const FracturePlatingSystemPlates = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function (lng) {
		setData(dataGenerator(lng))
	})
	return (
		<Plates
			self={"fracturePlatingSystem"}
			navigate={"fracture_system"}
			titles={["fracturePlatingSystem.plates.titles.1", "fracturePlatingSystem.plates.titles.2", "fracturePlatingSystem.plates.titles.3", "fracturePlatingSystem.plates.titles.4"]}
			data={[data.platesData_1, data.platesData_2, data.platesData_3, data.platesData_4]}
		/>
	)
}

export default (FracturePlatingSystemPlates)