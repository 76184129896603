import React from 'react'
import LevelOne from "../maxillofacial/desktop/LevelOne";
import Tornillos from "../../assets/images/CranioMaxiloFacial/MicroPlating/level1/micro-tornillos.png"
import Placas from "../../assets/images/CranioMaxiloFacial/MicroPlating/level1/micro-placa.svg"
import Instrumental from "../../assets/images/CranioMaxiloFacial/MicroPlating/level1/micro-instrumental.svg"
import Sets from "../../assets/images/CranioMaxiloFacial/MicroPlating/level1/micro-set.png"


const MicroplatingSystem = (props) => {
	return (
		<LevelOne
			self={"microplating_system"}
			title={"maxillofacial.microPlatingSystem.self"}
			subtitle={"maxillofacial.microPlatingSystem.subtitle"}
			productImages={[Tornillos, Placas, Instrumental, Sets]}
			productTitle={"maxillofacial.microPlatingSystem.products"}
			productDescription={"maxillofacial.microPlatingSystem.products"}
			openPage={["/maxillofacial/microplating_system/screws", "/maxillofacial/microplating_system/plates", "/maxillofacial/microplating_system/instruments","/maxillofacial/microplating_system/sets"]}
			currentPage={1}
		/>
	)
}

export default (MicroplatingSystem)