import styled from 'styled-components'
import Color from "../../../commons/Color"
import OndaVioletaSection1 from '../../../assets/images/Dental/onda-violeta.png'


export const Styles = styled.div`
	position: relative;
	.section{
		width:100vw;
		position:relative;
	}
	.section1{
		background-image: url('${OndaVioletaSection1}');
		background-size:contain;
		background-repeat:no-repeat;
		background-position: center;
		height:112vh;
		z-index:103;

		.dental-data > div:first-child{
			height: 60%;
		}

		.dental-data .container-title{
			width:75%;
			padding-top:200px;
			h1{
				color:white;
				font-size: 72px;
				font-family: 'Montserrat', sans-serif;
				font-weight: 700;
				margin-bottom: 35px;
			}
			h3{
				z-index:102;
				color:white;
				font-size: 26px;
				font-weight: 300;
				font-family: 'Montserrat', sans-serif;
				line-height:36px;
			}
		}

		.dental-data{
			.card{
				padding-top:80px;
			}
		}

		.container-dental-image{
			min-height:100vh;
			div{
				max-width: 73%;
				background-size: contain;
				height: 112vh;
				background-repeat: no-repeat;
				background-position:bottom;
			}
		}
	}

	.blue-dot{
		color: #49D3EA;
	}

	.gradient-violet-one {
		position: absolute;
		left: 0px;
		background-image: linear-gradient(to bottom, #5B3D9C, #4A317C, #312152, #23183A, #221738) ;
		z-index: 101;
		width: 100%;
		height:112vh;
	}
	.section2{
		padding-top:146px;
		background-image: linear-gradient(to bottom, #27154D, #241346, #211342, #1E103C, #1B0F36, #1A0E32);
		display:flex;
		align-items:center;
		.microscope-img{
			position:absolute;
			z-index:106;
			left:3%;
			top: 5%;
		}
	}

	.card{
		display: flex;
		flex-direction: column;
		background:transparent;
		border:transparent;
		z-index: 204;
		.content-information{
			width:95%;
			display:flex;
			.content-image{
				margin-right:40px;
				margin-top:-2%;
				display: flex;
				align-items:flex-start;
				img{
					z-index: 200;
					background-image: linear-gradient(to bottom, #120B26, #180D30, #1D1038, #1F113E);
					border-radius: 100%;
					height: fit-content;
					width: 99px;
					height: 99px;
					opacity: 0.7px;
				}
			}
			.content-text{
				font-family: Montserrat, sans-serif;
				.title{
					min-height:107px;
					padding-bottom:15px;
				}

				h1{
					color:white;
					font-weight: 700;
					font-size: 33px;
					text-transform:none;
				}
				h2{
					color:white;
					text-transform:none;
					font-size:25px
				}
				p{
					z-index: 210;
					font-size: 22px;
					font-weight: 700;
					color:white;
					line-height:30px;
					margin-bottom:20px;
				}
			}
		}
		.content-button{
			margin-top:35px;
			padding-left: 150px;
			z-index: 210;
			display: flex;
			justify-content: flex-start;
			.button{
				padding:10px;
				border-width: 2px;
				border-style: solid;
				border-color: rgb(255, 255, 255);
				font-family: Montserrat, sans-serif;
				border-image: initial;
				border-radius: 25px;
				background: transparent;
				font-weight: 600;
				margin: 0px;
				width: 8vw;
				@media  screen and (max-width: 1440px) {
					width: 10vw;
					margin-bottom:10px;
				}
			}
		}
	}



	.section3{

		height:90vh;
		z-index:103;
		background: linear-gradient(to bottom, #1A0E32, #190E30, #160C2C, #110920, #0F081C);
		.ocv3{
			width: 100%;
			position: absolute;
			z-index: 60;
			height: 100%;
		}
		.row-content-card{
			height:90vh;
			width:100%;
			display: flex;
			align-items:center;
			z-index: 100;

			.content-image{
				display:flex;
				justify-content:center;
				align-items:center;
				z-index: 100;

			}
			.card-section-3 {
				z-index: 100;
			}
			.dental-card{
				z-index:110;
				box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 6px;
				opacity: 1;
				background: linear-gradient(224deg, rgba(28, 28, 28, 0) 0%, rgba(14,5,15,0.686) 100%) 0% 0% no-repeat padding-box padding-box transparent;
				border-radius: 18px;
				padding:50px;
    			margin-left: 5%;
				h1{
					color:white;
					z-index:120;
					text-align: left;
					letter-spacing: 0px;
					font-weight: 700;
					margin-bottom: 36px;
					font-family: Montserrat, sans-serif;
					font-size: 44px;
				}
				.card-text{
					p{
						z-index:120;
						font-weight: 300;
						font-size:24px;
						color:white;
						font-family: Montserrat, sans-serif;
						line-height: 33px;
						margin-bottom:30px;
						@media screen and (max-width:1440px){
							font-size:20px;
						}
					}
					p:last-child{
						margin-bottom:0px;
					}
				}
			}
		}
	}

	.section4{
		z-index:400;
		height:102vh;
		background-image: linear-gradient(to bottom, #0F081C, #0C0715, #090511, #020203, #000000);
		padding: 0px 125px;
	}
	.next-seccion{
		background:black;
		width:100px;
		height:100px;
		a{
			width:50px;
			height:50px;
			background:pink;
		}
	}
	.pop-up-style-implantologia-content{
			padding:0px !important;
			width:100% !important;
			background:rgba(0,0,0,1) !important;
			border:0px solid !important;
			position:relative;
			overflow:hidden;
			border-radius:25px;
			z-index:999;
			margin:60px 113px 60px 114px !important;
	}
	.pop-up-style-conexion-hexagono-content{
			z-index:999;
			padding:0px !important;
			background: ${Color.gradientModal} !important;
			border:0px solid !important;
			position:relative;
			overflow:hidden;
			border-radius:5px;
			width:100% !important;
			border-radius:25px;
			margin:55px 113px 43px 114px !important;
	}
	.zoomCard {
		z-index:900;
		transition: transform .2s; /* Animation */
	}
	.zoomCard:hover {
		transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
	}
`
export const FaicoSystemDentalStyles = styled.div`
	z-index:300;
	.content{
		margin:16px;
		margin-top:0px;
	}
	#image-hexagon{
		height: 410px;
		width: 100%;
	}
	.titles{
		margin-bottom:20px;
		@media  screen and (max-width: 1440px) {
			margin-bottom:145px;
		}
		.h1-flex{
			display: flex;
		}
		h1{
			margin-top:3%;
			font-family: 'Montserrat', sans-serif;
			color:white;
			font-size:61px;
			font-weight:700;
			text-transform:capitalize;
			margin-bottom:28px;
		}
		h2{
			font-family: 'Montserrat', sans-serif;
			color:white;
			font-size:23px;
			line-height:36px;
		}
	}
	.content-card{
		margin-top:15%;
		margin-bottom:15%;
		@media  screen and (max-width: 1440px) {
			margin:0px;
		}
		.card{
			cursor: pointer !important;
			padding:20px;
			display: flex;
			flex-direction: column;
			align-items: center;

			background: ${Color.gradientCard} 0% 0% no-repeat padding-box;
			box-shadow: 0px 4px 4px #3d339836;
			border-radius: 25px;
			padding:10px;
			display:flex;
			align-items: center;
			justify-content: flex-end;
			height:320px;
			.content-image{
				display:flex;
				justify-content:center;
				align-content:center;
				position: relative;
				top: 32px;
				.image{
					display: flex;
					flex-direction: row;
					margin: 0;
					width:80%;
				}
			}
			.image-10{
				padding:0 10px;
			}
			.image-30{
				padding:0 30px;
			}
			.titles{
				margin:0;
				/* padding: 0px 30px; */
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				text-align:center;
				h1{
					font-family: 'Montserrat', sans-serif;
					color:white;
					font-size:33px;
					font-weight:700;
					line-height: 38px;
					width:100%;
					padding-top: 40px;
					min-height:118px;
				}
				h2{
					text-align:center;
					line-height: 32px;
					font-family: 'Montserrat', sans-serif;
					color:white;
					font-size:25px;
					width:100%;
					padding: 20px 25px;
					padding-top:0px;
				}
			}
		}
	}
	.zoomCard {
		z-index:900;
		transition: transform .2s; /* Animation */
	}
	.zoomCard:hover {
		transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
	}
`