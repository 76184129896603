/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/*----- Styles -----*/
const StyledPostCard = styled.article`
	border-bottom: 1px solid black;
`;

/*----- Component -----*/
const PostCard = ({ userPost: { title, body } }) => {
	return (
		<StyledPostCard>
			<h3>{title}</h3>
			<p>{body}</p>
		</StyledPostCard>
	);
};
export default PostCard;

/*----- PropTypes -----*/
PostCard.propTypes = {
	body: PropTypes.string,
	title: PropTypes.string
};
