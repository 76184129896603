import styled from 'styled-components'
import { Accordion } from 'react-bootstrap'

const AccordionStyles = styled(Accordion)`
    position: relative;
    .container-fluid{
        padding:0px;
    }
    .card{
        /* background: linear-gradient(to bottom, #250C62, #240B5F, #230B5D, #210B5A ) 0% 0% no-repeat padding-box padding-box transparent;; */
        background: linear-gradient(to bottom,#00000063 ,#00000080 20%) 0% 0% no-repeat padding-box;
        border-radius:21px !important;
        .card-header{
            padding-left:17px;
        }
    }
    .first-col{
        padding-right:0px;
    }
    .container-toggle{
        display:flex;
        flex-direction:row-reverse;
    }
    .card-title{
        padding-top:9px;
        text-transform: capitalize;
        h1{
            color:white;
            font-size:16px;
            line-height:22px;
            font-weight:700;
        }
        h3{
            color:white;
            font-size:13px;
            line-height:16ox;
            font-weight:300;
        }
    }
    .border-title{
        width: 100%;
        border-bottom: 1px solid rgb(144, 144, 144);
    }
    .card-image{
        height:55px;
    }

    .card-body{
        padding:0px;
        padding-top:10px;
    }
    .card-description{
        color:white;
        font-size:13px;
        line-height:16px;
        font-weight:400;
    }
`

export default AccordionStyles


export const AccordionInvertStyles = styled(AccordionStyles)`
    .card-header{
        margin:0 !important;
        padding:10px 30px !important;
    }
    .system-card{
      z-index:10;
      display:flex;
      align-items:center;

      justify-content:space-between;
      .title-container{
        display:flex;
        flex-direction:column;
        height: 100%;
        justify-content: center;
        padding:0;
        margin:0px;
        width:150px;
        h1{
          font-size:14px;
          font-weight:700;
          margin:0;
          color:white;
        }
      }
      .image-container{
        height:78px;
        img{
          height:100%;
        }
      }
    }
`