import React from 'react'
import LevelOne from '../../dental/mobile/LevelOne';

const UniversalExpanders = (props) => {
	return (
		<LevelOne
			system={"universalExpanders"}
		/>
	)
}

export default UniversalExpanders