import React from 'react'
import LevelOne from "../maxillofacial/desktop/LevelOne";
import Tornillos from "../../assets/images/CranioMaxiloFacial/MediumplatingSystem/level1/medium-tornillos.png"
import Placas from "../../assets/images/CranioMaxiloFacial/MediumplatingSystem/level1/medium-placas.png"
import Instrumental from "../../assets/images/CranioMaxiloFacial/MediumplatingSystem/level1/medium-instrumental.png"
import Sets from "../../assets/images/CranioMaxiloFacial/MediumplatingSystem/level1/medium-set.png"

const MediumPlatingSystem = (props) => {
	return (
		<LevelOne
			title={"maxillofacial.mediumplatingSystem.self"}
			subtitle={"maxillofacial.mediumplatingSystem.subtitle"}
			productImages={[Tornillos,Placas,Instrumental,Sets]}
			productTitle={"maxillofacial.mediumplatingSystem.products"}
			productDescription={"maxillofacial.mediumplatingSystem.products"}
			openPage={["/maxillofacial/mediumplating_system/screws","/maxillofacial/mediumplating_system/plates","/maxillofacial/mediumplating_system/instruments","/maxillofacial/mediumplating_system/sets"]}
			currentPage = {2}
			classImage= {['','','','imageMediumlating']}
		/>
	)
}

export default (MediumPlatingSystem)