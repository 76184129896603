/*----- Core -----*/
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { Trans } from "react-i18next";
import { MobileStyles } from "../Styles";
import { postsSelector, loadingPostSelector, totalNewsSelector } from '../redux/NewSelectors'
import { fetchNews, resetNews } from '../redux/NewsActions';
import { withTranslation } from 'react-i18next';
import NoveltyTile from '../../../components/news/NoveltyTile';
import BackButton from '../../../components/button/BackButton'

const News = (props) => {
	const [per_page] = useState(6);
	const [page] = useState(1);

	const {
		fetchNews,
		posts,
		resetNews
	} = props;

	useEffect(() => {
		resetNews()
		const payload = {
			"per_page": per_page,
			"page": page
		}
		fetchNews(payload);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const renderNews = () => {
		return posts.map((post, id) => {
			return (
				<NoveltyTile 
					key = {id}
					image={post.attributes.main_image.url}
					title={post.attributes.title}
					subtitle={post.attributes.subtitle}
					tags={post.attributes.tags}
					urlPath={`/news/${post.id}`}
					main={true}
				/>

			)
		})
		
	}

	return (
		<MobileStyles>
			<div className="content-text">
				<div className="content-title">
					<div className="title">
						<h1><Trans>news.self</Trans></h1>
						<span className="dot"></span>
					</div>
					<p><Trans>news.description</Trans></p>
				</div>
			</div>
			<div className="content-news">
				{renderNews()}
				<BackButton />
			</div>
		</MobileStyles >
	);

}

const mapStateToProps = (state) => {
	return {
		posts: postsSelector(state),
		isLoading: loadingPostSelector(state),
		totalNews: totalNewsSelector(state)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchNews: (payload) => fetchNews(dispatch, payload),
		resetNews: (payload) => resetNews(dispatch, payload),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation() (News))