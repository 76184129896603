import React from 'react';
import Styles from './Styles';
import { Trans } from 'react-i18next';

import systems from '../../systems';

const Sets = ({system}) => {
  return(
    <Styles>
      <p><Trans>{systems[system].sets.text}</Trans></p>
      {systems[system].sets.items.map(item =>{
        return (
          <p className="m-0"><Trans>{item}</Trans></p>
        )
      }) }
      
    </Styles>
  )
}

export default Sets