import styled from "styled-components";

export default styled.main`
    background:black;
    position:relative;
    background:rgba(28,28,28,1);
    height: 1361px;
    @media screen and (max-width:1440px){
        height:auto;
    }
    .pop-up-style-content{
			padding:0px !important;
			width:100% !important;
			background: linear-gradient(to bottom left,rgba(72,71,71,0.5) 15%,rgba(72,71,71,1) 100%) !important;
			border:0px solid !important;
			position:relative;
			overflow:hidden; 
			border-radius:5px;

			@media (min-width:1500px){
				margin:30px !important;
			}
			@media(min-width:576px) and (max-width:1500px){
				margin:20px !important;
			}
			@media (max-width:576px){
					margin:10px !important;
			}
	}
    .column-card{
        margin:0px 40px !important;
    }
	.card-content{
        background: transparent linear-gradient(to bottom, #2e2e2e94 0%, #000000a6 100%) 0% 0% no-repeat padding-box;
		transition: transform .2s; /* Animation */
        border:2px solid black;
        border-radius:36px !important;
        z-index:50;
        width:380px;
        min-height:560px;
        margin:0px 20px;
        @media screen and (max-width:1440px){
            width:310px;
        }
	}
    .card-content:first-child{
        margin-left:0px;
    }
    .card-content:last-child{
        margin-right:0px;
    }
	.card-content:hover{
		transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
	}
    .content-buttons{
        display:flex;
        width:100%;
        justify-content:center;
        margin-top:18px;
        button{
            background:rgba(28,28,28,1);
            border: 1px solid white;
            width:15%;
            border-radius: 5px !important;
            outline:none;
            font-family: 'Montserrat',sans-serif;
            margin:0 10%;
            transition: transform .2s; /* Animation */
            font-size:12px;
            padding:10px;
            font-weight:700;
        }
        button:hover {
            background:white !important;
            border:1px solid black !important;
            color:black !important;
            transform: scale(1.03); 
        }
        button:active{
            border: 1px solid white;
            box-shadow:none !important;
        }
        button:focus{
            border: 1px solid white;
            box-shadow:none !important;
        }
    }
	.card-body{
        z-index:50;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:0;
	}
	.content-button{
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0px 0px 40px 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        .card-button{
            background:transparent !important;
            width: 142px;
            height: 41px;
            z-index:50;
            color:white;
            border:2.5px solid white;
            font-size:12px;
            font-weight:700;
            font-family: 'Montserrat', sans-serif;
            border-radius:25px;
        }
        .card-button:hover {
            background:white !important;
            border:1.5px solid white;
            color:black;
            
        }
    }
    .card-images{
        z-index:50;
        position:absolute; 
        display:flex;
        justify-content:center;
        width:100%;
        .card-image-0{
            position:relative;
            bottom:75px;
            z-index:50;
        }
        .card-image-1{
            position:relative;
            bottom:58px;
            z-index:50;
        }
        .card-image-2{
            position:relative;
            bottom:82px;
            z-index:50;
        }
        .card-image-3{
            position:relative;
            bottom:118px;
            width:65%;
            z-index:50;
        }
        .imageMandibular{
            width:50%;
            bottom: 170px;
        }
        .imageMediumlating{
            width:60%;
        }
    }
    .dot{
        height: 5px;
        width: 5px;
        background-color: #49D3EA;
        border-radius: 50%;
        display: inline-block;
        margin-left: 2px;
    }
	.card-text{
        display:flex;
        justify-content:center;
        z-index:50;
        margin:12px 0px 40px 0px;
        font-family: 'Montserrat', sans-serif;
        color:white;
        text-align:center;
        font-size:20px;
        line-height:23px;
        width: 283px;
        height:100%;
	}
	.card-title{
        z-index:50;
        text-align: center;
		color:white;
		font-weight:700;
		font-family: 'Montserrat';
		font-size:26px;
        margin:0px;
        margin-top:133px;
        padding:0px;
	}
  .container-cards{
        margin-bottom:10%;
        z-index:50;
        margin-right: -24px;
        margin-left: -24px;

        @media screen and (max-width:1440px){
            display:flex;
            justify-content:center;
        }
  }
  .filter-blur{
  filter:blur(5px);
	}
    .first-row{
        position:relative;
        width:100%;
    }
    .onda-gris-reverse{
        position: absolute;
        z-index: 15;
        bottom: 0;
    }
    .pop-up-style-content{
			padding:0px !important;
			width:100% !important;
			background: linear-gradient(to bottom left,rgba(72,71,71,0.5) 15%,rgba(72,71,71,1) 100%) !important;
			border:0px solid !important;
			position:relative;
			overflow:hidden; 
			border-radius:5px;

			@media (min-width:1500px){
				margin:30px !important;
			}
			@media(min-width:576px) and (max-width:1500px){
				margin:20px !important;
			}
			@media (max-width:576px){
					margin:10px !important;
			}
	}
    .filter-blur-footer{
        width: 100%;
        height: 5000px;
        position: relative;
        bottom: 0%;
    }
    .second-gradient{
        background: linear-gradient(to bottom,rgba(0,0,0,1) 5%,rgba(0,0,0,1) 10%,rgba(50,50,50,0.7) 25%,rgba(45,45,45,1) 80%,rgba(0,0,0,0.8) );
        width: 100%;
        height: 50%;
        z-index: 14;
        position: absolute;
        top: 50%;
    }
    .second-row{
        position:relative;
        z-index:50;
        margin-top:198px;
    }
    .second-row-IMF{
        position:relative;
        z-index:50;
        margin-top:35px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .titles{
        text-align:center;
        margin:0;
        margin-top:120px;
        height:min-content;
        z-index:50;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h1{
            font-family: 'Montserrat', sans-serif;
            color:#EBEBEB;
            font-weight:700;
            display:block;
            font-size:50px;
            margin:0;
        }
        h2{
            font-family: 'Montserrat', sans-serif;
            color:#F8F8F8;
            margin:0;
            margin-top:20px;
            display:block;
            width:596px;
            height: 136px;
            font-size:26px;
            line-height:36px;
        }
    }
    .imf{
        height: 18vh !important;
    }
    .imf_titles{
        top:27%;
        z-index:50;
        text-align:center;
        margin-top:2%;
        display:flex;
        flex-direction:column;
        align-items:center;
        h1{
            font-family: 'Montserrat', sans-serif;
            color:#EBEBEB;
            font-weight:700;
            font-size:45px;
            display:block;
        }
        h2{
            font-family: 'Montserrat', sans-serif;
            color:#F8F8F8;
            margin-top:20px;
            display:block;
            font-size:26px;
            width: 50%;
            font-weight:300;
        }
    }
    .card-imf{
        background: linear-gradient(45deg,rgba(0,0,0,0.69) ,rgba(74,73,73,0) 85%);
        width:50%;
        border-radius: 20px;
        padding:10px;
        display:flex;
        flex-direction:row;
        .content-images{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top:3%;
        }
        h3{
            color: white;
            font-family: 'Montserrat',sans-serif;
            font-weight: 700;
            text-align: center;
        }
        .head{
            background:transparent !important;
            border:0px;
            .tr-head{
                background:transparent !important;
                font-size:16px;
                border:0px;
                font-family: 'Montserrat', sans-serif;
                @media (max-width:1280px){
                    font-size:14px;
                }
            }
            th{
                padding: 10px;
                background:transparent !important;
                text-align: center;
                border:0px;
                vertical-align: middle;
                z-index:900;
                position: sticky; top: 0;
            }
        }
        td{
            text-align:center;
            padding:5px !important;
            vertical-align: middle;
            background:transparent !important;
            border:0px;
        }
        .table{
            background:transparent !important;
        }
    }
`
