import { put,takeLatest } from 'redux-saga/effects'
import { 
    FETCH_NEW,
    SUCCESS_NEW
} from '../NewConst'
// Api
import PostApi  from '../../../../services/PostApi';

function* fetchNew(action){
    const data = yield PostApi(action.params)
    .then(posts => {
        return posts.data
    })
    .catch(e=>{
        return e.message
    })
    yield put({type: SUCCESS_NEW, payload: data})
}


export default function* fetchListWatcher() {
	yield takeLatest(FETCH_NEW, fetchNew)
}