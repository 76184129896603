import { combineReducers } from 'redux'
import { 
    FETCH_NEWS,
    FETCH_NEW,
    SUCCESS_NEWS,
    SUCCESS_NEW,
    RESET_NEWS
} from './NewConst'

/*----- Reducer -----*/
const initialState = {
    newsData: [],
    newData: [],
    isLoadingNews: false,
    isLoadingDetail: false,
    totalNews: 0
}
/*----- Reducer methods -----*/

export function Post(state = initialState, action) {
    switch(action.type) {
        case FETCH_NEWS:
            return {
                ...state,
                isLoadingNews : true,
            }
        case SUCCESS_NEWS: {
            return {
                ...state,
                newsData: state.newsData.concat(action.payload.data),
                isLoadingNews: false,
                totalNews: action.payload.meta.total_results
            }
        }
        case RESET_NEWS: {
            return {
                ...state,
                newsData: [],
                totalNews: 0
            }
        }
        case FETCH_NEW:
            return {
                ...state,
                isLoadingDetail:true,
            }
        case SUCCESS_NEW: {
            return {
                ...state,
                newData:action.payload,
                isLoadingDetail: false
            }
        }
        default:
            return state
        }
}
export default combineReducers({
    Post
})
