import styled from "styled-components";

export const Styles = styled.div`
  background:#1C1C1C;
  position:relative;
	padding:2%;
  .col-md-2{
    z-index:10;
  }
  .content-row-cards{
    flex-direction:row;
    align-items:flex-end;
    width:100%;
    padding:1% 0;
    margin-bottom: 30px;
  }
  .content-images-wave{
    position:absolute;
    width:100%;
    .image-wave-reverse{
      position:relative;
      z-index: 1;
      left: -13%;
      transform: scaleX(-1);
    }
    .image-wave{
      position:relative;
      z-index: 1;
      z-index: 10;
      right: -10%;
    }
  }
  .titles-container{
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .title{
    margin:0;
    font-family: 'Montserrat', sans-serif;
    color:white;
    text-align:center;
    z-index:100;
    border:0px;
    font-weight:700;
    font-size:45px;
  }
  .subtitle{
    font-family: 'Montserrat', sans-serif;
    color:#F8F8F8;
    margin:20px 0px 25px 0px;
    text-align:center;
    display:block;
    font-size:26px;
    font-weight:300;
    width: 50%;
  }
  h3{
    font-weight:700;
    font-size:23px;
    text-transform: uppercase;
    text-align:center;
		margin:0 0 0 0;
    margin-bottom: 0px !important;
		font-family: 'Montserrat', sans-serif;
		color:white;
		z-index: 10;
    position: relative;
  }
  .content-buttons{
			display:flex;
			width:100%;
			justify-content:center;
			padding-top:20px;
			z-index:50;
			button{
        background: transparent !important;
        width: 142px;
        height: 41px;
        z-index: 50;
        color: white;
        border: 2.5px solid white;
        font-size: 12px;
        font-weight: 700;
        border-radius: 25px;
        margin:0 10%;
      }
			button:hover {
					background:white !important;
					border:1px solid black !important;
					color:black !important;
					transform: scale(1.03);
			}
			button:active{
					border: 1px solid white;
					box-shadow:none !important;
			}
			button:focus{
					border: 1px solid white;
					box-shadow:none !important;
			}
	}
`
export const ProductCardStyles = styled.div`
  background: linear-gradient(45deg,rgba(0,0,0,0.69) ,rgba(74,73,73,0) 85%);
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 15px;
  margin-top:10px;
  z-index: 50;
  .content-image{
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    z-index: 10;
    justify-content: center;
    img{
      z-index: 50;
    }
  }
  .content-text{
    h1{
      color: white;
      font-family: 'Montserrat',sans-serif;
      font-size: 18px;
      font-weight: 600;
      border:0;
      z-index: 10;
    }
    .description{
      display:flex;
      margin-top:10px;
      z-index: 10;
      justify-content:space-between;
      p{
        margin: 0;
        color: #D6D6D6;
        z-index: 10;
        font-size: 13px;
        display: inline;
      }
    }
  }
`