import React from 'react'
import SmartImplant from '../../../../dental/mobile/LevelTwo/SmartImplant';
import image from "../../../../../assets/images/Dental/internalHexagonConnection/smartImplants/modals/cyroth_nano_circles_mobile.png";

const CyrothShort = (props) => {
	return (
		<SmartImplant
			image={image}
			reference={2}
			system='internalHexagonConnection'
			short
		/>
	)
}

export default CyrothShort