import React from 'react'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import Arrow from "../../assets/icons/icon-components/Arrow.js";

const CustomToggle = (props) => {
	const { eventKey, open, setOpen } = props
	const decoratedOnClick = useAccordionToggle(eventKey, () => setOpen(!open));

	return (
		<div onClick={decoratedOnClick} style={{ zIndex: "100", display:'flex', alignItems:'center' }} >
			<div className="mr-2">
				{props.children}
			</div>
			<Arrow rotateDeg={open ? "90" : "270"} color={"#49D3EA"} width="10px" strokeWidth="50px" />
		</div>
	)
}


export default CustomToggle