/*----- Core -----*/
import PersonCard from '../card/PersonCard';
import React from 'react'
import PropTypes from 'prop-types'

/*----- Components -----*/
function TeamList({team, onClickFunc}) {	
	return (
		<>
			<h3>Team list</h3>
			{
				team.map( (user, i) => {
					return (
						<PersonCard
							key     = {i}
							name    = {user.name}
							email   = {user.email}
							onClick = { ()=>onClickFunc(user.id)}
						/>
					)
				})
			}
		</>
	)
}
export default TeamList

/*----- PropTypes -----*/
TeamList.propTypes = {
	team:  PropTypes.array.isRequired,
	title: PropTypes.func
}
