import StringifiedParameters from './StringifiedParameters'

export default (baseUrl, resources, parameters = {}, method = "get") => {

	let Payload = StringifiedParameters(parameters)
	return fetch(`${baseUrl}/${resources}?${Payload}`,
		{
			method: method
		})
		.then(response => response.json())
		.catch((error) => {
			console.log('Error al hacer el request: ', error)
		})
}
