import React from 'react'
import Screws from "../../maxillofacial/desktop/Screws"
import * as data from "./data.js";

/* Assets */
import Image_1_0 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Screws/01_MICS-1.2-S-8.svg"
import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Screws/image_1_0.png"
import Image_2_0 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Screws/02_MICS-1.2-C-8 1.svg"
import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Screws/image_2_0.png"
import Image_3_0 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Screws/03_MICS-1.4-ES-8 1.svg"
import Image_4_0 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Screws/04_MICS-1.4-EC-8 1.svg"

const MicroPlatingScrews = (props) => {
	return (
		<Screws
			title={"microPlatingSystem.products.1.name"}
			navigate={"microplating_system"}
			subtitle={"microPlatingSystem.products.1.description"}
			sectionTitles={["microPlatingSystem.screws.1","microPlatingSystem.screws.2","microPlatingSystem.screws.3","microPlatingSystem.screws.4"]}
			sectionImagesFirst = {[Image_1_0,Image_2_0,Image_3_0,Image_4_0]}
			sectionImagesSecond= {[Image_1_1,Image_2_1,Image_1_1,Image_2_1]}
			sectionData={[data.dataSection_1,data.dataSection_2,data.dataSection_3,data.dataSection_4]}
		/>
	)
}

export default (MicroPlatingScrews)