import React,{useState} from 'react'
import Sets from "../../maxillofacial/desktop/Sets"
import {dataGenerator} from "./data.js";
import i18n from "i18next";

const FracturePlatingSystemSets = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<Sets
			title={"fracturePlatingSystem.products.4.name"}
			navigate={"fracture_system"}
			subtitle={"fracturePlatingSystem.products.4.description"}
			sectionTitles={["fracturePlatingSystem.sets.titles.1"]}
			titleExtended={["fracturePlatingSystem.sets.titles_extended.1"]}
			sectionData={[data.dataSection_1]}
		/>
	)
}

export default (FracturePlatingSystemSets)