import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_1.png"
import Image_2_2 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_2.png"
import Image_2_3 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_3.png"
import Image_2_4 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_4.png"
import Image_2_5 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_5.png"
import Image_2_6 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_6.png"
import Image_2_7 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_7.png"
import Image_2_8 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_8.png"
import Image_2_9 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_9.png"
import Image_2_10 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_10.png"
import Image_2_11 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_11.png"
import Image_2_12 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_12.png"
import Image_2_13 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_13.png"
import Image_2_15 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Instruments/image_15.png"

export default function data(lng) {

  const instrumentsData_1_en = [" Plate and Wire cutting Plier with TC 22 cm.", " Plate bending plier with pin 13.5 cm.", " CUSHING Plate/Screw holding forcep 17.5 cm. Straight.", " CUSHING Plate/Screw holding forcep 17.5 cm. Angled."]
  const instrumentsData_1_es = [" Alicate de corte de placa y alambre con TC 22 cm.", "Alicate para doblar placas con pin 13.5 cm.", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Recta.", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Angulada."]

  const instrumentsData_2_en = [ " Screwdriver detachable complete. Cross Head.", " Screwdriver detachable complete. Hexagon Head.", " Depth Gauge 18 cm.", " Drill Guide with handle for 2.3 mm screws."]
  const instrumentsData_2_es = [" Destornillador desmontable completo. Cross Head.", " Destornillador desmontable completo. Hexagon Head.", " Medidor de profundidad 18 cm.", " Guía de perforación con mango para tornillos 2.3 mm."]

  const instrumentsData_3_en = [" Spiral-Drill with Sk-connection, Ø 1.8 mm. x 98 mm. for 2.3 mm. Screws."," Spiral-Drill with Ao-connection, Ø 1.8 mm. x 95 mm. for 2.3 mm. Screws."," Spiral-Drill with Dental-connection, Ø 1.8 mm. x 80 mm. for 2.3 mm. Screws."," Spiral-Drill with Sk-connection, Ø 2.0 mm. x 105 mm. for 2.7 mm. Screws."," Spiral-Drill with Ao-connection, Ø 2.0 mm. x 103 mm. for 2.7 mm. Screws."]
  const instrumentsData_3_es = [" Spiral-Drill con conexión-Sk, Ø 1.8 mm. 98 mm. p/ tornillo 2.3 mm.", " Spiral-Drill con conexión-Ao, Ø 1.8 mm. 95 mm. p/ tornillo 2.3 mm.", " Spiral-Drill con conexión-Dental, Ø 1.8 mm. 80 mm. p/ tornillo 2.3 mm.", " Spiral-Drill con conexión-Sk, Ø 2.0 mm. 105 mm. p/ tornillo 2.7 mm.", " Spiral-Drill con conexión-Ao, Ø 2.0 mm. 103 mm. p/ tornillo 2.7 mm."]

  const instrumentsData_4_en = [" Transbuccal drilling guide complete for screwdriver without holding device / Ø 3.5 mm."," Transbuccal drilling guide complete for screwdriver with holding device / Ø 6.0 mm."]
  const instrumentsData_4_es = [" Guía de perforación transbucal completa para destornillador sin dispositivo de sujeción / Ø 3.5 mm.", " Guía de perforación transbucal completa para destornillador con dispositivo de sujeción / Ø 6.0 mm."]

  const instrumentsData_1 = [
    ["F2.3-70418", "F2.3-70213", "F2.3-40018", "F2.3-45018"],
    lng === "en" ? instrumentsData_1_en : instrumentsData_1_es,
    [Image_2_1, Image_2_2, Image_2_3, Image_2_4]
  ]
  const instrumentsData_2 = [
    ["F2.3-23218", "F2.3-23318", "F2.3-23018", "F2.3-28100"],
    lng === "en" ? instrumentsData_2_en : instrumentsData_2_es,
    [Image_2_5, Image_2_6, Image_2_7, Image_2_8]
  ]
  const instrumentsData_3 = [
    ["F2.3-23098", "F2.3-23095", "F2.3-23080", "F2.3-27105", "F2.3-27103"],
    lng === "en" ? instrumentsData_3_en : instrumentsData_3_es,
    [Image_2_9, Image_2_10, Image_2_11, Image_2_12, Image_2_13]
  ]
  const instrumentsData_4 = [
    ["F2.3-35000", "F2.3-60000"],
    lng === "en" ? instrumentsData_4_en : instrumentsData_4_es,
    [Image_2_15, Image_2_15]
  ]


  let data = {
    instrumentsData_1,
    instrumentsData_2,
    instrumentsData_3,
    instrumentsData_4
  }
  return data;
}