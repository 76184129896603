// Core
import React from 'react'
import styled from 'styled-components'
import { A } from "hookrouter"
// Global
import Roboto from '../../commons/mixins/fonts/Roboto'
import CardStyles from '../../commons/mixins/CardStyles'
// Constants
import { ROOT_URL } from '../../services/BaseUrl'

// Components
import Tag from '../../components/Tag'

const StyleNoveltyCard = styled(A)`
	${ CardStyles()}
	width: 100%;
	position: relative;
	display: block;
	background-size: cover;
	background-position: ${(props) => props.main ? "bottom bottom": "center center" };
	height: 383px;
	margin-bottom:43px;
	border-radius: 25px;
	height: ${(props) => props.main ? "341px" : ""};
	
.cover {
	border-radius: 6px;
	position: absolute;
	height: 100%;
	width: 100%;
	background: transparent linear-gradient(180deg, #FFFFFF00 0%, #0000003E 14%, #000000E0 100%) 0% 0% no-repeat padding-box;
	border-radius: 25px;
}
.novelty-data {
	box-shadow: inset 0 -160px 80px -10px rgba(0, 0, 0, 0.5);
	height: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	border-radius: 25px;
	width: 100%;
	.date {
		${ Roboto(8, 400, 'black')};
		background-color: white;
		text-align: center;
		padding: 4px 16px;
		letter-spacing: 4px;
		border-radius: 25px;
		display: inline-block;
		width: fit-content;
	}
	.title {
		font-family: 'Montserrat', sans-serif;
		font-size:${(props) => props.main ? "23px" : "18px"};
		text-align:left;
		color:white;
		line-height: 27px;
		font-weight:700;
		margin-bottom: ${(props) => props.main ? "18px": "14px" }
	}
	.subtitle {
		font-family: 'Montserrat', sans-serif;
		font-size:${(props) => props.main ? "18px" : "13px"};
		text-align:left;
		color:white;
		line-height: ${(props) => props.main ? "20px" : "16px"};
		font-weight:300;
		margin-bottom: ${(props) => props.main ? "27px": "21px" }
	}
	.card-text{
		margin: ${(props) => props.main ? "0px 0px 34px 50px": "0px 19px 20px 19px" }
	}
	.tags{
		span{
			color:white;
			border:1px solid white;
			margin:0px 5px 0px 0px;
		}
	}
}
`

const NoveltyCard = ({ urlPath, tags, image, title, subtitle , main}) => {
	return (
		<StyleNoveltyCard
			href={urlPath}
			style={{backgroundImage: `url(${ROOT_URL}${image})`}}
			className='image'
			main={main}
		>
			<div className="cover">
			</div>
			<div className="novelty-data d-flex flex-column justify-content-end">
				<div className="card-text">
					<div className="title">{title}</div>
					<div className="subtitle">{subtitle}</div>
					<div className="tags">
						<div>{tags.map((tag, i) => <Tag key={i} content={tag.name}/>)}</div>
					</div>
				</div>
			</div>
		</StyleNoveltyCard>
	)
}
export default NoveltyCard
