import styled from 'styled-components'

const Styles = styled.div`
    padding:10px;
    z-index:10;
    height:100px;
    background: transparent linear-gradient(90deg, #333D98 0%, #4345A6 10%, #695AC7 37%, #8469DF 62%, #9572ED 83%, #9B76F3 100%) 0% 0% no-repeat padding-box;
    border-radius:17px;
    display:flex;
    align-items:center;
    justify-content:center;

    .container-card{
        display:flex;
        width:100%;
    }

    .first-col{
        text-align:center;
        width:28%;
        .card-image{
            height: 51px;
        }
    }
    .second-col{
        padding-left:8px;
        display:flex;
        align-items:center;
        .card-title{
            margin-bottom:0px;
            h1{
                margin-bottom:0px;
                line-height:18px;
                font-weight:700;
                font-size:14px;
                color:white;
            }
        }
    }
`

export default Styles