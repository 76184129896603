import styled from 'styled-components'

const ProstheticComponentsStyles = styled.div`
  padding-bottom:20px;
  .system-text{
    p{
      font-size:14px;
      line-height:16px;
      color:white;
      font-weight:400;
      margin-top:10px;
      z-index: 100;
    }

    .system-list{
      display:flex;
      flex-direction:column;
      .element{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin:5px 0px;
        .dot{
          margin-top:5px;
          height: 6px;
          width: 6px;
          background-color:#7161FF;
          border-radius: 50%;
          display: inline-block;
          margin-right: 4px;
        }
        p{
          color:white;
          margin:0;
          font-size:14px;
          font-weight:400;
          line-height:16px;
          z-index: 100;
        }
      }
    }
  }
`
export default ProstheticComponentsStyles;