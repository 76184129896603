import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_1.png"
import Image_2_2 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_2.png"
import Image_2_3 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_3.png"
import Image_2_4 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_4.png"
import Image_2_5 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_5.png"
import Image_2_6 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_6.png"
import Image_2_7 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_7.png"
import Image_2_8 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_8.png"
import Image_2_9 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_9.png"
import Image_2_10 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_10.png"
import Image_2_11 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_11.png"
import Image_2_12 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_12.png"
import Image_2_13 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_13.png"
import Image_2_15 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Instruments/image_15.png"

export function dataGenerator(lng) {

	const instrumentsData_1_en = [" Titanium plate bending tool 15 cm.", " Plate Holding Forcep.", " Plate Holding Forcep.", " Plate bending pliers with titaniumrolls for plate-thickness 2.2 - 2.5 mm. 16.5 cm."]
	const instrumentsData_1_es = ["Grifa para doblar placas de Titanio 15 cm.", "Pinza de sujeción de placa ", " Pinza de sujeción de placa ", "Dobladora de placas con rollos de Titanio para placas de espesor 2.2 - 2.5 mm. 16.5 cm."]

	const instrumentsData_1_detail_en = ["", "15.5 cm. Left", "15.5 cm. Right", ""]
	const instrumentsData_1_detail_es = ["","15.5 cm. Izquierda", "15.5 cm. Derecha", ""]

	const instrumentsData_2_en = [" Screwdriver detachable complete. Self Fitting. Cross Head.", " Screwdriver detachable complete. Cross Head."," Tap for Ø 2.7 mm screws."," Depth Gauge up to 45 mm."]
	const instrumentsData_2_es = ["Destornillador desmontable completo. Autoajustable.", "Destornillador desmontable completo.", "Herramienta de roscar", "Medidor de profundidad hasta 45 mm."]

	const instrumentsData_3_en = ["Drill Guide for mandibular system."," Spiral-Drill with Sk-connection"," Spiral-Drill with with AO-connection"," Spiral-Drill with Dental-connection"]
	const instrumentsData_3_es = ["Guía de perforación para el sistema mandibular.", "Spiral-Drill con conexión-Sk", " Spiral-Drill con conexión-Ao", " Spiral-Drill con conexión-Dental"]

	const instrumentsData_4_en = ["Plate Cutting Forceps 57.5 cm."," Transbuccal drilling guide complete for screwdriver without holding device / Ø 3.5 mm."," Transbuccal drilling guide complete for screwdriver with holding device / Ø 6.0 mm."]
	const instrumentsData_4_es = ["Alicate para cortar placa 57.5 cm.", "Guía de perforación transbucal completa para destornillador sin dispositivo de sujeción / Ø 3.5 mm.", "Guía de perforación transbucal completa para destornillador con dispositivo de sujeción / Ø 6.0 mm."]

	const instrumentsData_1 = [
		["F2.7-22150", "F2.7-22154", "F2.7-22155", "F2.7-25160"],
		lng === "en" ? instrumentsData_1_en : instrumentsData_1_es,
		lng === "en" ? instrumentsData_1_detail_en : instrumentsData_1_detail_es,
		[Image_2_1, Image_2_2, Image_2_3, Image_2_4, Image_2_5]
	]
	const instrumentsData_2 = [
		["F2.7-27218S", "F2.7-27218", "F2.7-27056", "F2.7-27018"],
		lng === "en" ? instrumentsData_2_en : instrumentsData_2_es,
		["Cross Head.", "Cross Head.", "Ø 2.7 mm.", ""],
		[Image_2_5, Image_2_6, Image_2_7, Image_2_8]
	]
	const instrumentsData_3 = [
		["F2.7-28100", "F2.7-20105", "F2.7-20103", "F2.7-20101"],
		lng === "en" ? instrumentsData_3_en : instrumentsData_3_es,
		["","Ø 2.0 mm. 35 mm. stop. 105 mm.", "Ø 2.0 mm. 35 mm. stop. 103 mm.", "Ø 2.0 mm. 35 mm. stop. 101 mm."],
		[Image_2_9, Image_2_10, Image_2_11, Image_2_12],
	]
	const instrumentsData_4 = [
		["F2.7-47000", "F2.7-35000", "F2.7-60000"],
		lng === "en" ? instrumentsData_4_en : instrumentsData_4_es,
		[],
		[Image_2_13, Image_2_15, Image_2_15]
	]

	let data = {
		instrumentsData_1,
		instrumentsData_2,
		instrumentsData_3,
		instrumentsData_4
	}
	return data;
}