/*----- Core -----*/
import React from "react";
import { connect } from "react-redux";

/*----- Assets -----*/
import Image from "../../assets/images/beach.jpg";

/*----- Actions -----*/

/*----- Selectors -----*/

/*----- Components -----*/
import LandscapeCard from "../../components/card/LandscapeCard";
import DescriptionCard from "../../components/card/DescriptionCard";

/*----- Commons -----*/
import "../../commons/i18n";
import { Trans } from "react-i18next";
import Styles from "../../commons/styles/ProductsMainPage.js";

const Implants = () => {
	return (
		<Styles>
			<React.Fragment>
				<LandscapeCard
					title={<Trans>implants.title</Trans>}
					body={<Trans>implants.landscape_body</Trans>}
					imageUrl={Image}
				/>
				<div className="row padding-cards">
					<DescriptionCard
						title={<Trans>implants.title_card1</Trans>}
						body={<Trans>implants.body_card1</Trans>}
						imageUrl={Image}
						button={true}
					/>
					<DescriptionCard
						title={<Trans>implants.title_card2</Trans>}
						body={<Trans>implants.body_card2</Trans>}
						imageUrl={Image}
						button={true}
					/>
				</div>
			</React.Fragment>
		</Styles>
	);
};

export default connect()(Implants);
