import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_1.png"
import Image_1_2 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_2.png"
import Image_1_3 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_3.png"
import Image_1_4 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_4.png"
import Image_1_5 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_5.png"
import Image_1_6 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_6.png"
import Image_1_7 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_7.png"
import Image_1_8 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_8.png"
import Image_1_9 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_9.png"
import Image_1_10 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_10.png"
import Image_1_11 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_11.png"
import Image_1_12 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_12.png"
import Image_1_13 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_13.png"
import Image_1_14 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_14.png"
import Image_1_15 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_15.png"
import Image_1_16 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_16.png"
import Image_1_17 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_17.png"
import Image_1_18 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_18.png"
import Image_1_19 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_19.png"
import Image_1_20 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_20.png"
import Image_1_21 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_21.png"
import Image_1_22 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_22.png"
import Image_1_23 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_23.png"
import Image_1_24 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_24.png"
import Image_1_25 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_25.png"
import Image_1_26 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_26.png"
import Image_1_27 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_27.png"
import Image_1_28 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_28.png"
import Image_1_29 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_29.png"
import Image_1_30 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_30.png"
import Image_1_31 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_31.png"
import Image_1_32 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_32.png"
import Image_1_33 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_33.png"
import Image_1_34 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_34.png"
import Image_1_35 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_35.png"
import Image_1_36 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_36.png"
import Image_1_37 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_37.png"
import Image_1_38 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_38.png"
import Image_1_39 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_39.png"
import Image_1_40 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_40.png"
import Image_1_41 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_41.png"
import Image_1_42 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_42.png"
import Image_1_43 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_43.png"
import Image_1_44 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_44.png"
import Image_1_45 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_45.png"
import Image_1_46 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_46.png"
import Image_1_47 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_47.png"
import Image_1_48 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_48.png"
import Image_1_49 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_49.png"
import Image_1_50 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_50.png"
import Image_1_51 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_51.png"
import Image_1_52 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_52.png"
import Image_1_53 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_53.png"
import Image_1_54 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_54.png"
import Image_1_55 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_55.png"
import Image_1_56 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_56.png"
import Image_1_57 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_57.png"
import Image_1_58 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_58.png"
import Image_1_59 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_59.png"
import Image_1_60 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_60.png"
import Image_1_61 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_61.png"
import Image_1_62 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_62.png"
import Image_1_63 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_63.png"
import Image_1_64 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_64.png"
import Image_1_65 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_65.png"
import Image_1_66 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_66.png"
import Image_1_67 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_67.png"
import Image_1_68 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_68.png"
import Image_1_69 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_69.png"
import Image_1_70 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_70.png"
import Image_1_71 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_71.png"
import Image_1_72 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_72.png"
import Image_1_73 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_73.png"
import Image_1_74 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_74.png"
import Image_1_75 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_75.png"
import Image_1_76 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_76.png"
import Image_1_77 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_77.png"
import Image_1_78 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_78.png"
import Image_1_79 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_79.png"
import Image_1_80 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_80.png"
import Image_1_81 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_81.png"
import Image_1_82 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_82.png"
import Image_1_83 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_83.png"
import Image_1_84 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_84.png"
import Image_1_85 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_85.png"
import Image_1_86 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_86.png"
import Image_1_87 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_87.png"
import Image_1_88 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_88.png"
import Image_1_89 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_89.png"
import Image_1_90 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_90.png"
import Image_1_91 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_91.png"
import Image_1_92 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_92.png"
import Image_1_93 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_93.png"
import Image_1_94 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_94.png"
import Image_1_95 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_95.png"
import Image_1_96 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Plates/image_96.png"

export default function dataGenerator(lng) {

    const platesData_1_description_en = ["Straight Plate. 2-holes.", "Straight Plate. 4-holes.", "Straight Plate. 6-holes.", "Straight Plate. 8-holes.", "Straight Plate. 12-holes.", "Straight Plate. 16-holes.", "Straight Plate. 24-holes.", "Straight Plate. 40-holes.", "Straight Plate. 4-holes.", "Straight Plate. 4-holes.", "Straight Plate. 6-holes."]
    const platesData_1_description_es = ["Placa Recta. 2-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 6-agujeros.", "Placa Recta. 8-agujeros.", "Placa Recta. 12-agujeros.", "Placa Recta. 16-agujeros.", "Placa Recta. 24-agujeros.", "Placa Recta. 40-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 6-agujeros."]

    const platesData_1_detail_en = ["9 mm stem.", "", "", "", "", "", "", "", "9 mm stem.", "12 mm stem.", "12 mm stem."]
    const platesData_1_detail_es = ["9 mm puente.", "", "", "", "", "", "", "", "9 mm puente.", "12 mm puente.", "12 mm puente."]

    const platesData_2_description_en = ["L Plate. 4-holes. Left.", "L Plate. 4-holes. Right.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 5-holes.", "L Plate. 5-holes.", "L Plate. 6-holes.", "L Plate. 6-holes.", "L Plate. 6-holes.", "L Plate. 6-holes."]
    const platesData_2_description_es = ["Placa L. 4-agujeros. Izquierda.", "Placa L. 4-agujeros. Derecha.", "Placa L. 4-agujeros.", "Placa L. 4-agujeros.", "Placa L. 4-agujeros.", "Placa L. 4-agujeros.", "Placa L. 4-agujeros.", "Placa L. 4-agujeros.", "Placa L. 5-agujeros.", "Placa L. 5-agujeros.", "Placa L. 6-agujeros.", "Placa L. 6-agujeros.", "Placa L. 6-agujeros.", "Placa L. 6-agujeros."]

    const platesData_2_detail_en = ["", "", "8 mm stem. Left.", "8 mm stem. Right.", "10 mm stem. Left.", "10 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right.", "9 mm stem. Left.", "9 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_2_detail_es = ["", "", "8 mm puente. Izquierda.", "8 mm puente. Derecha.", "10 mm puente. Izquierda.", "10 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha.", "9 mm puente. Izquierda.", "9 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_3_description_en = ["L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 5-holes.", "L Plate - 112°. 5-holes."]
    const platesData_3_description_es = ["Placa L - 112°. 4-agujeros.", "Placa L - 112°. 4-agujeros.", "Placa L - 112°. 4-agujeros.", "Placa L - 112°. 4-agujeros.", "Placa L - 112°. 5-agujeros.", "Placa L - 112°. 5-agujeros."]

    const platesData_3_detail_en = ["8 mm stem. Left.", "8 mm stem. Right.", "10 mm stem. Left.", "10 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_3_detail_es = ["8 mm puente. Izquierda.", "8 mm puente. Derecha.", "10 mm puente. Izquierda.", "10 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_4_description_en = ["L Plate - 100°. 7-holes.", "L Plate - 100°. 7-holes"]
    const platesData_4_description_es = ["Placa L - 100°. 7-agujeros.", "Placa L - 100°. 7-agujeros."]

    const platesData_4_detail_en = ["12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_4_detail_es = ["12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_5_description_en = ["T Plate. 6-holes.", "T Plate. 5-holes.", "T Plate. 4-holes.", "T Plate. 4-holes.", "T Plate. 6-holes.", "T Plate. 6-holes.", "T Plate. 6-holes.", "T Plate. 6-holes.", "T Plate. 6-holes."]
    const platesData_5_description_es = ["Placa T. 6-agujeros.", "Placa T. 5-agujeros.", "Placa T. 4-agujeros.", "Placa T. 4-agujeros.", "Placa T. 6-agujeros.", "Placa T. 6-agujeros", "Placa T. 6-agujeros"]

    const platesData_5_detail_en = ["", "12 mm stem.", "", "12 mm stem.", "", "7.5 mm stem.", "12 mm stem."]
    const platesData_5_detail_es = ["", "12 mm puente.", "", "12 mm puente.", "", "7.5 mm puente.", "12 mm puente."]

    const platesData_6_description_en = ["T Plate - 80°. 5-holes", "T Plate - 80°. 5-holes"]
    const platesData_6_description_es = ["Placa T - 80°. 5-agujeros", "Placa T - 80°. 5-agujeros"]

    const platesData_6_detail_en = ["12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_6_detail_es = ["12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_7_description_en = ["Double T Plate. 6-holes.", "Double T Plate. 4-holes."]
    const platesData_7_description_es = ["Doble Placa T. 6-agujeros.", "Doble Placa T. 4-agujeros."]

    const platesData_7_detail_en = ["", "12 mm stem."]
    const platesData_7_detail_es = ["", "12 mm puente."]

    const platesData_8_description_en = ["Y Plate. 5-holes.", "Y Plate. 4-holes.", "Y Plate. 5-holes.", "Y Plate. 5-holes."]
    const platesData_8_description_es = ["Placa Y. 5-agujeros.", "Placa Y. 4-agujeros.", "Placa Y. 5-agujeros.", "Placa Y. 5-agujeros."]

    const platesData_8_detail_en = ["", "12 mm stem.", "12 mm stem.", "6.5 mm stem."]
    const platesData_8_detail_es = ["", "12 mm puente.", "12 mm puente.", "6.5 mm puente."]

    const platesData_9_description_en = ["Double Y Plate. 6-holes.", "Double Y Plate. 7-holes.", "Double Y Plate. 6-holes.", "Double Y Plate. 6-holes."]
    const platesData_9_description_es = ["Doble Placa Y. 6-agujeros.", "Doble Placa Y. 7-agujeros.", "Doble Placa Y. 6-agujeros", "Doble Placa Y. 6-agujeros."]

    const platesData_9_detail_en = ["", "", "12 mm stem.", "18 mm stem."]
    const platesData_9_detail_es = ["", "", "12 mm puente.", "18 mm puente."]

    const platesData_10_description_en = ["Orbital Plate. 6-holes.", "Orbital Plate. 8-holes.", "Orbital Plate. 10-holes.", "Orbital Plate. 12-holes."]
    const platesData_10_description_es = ["Placa Orbital. 6-agujeros.", "Placa Orbital. 8-agujeros.", "Placa Orbital. 10-agujeros.", "Placa Orbital. 12-agujeros."]

    const platesData_11_description_en = ["X Plate. 4-holes."]
    const platesData_11_description_es = ["Placa X. 4-agujeros."]

    const platesData_12_description_en = ["Z Plate. 4-holes.", "Z Plate. 4-holes.", "Z Plate. 4-holes.", "Z Plate. 4-holes."]
    const platesData_12_description_es = ["Placa Z. 4-agujeros.", "Placa Z. 4-agujeros.", "Placa Z. 4-agujeros.", "Placa Z. 4-agujeros."]

    const platesData_12_detail_en = ["8 mm stem. Left.", "8 mm stem. Right.", "14 mm stem. Left.", "14 mm stem. Right."]
    const platesData_12_detail_es = ["8 mm puente. Izquierda.", "8 mm puente. Derecha.", "14 mm puente. Izquierda.", "14 mm puente. Derecha."]

    const platesData_13_description_en = ["Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate."]
    const platesData_13_description_es = ["Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática."]

    const platesData_13_detail_en = ["2x2-holes.", "3x2-holes.", "4x2-holes.", "4x4-holes.", "6x4-holes."]
    const platesData_13_detail_es = ["2x2-agujeros.", "3x2-agujeros.", "4x2-agujeros.", "4x4-agujeros.", "6x4-agujeros."]

    const platesData_14_description_en = ["Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate."]
    const platesData_14_description_es = ["Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular."]

    const platesData_14_detail_en = ["2x2-holes.", "3x2-holes.", "4x2-holes.", "4x4-holes.", "6x4-holes."]
    const platesData_14_detail_es = ["2x2-agujeros.", "3x2-agujeros.", "4x2-agujeros.", "4x4-agujeros.", "6x4-agujeros."]

    const platesData_15_description_en = ["Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes."]
    const platesData_15_description_es = ["Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros."]

    const platesData_15_detail_en = ["Depth 2 mm.", "Depth 4 mm.", "Depth 6 mm.", "Depth 8 mm.", "Depth 10 mm.", "Depth 12 mm.", "Depth 14 mm."]
    const platesData_15_detail_es = ["Profundidad 2 mm.", "Profundidad 4 mm.", "Profundidad 6 mm.", "Profundidad 8 mm.", "Profundidad 10 mm.", "Profundidad 12 mm.", "Profundidad 14 mm."]

    const platesData_16_description_en = ["LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes.", "LeFort Plate. 11-holes."]
    const platesData_16_description_es = ["Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros.", "Placa LeFort. 11-agujeros."]

    const platesData_16_detail_en = ["Depth 0 mm. Left.", "Depth 2 mm. Left.", "Depth 3 mm. Left.", "Depth 4 mm. Left.", "Depth 5 mm. Left.", "Depth 6 mm. Left.", "Depth 7 mm. Left.", "Depth 8 mm. Left.", "Depth 9 mm. Left.", "Depth 10 mm. Left.", "Depth 11 mm. Left.", "Depth 12 mm. Left.", "Depth 0 mm. Right.", "Depth 2 mm. Right.", "Depth 3 mm. Right.", "Depth 4 mm. Right.", "Depth 5 mm. Right.", "Depth 6 mm. Right.", "Depth 7 mm. Right.", "Depth 8 mm. Right.", "Depth 9 mm. Right.", "Depth 10 mm. Right.", "Depth 11 mm. Right.", "Depth 12 mm. Right."]
    const platesData_16_detail_es = ["Profundidad 0 mm. Izquierda.", "Profundidad 2 mm. Izquierda.", "Profundidad 3 mm. Izquierda.", "Profundidad 4 mm. Izquierda.", "Profundidad 5 mm. Izquierda.", "Profundidad 6 mm. Izquierda.", "Profundidad 7 mm. Izquierda.", "Profundidad 8 mm. Izquierda.", "Profundidad 9 mm. Izquierda.", "Profundidad 10 mm. Izquierda.", "Profundidad 11 mm. Izquierda.", "Profundidad 12 mm. Izquierda.", "Profundidad 0 mm. Derecha.", "Profundidad 2 mm. Derecha.", "Profundidad 3 mm. Derecha.", "Profundidad 4 mm. Derecha.", "Profundidad 5 mm. Derecha.", "Profundidad 6 mm. Derecha.", "Profundidad 7 mm. Derecha.", "Profundidad 8 mm. Derecha.", "Profundidad 9 mm. Derecha.", "Profundidad 10 mm. Derecha.", "Profundidad 11 mm. Derecha.", "Profundidad 12 mm. Derecha."]

    const platesData_17_description_en = ["Straight Plate. 2-holes.", "Straight Plate. 2-holes.", "Straight Plate. 4-holes.", "Straight Plate. 4-holes.", "Straight Plate. 40-holes."]
    const platesData_17_description_es = ["Placa Recta. 2-agujeros.", "Placa Recta. 2-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 40-agujeros."]

    const platesData_17_detail_en = ["9 mm stem.", "12 mm stem.", "9 mm stem.", "12 mm stem.", ""]
    const platesData_17_detail_es = ["9 mm puente.", "12 mm puente.", "9 mm puente.", "12 mm puente.", ""]

    const platesData_18_description_en = ["L Plate - 100°. 5-holes.", "L Plate - 100°. 5-holes.", "L Plate - 100°. 7-holes.", "L Plate - 100°. 7-holes.", "L Plate - 100°. 9-holes.", "L Plate - 100°. 9-holes."]
    const platesData_18_description_es = ["Placa L - 100°. 5-agujeros.", "Placa L - 100°. 5-agujeros.", "Placa L - 100°. 7-agujeros.", "Placa L - 100°. 7-agujeros .", "Placa L - 100°. 9-agujeros.", "Placa L - 100°. 9-agujeros."]

    const platesData_18_detail_en = ["12 mm stem. Left.", "12 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_18_detail_es = ["12 mm puente. Izquierda.", "12 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_19_description_en = ["T Plate. 4-holes.", "T Plate. 8-holes.", "T Plate. 10-holes."]
    const platesData_19_description_es = ["Placa T. 4-agujeros.", "Placa T. 8-agujeros.", "Placa T. 10-agujeros."]

    const platesData_19_detail_en = ["15 mm stem.", "15 mm stem.", "18 mm stem."]
    const platesData_19_detail_es = ["15 mm puente.", " 15 mm puente.", "18 mm puente."]

    const platesData_20_description_en = ["Y Plate. 5-holes.", "Y Plate. 8-holes."]
    const platesData_20_description_es = ["Placa Y. 5-agujeros.", "Placa Y. 8-agujeros."]

    const platesData_21_description_en = ["Double Y Plate. 8-holes"]
    const platesData_21_description_es = ["Doble Placa Y. 8-agujeros"]

    const platesData_21_detail_en = ['10 mm stem.']
    const platesData_21_detail_es = ['10 mm puente.']

    const platesData_22_description_en = ["X Plate. 4-holes."]
    const platesData_22_description_es = ["Placa X. 4-agujeros."]

    const platesData_23_description_en = ["Orbital Plate. 6-holes.", "Orbital Plate. 8-holes.", "Orbital Plate. 10-holes.", "Orbital Plate. 12-holes."]
    const platesData_23_description_es = ["Placa Orbital. 6-agujeros.", "Placa Orbital. 8-agujeros.", "Placa Orbital. 10-agujeros.", "Placa Orbital. 12-agujeros."]

    const platesData_1 = [
        ["MINP-1.0-2-S-MB", "MINP-1.0-4-S", "MINP-1.0-6-S", "MINP-1.0-8-S", "MINP-1.0-12-S", "MINP-1.0-16-S", "MINP-1.0-24-S", "MINP-1.0-40-S", "MINP-1.0-4-S-MB", "MINP-1.0-4-S-LB", "MINP-1.0-6-S-LB"],
        lng === "en" ? platesData_1_description_en : platesData_1_description_es,
        lng === "en" ? platesData_1_detail_en : platesData_1_detail_es,
        [Image_1_1, Image_1_2, Image_1_3, Image_1_4, Image_1_5, Image_1_6, Image_1_7, Image_1_8, Image_1_9, Image_1_10, Image_1_11],
    ]
    const platesData_2 = [
        ["MINP-1.0-4-L-L", "MINP-1.0-4-L-R", "MINP-1.0-4-L-L-SB", "MINP-1.0-4-L-R-SB", "MINP-1.0-4-L-L-MB", "MINP-1.0-4-L-R-MB", "MINP-1.0-4-L-L-LB", "MINP-1.0-4-L-R-LB", "MINP-1.0-5-L-L-LB", "MINP-1.0-5-L-R-LB", "MINP-1.0-6-L-L-MB", "MINP-1.0-6-L-R-MB", "MINP-1.0-6-L-L-LB", "MINP-1.0-6-L-R-LB"],
        lng === "en" ? platesData_2_description_en : platesData_2_description_es,
        lng === "en" ? platesData_2_detail_en : platesData_2_detail_es,
        [Image_1_12, Image_1_13, Image_1_14, Image_1_15, Image_1_16, Image_1_17, Image_1_18, Image_1_19, Image_1_20, Image_1_21, Image_1_22, Image_1_23, Image_1_24, Image_1_25],
    ]
    const platesData_3 = [
        ["MINP-1.0-4-L-L-SB-112", "MINP-1.0-4-L-R-SB-112", "MINP-1.0-4-L-L-MB-112", "MINP-1.0-4-L-R-MB-112", "MINP-1.0-5-L-L-LB-112", "MINP-1.0-5-L-R-LB-112"],
        lng === "en" ? platesData_3_description_en : platesData_3_description_es,
        lng === "en" ? platesData_3_detail_en : platesData_3_detail_es,
        [Image_1_26, Image_1_27, Image_1_28, Image_1_29, Image_1_30, Image_1_31],
    ]
    const platesData_4 = [
        ["MINP-1.0-7-L-L-LB-100", "MINP-1.0-7-L-R-LB-100"],
        lng === "en" ? platesData_4_description_en : platesData_4_description_es,
        lng === "en" ? platesData_4_detail_en : platesData_4_detail_es,
        [Image_1_32, Image_1_33],
    ]
    const platesData_5 = [
        ["MINP-1.0-6-T", "MINP-1.0-5-T-LB", "MINP-1.0-4-22-T", "MINP-1.0-4-22-T-LB", "MINP-1.0-6-24-T", "MINP-1.0-6-24-T-MB", "MINP-1.0-6-24-T-LB"],
        lng === "en" ? platesData_5_description_en : platesData_5_description_es,
        lng === "en" ? platesData_5_detail_en : platesData_5_detail_es,
        [Image_1_34, Image_1_35, Image_1_36, Image_1_37, Image_1_38, Image_1_39, Image_1_40],
    ]
    const platesData_6 = [
        ["MINP-1.0-5-T-L-LB", "MINP-1.0-5-T-R-LB"],
        lng === "en" ? platesData_6_description_en : platesData_6_description_es,
        lng === "en" ? platesData_6_detail_en : platesData_6_detail_es,
        [Image_1_41, Image_1_42],
    ]
    const platesData_7 = [
        ["MINP-1.0-6-TT", "MINP-1.0-4-TT"],
        lng === "en" ? platesData_7_description_en : platesData_7_description_es,
        lng === "en" ? platesData_7_detail_en : platesData_7_detail_es,
        [Image_1_43, Image_1_44],
    ]
    const platesData_8 = [
        ["MINP-1.0-5-Y", "MINP-1.0-4-Y-LB", "MINP-1.0-5-Y-LB", "MINP-1.0-5-Y-SB"],
        lng === "en" ? platesData_8_description_en : platesData_8_description_es,
        lng === "en" ? platesData_8_detail_en : platesData_8_detail_es,
        [Image_1_45, Image_1_46, Image_1_47, Image_1_48],
    ]
    const platesData_9 = [
        ["MINP-1.0-6-YY", "MINP-1.0-7-YY", "MINP-1.0-6-YY-LB", "MINP-1.0-6-YY-EELB"],
        lng === "en" ? platesData_9_description_en : platesData_9_description_es,
        lng === "en" ? platesData_9_detail_en : platesData_9_detail_es,
        [Image_1_49, Image_1_50, Image_1_51, Image_1_52],
    ]
    const platesData_10 = [
        ["MINP-1.0-6-O", "MINP-1.0-8-O", "MINP-1.0-10-O", "MINP-1.0-12-O"],
        lng === "en" ? platesData_10_description_en : platesData_10_description_es,
        ["", "", "", ""],
        [Image_1_53, Image_1_54, Image_1_55, Image_1_56],
    ]
    const platesData_11 = [
        ["MINP-1.0-4-X"],
        lng === "en" ? platesData_11_description_en : platesData_11_description_es,
        [""],
        [Image_1_57],
    ]
    const platesData_12 = [
        ["MINP-1.0-4-Z-L", "MINP-1.0-4-Z-R", "MINP-1.0-4-Z-L-ELB", "MINP-1.0-4-Z-R-ELB"],
        lng === "en" ? platesData_12_description_en : platesData_12_description_es,
        lng === "en" ? platesData_12_detail_en : platesData_12_detail_es,
        [Image_1_58, Image_1_59, Image_1_60, Image_1_61],
    ]
    const platesData_13 = [
        ["MINP-1.0-4-Q", "MINP-1.0-6-Q", "MINP-1.0-8-Q", "MINP-1.0-16-Q", "MINP-1.0-24-Q"],
        lng === "en" ? platesData_13_description_en : platesData_13_description_es,
        lng === "en" ? platesData_13_detail_en : platesData_13_detail_es,
        [Image_1_62, Image_1_63, Image_1_64, Image_1_65, Image_1_66],
    ]
    const platesData_14 = [
        ["MINP-1.0-4-RC", "MINP-1.0-6-RC", "MINP-1.0-8-RC", "MINP-1.0-16-RC", "MINP-1.0-24-RC"],
        lng === "en" ? platesData_14_description_en : platesData_14_description_es,
        lng === "en" ? platesData_14_detail_en : platesData_14_detail_es,
        [Image_1_67, Image_1_68, Image_1_69, Image_1_70, Image_1_71],
    ]
    const platesData_15 = [
        ["MINP-1.0-4-2L-LCH", "MINP-1.0-4-4L-LCH", "MINP-1.0-4-6L-LCH", "MINP-1.0-4-8L-LCH", "MINP-1.0-4-10L-LCH", "MINP-1.0-4-12L-LCH", "MINP-1.0-4-14L-LCH"],
        lng === "en" ? platesData_15_description_en : platesData_15_description_es,
        lng === "en" ? platesData_15_detail_en : platesData_15_detail_es,
        [Image_1_72, Image_1_72, Image_1_72, Image_1_72, Image_1_72, Image_1_72, Image_1_72],
    ]
    const platesData_16 = [
        ["MINP-1.0-0-Lfort-L", "MINP-1.0-2-Lfort-L", "MINP-1.0-3-Lfort-L", "MINP-1.0-4-Lfort-L", "MINP-1.0-5-Lfort-L", "MINP-1.0-6-Lfort-L", "MINP-1.0-7-Lfort-L", "MINP-1.0-8-Lfort-L", "MINP-1.0-9-Lfort-L", "MINP-1.0-10-Lfort-L", "MINP-1.0-11-Lfort-L", "MINP-1.0-12-Lfort-L", "MINP-1.0-0-Lfort-R", "MINP-1.0-2-Lfort-R", "MINP-1.0-3-Lfort-R", "MINP-1.0-4-Lfort-R", "MINP-1.0-5-Lfort-R", "MINP-1.0-6-Lfort-R", "MINP-1.0-7-Lfort-R", "MINP-1.0-8-Lfort-R", "MINP-1.0-9-Lfort-R", "MINP-1.0-10-Lfort-R", "MINP-1.0-11-Lfort-R", "MINP-1.0-12-Lfort-R"],
        lng === "en" ? platesData_16_description_en : platesData_16_description_es,
        lng === "en" ? platesData_16_detail_en : platesData_16_detail_es,
        [Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_73, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74, Image_1_74]
    ]
    const platesData_17 = [
        ["MMPP-0.75-2-S-MB", "MMPP-0.75-2-S-LB", "MMPP-0.75-4-S-MB", "MMPP-0.75-4-S-LB", "MMPP-0.75-40-S"],
        lng === "en" ? platesData_17_description_en : platesData_17_description_es,
        lng === "en" ? platesData_17_detail_en : platesData_17_detail_es,
        [Image_1_75, Image_1_76, Image_1_77, Image_1_78, Image_1_79],
    ]
    const platesData_18 = [
        ["MMPP-0.75-5-L-L-LB-100", "MMPP-0.75-5-L-R-LB-100", "MMPP-0.75-7-L-L-LB-100", "MMPP-0.75-7-L-R-LB-100", "MMPP-0.75-9-L-L-LB-100", "MMPP-0.75-9-L-R-LB-100"],
        lng === "en" ? platesData_18_description_en : platesData_18_description_es,
        lng === "en" ? platesData_18_detail_en : platesData_18_detail_es,
        [Image_1_80, Image_1_81, Image_1_82, Image_1_83, Image_1_84, Image_1_85],
    ]
    const platesData_19 = [
        ["MMPP-0.75-4-22-T-ELB", "MMPP-0.75-8-53-T-ELB", "MMPP-0.75-10-73-T-ELB"],
        lng === "en" ? platesData_19_description_en : platesData_19_description_es,
        lng === "en" ? platesData_19_detail_en : platesData_19_detail_es,
        [Image_1_86, Image_1_87, Image_1_88]
    ]
    const platesData_20 = [
        ["MMPP-0.75-5-23-Y", "MMPP-0.75-8-44-Y"],
        lng === "en" ? platesData_20_description_en : platesData_20_description_es,
        [""],
        [Image_1_89, Image_1_90]
    ]
    const platesData_21 = [
        ["MMPP-0.75-8-YY-MB "],
        lng === "en" ? platesData_21_description_en : platesData_21_description_es,
        lng === "en" ? platesData_21_detail_en : platesData_21_detail_es,
        [Image_1_91],
    ]
    const platesData_22 = [
        ["MMPP-0.75-4-X"],
        lng === "en" ? platesData_22_description_en : platesData_22_description_es,
        [""],
        [Image_1_92]
    ]
    const platesData_23 = [
        ["MMPP-0.75-6-O", "MMPP-0.75-8-O", "MMPP-0.75-10-O", "MMPP-0.75-12-O"],
        lng === "en" ? platesData_23_description_en : platesData_23_description_es,
        [""],
        [Image_1_93, Image_1_94, Image_1_95, Image_1_96]
    ]
    let data = {
        platesData_1,
        platesData_2,
        platesData_3,
        platesData_4,
        platesData_5,
        platesData_6,
        platesData_7,
        platesData_8,
        platesData_9,
        platesData_10,
        platesData_11,
        platesData_12,
        platesData_13,
        platesData_14,
        platesData_15,
        platesData_16,
        platesData_17,
        platesData_18,
        platesData_19,
        platesData_20,
        platesData_21,
        platesData_22,
        platesData_23
    }
    return data;
}