import styled from 'styled-components'

const Styles = styled.div`
  width:100%;
  min-height:100%;
  background:transparent linear-gradient(to bottom, #6F3CEA 0%, #25144D 46%, #000000 100%) 0% 0% no-repeat padding-box;
  .title-container{
    padding:0px 38px;
    h1{
      margin:61px 0px 0px 0px;
      font-size:32px;
      color:white;
      font-weight:700;
      .blue-dot{
        margin-left:1px;
      }
    }
    p{
      height:143px;
      margin-top:15px;
      color:white;
      font-size:15px;
      text-align:left;
      line-height:19px;
      font-weight:300;
    }
  }
  .cards-container{
    padding:0px 24px;
    .card{
      background:rgba(0,0,0,0.5);
      margin-top:13px;
      padding-bottom:10px;
      img{
        background: rgba(0,0,0,0.5);
        border-radius: 37px;
      }
      .card-title{
        h1{
          font-size:16px;
          font-weight:700;
          color:white;
        }
        h3{
          font-size:14px;
          font-weight:300;
          color:white;
          text-transform:none;
        }
      }
      .border-title{
        border-bottom:1px solid #49D3EA;
      }
      .card-description{
        margin:0;
      }
    }
  }
  .maxilofacial-systems{
    margin-top:70px;
    .title-container{
      padding:0px 38px;
      h3{
        margin:0;
        color:#49D3EA;
        font-size:12px;
        font-weight:700;
      }
      h1{
        color:white;
        margin:15px 0px 10px 0px;
        font-size:30px;
        font-weight:700;
      }
      p{
        height:min-content;
        margin:0;
        color:white;
        font-size:15px;
        font-weight:400;
        line-height:19px;
      }
    }
  }
  .image-container-background{
    position:absolute;
    width:100%;
    overflow-x:hidden;
    img{
      width:100%;
      mix-blend-mode: soft-light;
      position:relative;
      right:-24%;
    }
  }
`

export default Styles