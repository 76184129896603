/*** Imports ***/
import React from 'react'
import ViewsContainer from './modules/commons/ViewsContainer'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { store, history } from './store/Store'
/*** Styles ***/
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'antd/dist/antd.css'
import GlobalStyle from './GlobalStyle'
import 'react-multi-carousel/lib/styles.css';

export default function App() {
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<ViewsContainer />
				<GlobalStyle />
			</ConnectedRouter>
		</Provider>
	)
}