/*----- Core -----*/
import React, { useState } from 'react'
import { Image } from "react-bootstrap";
import { Accordion, Card } from 'react-bootstrap'

/*----- Actions -----*/

/*----- Selectors -----*/

/*----- Components -----*/
import {MobileStyles, AccordionStyle} from '../Styles'
import AboutUsBackground from "../../../assets/images/img-marca-faico-edificio.png";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import Arrow from "../../../assets/icons/icon-components/Arrow.js"

/*----- Commons -----*/
import '../../../commons/i18n';
import { Trans } from 'react-i18next';
import RegisterMark from '../../../components/registerMark';

const AccordionCard = ({title, content}) => {
	const [open, setOpen] = useState(false)

	const CustomToggle = ({ children, eventKey, open, setOpen }) => {
		const decoratedOnClick = useAccordionToggle(eventKey, () => setOpen(!open));

		return (
			<div onClick={decoratedOnClick}>
				<Arrow rotateDeg={open ? "90" : "270"} color="#49D3EA" width="15px" heigth="15px" strokeWidth="40px"  />
			</div>
		)
	}

	return(
		<AccordionStyle id="accordion-style" open={open}>
			<Accordion >
				<Card className="card">
					<Card.Header>
						<div className="title">
							<h1>{title}</h1>
							<span className="dot"></span>
						</div>
						<div className="line"/>
						<Accordion.Collapse eventKey="0" className="accordion-collapse">
							<Card.Body>
								{content}
							</Card.Body>
						</Accordion.Collapse>
						<div className="container-toggle">
							<CustomToggle eventKey="0" open={open} setOpen={setOpen}/>
						</div>
					</Card.Header>
				</Card>
			</Accordion>
		</AccordionStyle>
	);
}

const About = () => {
	return (
		<MobileStyles id="about-us-container">
			<div className="info-content">
				<div className="container-image">
					<div className="gradient"/>
					<Image src={AboutUsBackground} />
				</div>
				<div className="title-content">
					<div className="title">
						<h1><Trans>about.self</Trans></h1>
						<span className="dot"></span>
					</div>
					<div className="history-content">
						<p><Trans>about.history_body</Trans></p>
					</div>
				</div>
				<div className="text-content">
					<p><RegisterMark>about.mid_body</RegisterMark></p>
					<p><Trans>about.mid_body_2</Trans></p>
				</div>
			</div>
			<div className="cards-content">
				<AccordionCard
					title={<Trans>about.mission</Trans>}
					content={ <p><Trans>about.mission_body</Trans></p> }
				/>
				<AccordionCard
					title={<Trans>about.vision</Trans>}
					content={ <p><Trans>about.vision_body</Trans></p> }
				/>
				<AccordionCard
					title={<Trans>about.values</Trans>}
					content={
						<div>
							<p><Trans>about.values_body</Trans></p>
							<p><Trans>about.values_body_extend</Trans></p>
						</div>
					}
				/>
			</div>
		</MobileStyles>
	);
}

export default About