export default () => {
  return `
    box-shadow: 0 10px 40px -20px rgba(0,0,0,0.4);
    transition: all 0.5s;
    animation-name: example;
    animation-duration: 0.6s;
    border-radius: 6px;
    cursor: pointer;
    @keyframes example {
        from {
          transform: scale(0.8);
          opacity: 0.5;
        }
        to {
          transform: scale(1);
          opacity: 1;
        }
      }
      &:hover {
        text-decoration: none;
        transform: scale(1.05);
        box-shadow: 0 20px 50px -14px rgba(0,0,0,0.4);
      }
  `
}
