import styled from "styled-components";

export const DesktopStyles = styled.div`
  padding:0;
  margin:0;
  position:relative;
  .content-image{
    position:absolute;
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    img{
      width:100%;
      position:relative;

    }
  }
  .content-page{
    padding:0px 420px;
    .content-title{
      margin-top:134px;
      margin-bottom:69px;
      .title{
        margin:0px;
        padding:0px;
        display:flex;
        align-items:flex-end;
        h1{
          margin:0px;
          padding:0px;
          text-align:left;
          font-size:60px;
          font-family: 'Montserrat', sans-serif;
          color:black;
          font-weight:700;
          height:min-content;
        }
        .dot{
          height: 10px;
          width: 10px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 15px;
          margin-left: 2px;
        }
      }
      p{
        font-size:26px;
        line-height:30px;
        margin:38px 0px 0px;
        padding:0px;
        font-family: 'Montserrat', sans-serif;
        text-align:left;
        color:#585858;
        font-weight:300;
      }
    }
  }
`;
export const MobileStyles = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  .content-text{
    padding: 62px 96px 0px 30px;
    margin-bottom: 40px;
    .content-title{
      .title{
        text-align:left;
        display:flex;
        align-items:flex-end;
        margin-bottom: 13px;
        h1{
          color:black;
          font-size:32px;
          font-weight:700;
          margin:0px;
        }
        .dot{
          height: 8px;
          width: 8px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 7px;
          margin-left: 3px;
        }
      }
      p{
        color:black;
        text-align:left;
        font-weight:300;
        font-size:15px;
        line-height:19px;
        margin-bottom:10px;
      }
    }
  }
  .content-news{
    padding: 20px 30px 50px 30px;
    a{
      height: 200px;
      margin-bottom:30px;
      .card-text{
        margin:16px;
      }
      .title{
        font-size:18px;
        line-height:20px;
        font-weight:700;
        margin-bottom: 7px;
      }
      .subtitle{
        font-size:14px;
        line-height:14px;
        font-weight:400;
        margin-bottom: 12px;
      }
    }

    .button-content{
      margin-top:20px;
    }
  }
`;