import styled from 'styled-components'

export const List = styled.div`
    &>div{
        margin-bottom:5%;
    }
    h1{
        text-transform:capitalize;
    }
    div:nth-child(2){
        img{
            height:47px !important;
        }
    }
    div:nth-child(4){
        img{
            height:42px !important;
        }
    }
`