import styled from "styled-components";

export const Styles = styled.div`
	position:relative;
  .container-page{
		background:#1C1C1C;
		padding:2%;
		z-index:100;
		.title{
			font-weight:700;
        margin:0;
        font-family: 'Montserrat', sans-serif;
        color:white;
        text-align:center;
				z-index:100;
		}
    .row-content{
		margin-bottom:35px;
      h1{
		font-size:32px;
        font-weight: 700;
        margin:0;
        font-family: 'Montserrat', sans-serif;
        color:white;
        border-bottom: 2px solid #545454;
				position:relative;
				z-index:100;
				margin-top:10px;
      }
      .content-cards{
        margin-top:3%;
				align-items:flex-end;
      }
			.content-title-OneToOne{
				border-bottom: 2px solid #545454;
				width:100%;
				display:flex;
				align-items:flex-end;
				img{
					width:5%;
					margin-right:5px;
					z-index:100;
				}
				h1{
					border:none
				}
			}
    }
	}
	.content-buttons{
		display:flex;
		width:100%;
		justify-content:center;
		padding-top:20px;
		z-index:15;
		button{
			background: transparent !important;
			width: 186px;
			height: 41px;
			z-index:15;
			color: white;
			border: 2.5px solid white;
			font-size:12px;
			font-weight:700;
			border-radius: 25px !important;
			font-family: 'Montserrat',sans-serif;
			padding:10px;
			margin:0 10%;
		}
		button:hover {
				background:white !important;
				border:1px solid black !important;
				color:black !important;
				transform: scale(1.03);
		}
		button:active{
				border: 1px solid white;
				box-shadow:none !important;
		}
		button:focus{
				border: 1px solid white;
				box-shadow:none !important;
		}
	}
	.content-images-wave{
    position:absolute;
    width:100%;
    .image-wave-reverse{
      position:relative;
      z-index: 1;
      left: -13%;
      transform: scaleX(-1);
    }
    .image-wave{
      position:relative;
      z-index: 1;
      z-index: 10;
      right: -10%;
    }
  }
	.zIndex100{
		z-index:100 !important;
		margin-top: 1.2%;
	}
`

export const ProductCardStyles = styled.div`
	z-index:100;
  .card-content{
    background:linear-gradient(45deg, rgba(0,0,0,0.69) , rgba(74,73,73,0) 50%);
    border-radius:5px;
    padding:10px;
    padding-bottom:15px;
		z-index:100;
		.content-image{
			margin-right:auto;
			margin-left:auto;
			margin-top:5%;
			margin-bottom:5%;
			z-index:100;
		}
		.content-image-type-4{
			margin-right:auto;
			margin-left:auto;
			margin-bottom:5%;
			margin-top:5%;
			padding:0 3% 0 0;
			width:fit-content;
			z-index:100;
		}
  }
  .card-content-doble{
		margin:10px;
		padding-bottom:0px;
		width:50%;
		z-index:100;
	}
	.cards-content-doble{
		background:linear-gradient(45deg, rgba(0,0,0,0.69) , rgba(74,73,73,0) 50%);
		border-radius:5px;
		display:flex;
		flex-direction:column;
		padding:12px;
		z-index:100;
		.content-image{
			margin-right:auto;
			margin-left:auto;
			margin-top:5%;
			margin-bottom:5%;
			z-index:100;
		}
	}
  .card-content-info{
		display:flex;
		justify-content:space-between;
		z-index:100;
	}
	.card-image{
		position:relative;
		display:flex;
		flex-direction:row;
		margin:auto;
	}
	.card-title{
		color:white;
		font-family: 'Montserrat', sans-serif;
		font-size:0.7vw;
		font-weight: 700;
		z-index:100;
	}
	.card-subtitle{
		color:white;
		font-family: 'Montserrat', sans-serif;
		font-size:0.7vw;
		font-weight:700;
		margin-bottom:10px;
		z-index:100;
	}
	.card-text{
		display:flex;
		justify-content:space-between;
		font-size:0.7vw;
		border-bottom:1px solid #D6D6D6;
		z-index:100;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			/* margin-bottom: 0; */
			letter-spacing:0;
			z-index:100;
		}
	}
	.card-text-empty{
		display:flex;
		justify-content:space-between;
		font-size:0.7vw;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			/* margin-bottom: 0; */
			letter-spacing:0;
			z-index:100;
		}
	}
	.card-text-detail{
		display:flex;
		justify-content:space-between;
		font-size:0.7vw;
		z-index:100;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			/* margin-bottom:0; */
			letter-spacing:0;
			z-index:100;
		}
	}
	.table-content{
		margin:0;
		padding:0;
		font-size:0.7vw;
		color:#D6D6D6;
		z-index:100;
		width: auto;
		flex: 1;
		td{
			margin:0;
			padding:0;
			z-index:100;
		}
		.last{
			text-align:right
		}
		thead{
			border:none;
			tr{
				border:none;
				z-index:100;
			}
			td{
				border:none;
				text-align:right;
				z-index:100;
			}
		}
		.td-header{
			text-align:left;
			font-weight:700;
		}
	}
	.information-text{
		color:#D6D6D6;
		text-align: right;
    margin: 10px 0px 0px 0px;
    font-size: 14px;
		z-index:100;
	}
	.subtitle_type1{
		color:#D6D6D6;
		text-align: left;
		margin: 10px 0px 0px 0px;
		font-size: 13px;
		z-index:100;
	}

	.product-detail{
		display:inline;
		margin:0;
		color:#D6D6D6;
		/* margin-bottom:0; */
		letter-spacing:0;
		font-size:13px;
		z-index:100;
	}
	img{
		z-index:100;
	}
	h1{
		z-index:100;
	}
	h2{
		z-index:100;
	}
	h3{
		z-index:100;
	}
`