import styled from 'styled-components'

export const ContactInformationStyles = styled.div`
  padding: 0px 20px;
  padding-right:136px;
  @media screen and (max-width:1440px){
    padding-right:0px;
  }
  width:80%;
  .title{
    display:flex;
    justify-content: space-between;
    align-items:center;
    h1{
      font-family: 'Montserrat', sans-serif;
      font-size:18px;
      font-weight:700;
      margin:0px;
      color:white;
    }
    img{
      width: 43px;
      height: 43px;
      margin-left: 6px;
    }
    img:nth-child(2){
      width: 40px;
      height: 43px;
      margin-left: 6px;

    }
  }
	.contact-about{
		padding-top:5%;
    flex-direction: column;
    height:90%;
			.item{
				display:flex;
        align-content:center;
        justify-content: flex-start;
        padding:10px 0px;
        padding-left:1px;
			}
			p{
				display:inline;
        margin:0;
				margin-left:20px;
        font-family: 'Montserrat', sans-serif;
        color:white;
        font-size:16px;
        font-weight:500;
			}
			.icon{
				width:30px;
				height:30px;
        margin-right: 5px;
        color:white;
			}
      .icon-phone{
				width:35px;
				height:35px;
			}
      .item-location{
        .icon{
          width:30px;
        }
        .address-container{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          p {
          }
        }
      }
	}
	.social-network{
		display:flex;
		.icon{
			margin-left:10px;
		}
	}
`

export const ContactFormStyles = styled.div`
    padding: 10px;
    height: 100%;
    width:80%;
    border-radius: 10px;
  h1{
    font-family: 'Montserrat', sans-serif;
    font-size:36px;
    font-weight:700;
    color:#102C65;
    line-height:43px;
    padding-right:18px;
    @media screen and (max-width:1440px){
      font-size:29px;
    }
  }
  .contact-form{
    margin-top:40px;
    margin-bottom:30px;
    padding-right:120px;
    @media screen and (max-width:1440px){
      padding:0px;
    }
  }
  .contact-form-button{
    padding: 10px 43px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(255,255,255);
    font-family: Montserrat,sans-serif;
    border-image: initial;
    border-radius: 25px;
    background: transparent;
    font-weight: 700;
    margin: 0px;
    width:max-content;
    font-size:13px;
  }
  .contact-form-button:hover{
    border-color: rgb(255,255,255);
    background: transparent;
  }
  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #102C65;
    background-color: #FFFFFF;
    opacity:0.6;
    border: 2px solid #102C65;
    font-weight:700;
  }
  .zoomButton {
    transition: transform .2s; /* Animation */
  }
  .zoomButton:hover {
    transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .form-group input, .form-group textarea{
    opacity:0.6;
    padding: 23px;
    border-radius:13px;
    font-size:13px;
    font-family:'Montserrat', sans-serif;
    font-weight:300;
    ::placeholder {
      color: #102C65;
    }
  }
  .form-control:focus {
    color: #102C65;
    background-color: #fff;
    border: 2px solid #102C65;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    font-weight:600;
  }
  .container-button{
    margin-top:30px;
  }
  .form-message{
    padding: 10px 20px;
    background: #dc3545;
    font-family: 'Montserrat',sans-serif;
    margin-top: 17px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 20px;
    border-radius: 17px;
  }
  .form-success{
    background: #28a745;
  }
  .message-hidden{
    background:transparent;
  }
`