/*----- Core -----*/
import React from 'react'
import { Row, Col } from 'react-bootstrap';
import ContactFormDesktop from './components/ContactForm';
import ContactInformationDesktop from './components/ContactInformation'
import { Styles } from './Styles'


const Contact = () => {
	return (
		<Styles>
			<div className="gradient"></div>
			<Row className="first-row">
				<Col md={5} className="col-form" >
					<ContactFormDesktop/>
				</Col>
			</Row>
			<Row className="second-row">
				<Col md={5} className="col-information">
					<ContactInformationDesktop/>
				</Col>
			</Row>
		</Styles>
	);
}

export default Contact