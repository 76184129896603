import React,{useState} from 'react'
import LevelOne from "../maxillofacial/desktop/LevelOne";
import {dataGenerator} from "./data.js";
import i18n from "i18next";
import EmptyImage from "../../assets/images/empty_resources.png"
import Image_1_0 from "../../assets/images/CranioMaxiloFacial/01a_IMF-1.6-C-8 1.svg"
import Image_1_1 from "../../assets/images/CranioMaxiloFacial/01b_IMF-1.6-C-8 1.png"

const IMFSystem = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<LevelOne
			title={"maxillofacial.imfSystem.self"}
			subtitle={"maxillofacial.imfSystem.subtitle"}
			productImages={[EmptyImage,EmptyImage,EmptyImage,EmptyImage]}
			hasTable={true}
			dataScrew = {data.dataScrew}
			dataSet = {data.dataSet}
			imageScrew = {[Image_1_0,Image_1_1]}
			currentPage = {6}
		/>
	)
}

export default (IMFSystem)