import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_1.png"
import Image_1_2 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_2.png"
import Image_1_3 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_3.png"
import Image_1_4 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_4.png"
import Image_1_5 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_5.png"
import Image_1_6 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_6.png"
import Image_1_7 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_7.png"
import Image_1_8 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_8.png"
import Image_1_9 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_9.png"
import Image_1_10 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_10.png"
import Image_1_11 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_11.png"
import Image_1_12 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Plates/image_12.png"

export default function dataGenerator(lng) {

  const platesData_1_description_en = ["Straight Plate. 4-holes.", "Straight Plate. 6-holes.", "Straight Plate. 14-holes.", "Straight Plate. 4-holes.", "Straight Plate. 6-holes.", "Straight Plate. 4-holes.", "Straight Plate. 8-holes.", "Straight Plate. 6-holes."]
  const platesData_1_description_es = ["Placa Recta. 4-agujeros.", "Placa Recta. 6-agujeros.", "Placa Recta. 14-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 6-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 8-agujeros.", "Placa Recta. 6-agujeros."]

  const platesData_1_detail_en = ["7.68 mm stem.", "7.68 mm stem.", "", "10.68 mm stem.", "10.68 mm stem.", "14.68 mm stem.", "10.68 mm stem.", "14.68 mm stem."]
  const platesData_1_detail_es = ["7.68 mm puente.", "7.68 mm puente.", "", "10.68 mm puente.", "10.68 mm puente.", "14.68 mm puente.", "10.68 mm puente.", "14.68 mm puente."]

  const platesData_2_description_en = ["Straight Plate. Alveolar. 4-holes.", "Straight Plate. Alveolar. 4-holes."]
  const platesData_2_description_es = ["Placa Recta. Alveolar. 4-agujeros.", "Placa Recta. Alveolar. 4-agujeros."]

  const platesData_2_detail_en = ["7.68 mm stem.", "10.68 mm stem."]
  const platesData_2_detail_es = ["7.68 mm puente.", "10.68 mm puente."]

  const platesData_3_description_en = ["Orbital Plate. 4-holes."]
  const platesData_3_description_es = ["Placa Orbital. 4-agujeros."]

  const platesData_3_detail_en = ["10.98 mm stem."]
  const platesData_3_detail_es = ["10.98 mm puente."]

  const platesData_4_description_en = ["Angulated Plate. 6-holes."]
  const platesData_4_description_es = ["Placa Angulada. 6-agujeros."]

  const platesData_4_detail_en = ["9.28 mm stem."]
  const platesData_4_detail_es = ["9.28 mm puente."]


  const platesData_1 = [
    ["FRMP-1.5-4-S", "FRMP-1.5-6-S", "FRMP-1.5-14-S", "FRMP-1.5-4-S-B", "FRMP-1.5-6-S-B", "FRMP-1.5-4-S-MB", "FRMP-1.5-8-S-B", "FRMP-1.5-6-S-MB"],
    lng === "en" ? platesData_1_description_en : platesData_1_description_es,
    lng === "en" ? platesData_1_detail_en : platesData_1_detail_es,
    [Image_1_1, Image_1_2, Image_1_3, Image_1_4, Image_1_5, Image_1_6, Image_1_7, Image_1_8],
  ]
  const platesData_2 = [
    ["FRMP-1.5-4-A-SS", "FRMP-1.5-4-A-MS"],
    lng === "en" ? platesData_2_description_en : platesData_2_description_es,
    lng === "en" ? platesData_2_detail_en : platesData_2_detail_es,
    [Image_1_9, Image_1_10]
  ]
  const platesData_3 = [
    ["FRMP-1.5-4-SC"],
    lng === "en" ? platesData_3_description_en : platesData_3_description_es,
    lng === "en" ? platesData_3_detail_en : platesData_3_detail_es,
    [Image_1_11],
  ]
  const platesData_4 = [
    ["FRMP-1.5-6-Ang"],
    lng === "en" ? platesData_4_description_en : platesData_4_description_es,
    lng === "en" ? platesData_4_detail_en : platesData_4_detail_es,
    [Image_1_12],
  ]

  let data = {
    platesData_1,
    platesData_2,
    platesData_3,
    platesData_4,
  }
  return data;
}