/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'


/*----- Commons -----*/
import Montserrat from '../../commons/mixins/fonts/Montserrat'
import Device from '../../commons/Device'
import Title from "../text/Title";
import Subtitle from "../text/Subtitle";


/*----- Styles -----*/
const StyledLandscapeCard = styled.article`
	max-width: 1600px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
	padding: 20px;
  h2{
		justify-content: flex-start;
		align-items: center;
		${ Montserrat(40, 600, 'black')};
		position: relative;
		z-index: 1;
		margin: 0;
	}
  	@media ${Device.sm} {
		padding: 80px;
	}
	@media ${Device.md} {
		height: 400px;
	}
	h3{
		text-align:justify;
	}
`

/*----- Component -----*/
const LandscapeCard = ({ title, body, imageUrl }) => {
	return (
		<StyledLandscapeCard className='landscape-card my-4 mx-auto' style={{ backgroundImage: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${imageUrl})` }}>
			<div className='col-12 mb-2'>
				<Title content={title} size={2}></Title>
			</div>
			<div className='col-xl-5 col-lg-7 col-md-9 col-sm-9 col-xs-9'>
				<Subtitle content={body} size={3}></Subtitle>
			</div>
		</StyledLandscapeCard>
	)
}
export default LandscapeCard;

/*----- PropTypes -----*/
LandscapeCard.propTypes = {
	body: PropTypes.object,
	title: PropTypes.object,
	imageUrl: PropTypes.string
}