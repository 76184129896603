
import React from "react";
import {BrowserView,MobileView} from "react-device-detect";

import ViewsContainerDesktop from './ViewsContainerDesktop';
import ViewsContainerMobile from './ViewsContainerMobile';

const ViewsContainer = () => {
	return (
		<>
			<BrowserView>
				<ViewsContainerDesktop />
			</BrowserView>
			<MobileView>
				<ViewsContainerMobile />
			</MobileView>
		</>
	)

}
export default ViewsContainer;