/*----- Core -----*/
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Image, Row, Container, Col, Table, Button } from "react-bootstrap";
import { navigate } from 'hookrouter';
import Subtitle from "../../../../components/text/Subtitle";
import { Styles, ProductCardStyles } from "./Styles";
import i18n from "i18next";
import dataGenerator from "./data.js";

/*Prótestica General */
import ImageCards1_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-1.png";
import ImageCards1_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-2.png";
import ImageCards1_3 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-3.png";
import ImageCards1_4 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-4.png";
import ImageCards1_5 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-5.png";
import ImageCards1_6 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-6.png";
import ImageCards1_7 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-7.png";
import ImageCards1_8 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-8.png";
import ImageCards1_9 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-9.png";
import ImageCards1_10 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-10.png";
import ImageCards1_11 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-11.png";
import ImageCards1_12 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_1/image-card-12.png";
/*Second Row */
import ImageCards2_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_2/image-card-1.png";
import ImageCards2_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_2/image-card-2.png";

/*3POD*/
import ImageCards3_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_3/image-card-1.png";
import ImageCards3_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_3/image-card-2.png";
import ImageCards3_3 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_3/T-base-image.png";
import ImageCards3_4 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_3/3pod-image.png";
import ImageCards3_5 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_3/image-card-3.png";

/*PRÓTESIS CEMENTADA*/
import ImageCards4_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-1.png";
import ImageCards4_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-2.png";
import ImageCards4_3 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-3.png";
import ImageCards4_4 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-4.png";
import ImageCards4_5 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-5.png";
import ImageCards4_6 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-6.png";
import ImageCards4_7 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-7.png";
import ImageCards4_8 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-8.png";
import ImageCards4_9 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_4/image-card-9.png";
/*PRÓTESIS Roscada*/
import ImageCards5_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_5/image-card-1.png";
import ImageCards5_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_5/image-card-2.png";
import ImageCards5_3 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_5/image-card-3.png";
import ImageCards5_4 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_5/image-card-4.png";
import ImageCards5_5 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_5/image-card-5.png";
import ImageCards5_6 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_5/image-card-6.png";
import ImageCards5_7 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_5/image-card-7.png";
/*PRÓTESIS CONOMÉTRICA*/
import ImageCards6_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_6/image-card-1.png";
import ImageCards6_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_6/image-card-2.png";
import ImageCards6_3 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_6/image-card-3.png";
import ImageCards6_4 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_6/image-card-4.png";
import ImageCards6_5 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_6/image-card-5.png";
import ImageCards6_6 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_6/image-card-6.png";
import ImageCards6_7 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_6/image-card-7.png";

/*PRÓTESIS OVERDENTURE*/
import ImageCards7_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_7/image-card-1.png";
import ImageCards7_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_7/image-card-2.png";
import ImageCards7_3 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_7/OverDLock_Internal_Snappler.png";
/*Piezas de Repuesto*/
import ImageCards8_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-1.png";
import ImageCards8_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-2.png";
import ImageCards8_3 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-3.png";
import ImageCards8_4 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-4.png";
import ImageCards8_5 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-5.png";
import ImageCards8_6 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-6.png";
import ImageCards8_7 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-7.png";
import ImageCards8_8 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-8.png";
import ImageCards8_9 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_8/image-card-9.png";

/*One to One*/
import ImageTitleOneToOne from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_9/logo-one-to-one.png"
import ImageCards9_1 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_9/image-card-1.png";
import ImageCards9_2 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_9/image-card-2.png";
import ImageCards9_3 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_9/image-card-3.png";
import ImageCards9_4 from "../../../../assets/images/Dental/internalHexagonConnection/prostheticComponents/cards_9/image-card-4.png";


import VioletWaveImg from "../../../../assets/images/onda-violeta-2.png";
import EmptyImage from "../../../../assets/images/empty_resources.png"

const tBaseImages = [ImageCards3_3, ImageCards3_4, ImageCards3_1, ImageCards3_2, ImageCards3_5];
const protesicaGeneralImages = [ImageCards1_1, ImageCards1_2, ImageCards1_3, ImageCards1_4, ImageCards1_5, ImageCards1_6, ImageCards1_7, ImageCards1_8, ImageCards1_9, ImageCards1_10, ImageCards1_11, ImageCards1_12]
const piezasDeRepuestoImages = [ImageCards8_1, ImageCards8_2, ImageCards8_3, ImageCards8_4, ImageCards8_5, ImageCards8_6, ImageCards8_7, ImageCards8_8, ImageCards8_9]
const protesisConometricaImages = [ImageCards6_1, ImageCards6_2, EmptyImage, ImageCards6_3, ImageCards6_4, ImageCards6_7, ImageCards6_5, ImageCards6_6]
const protesisRoscadaImages = [ImageCards5_1, ImageCards5_2, ImageCards5_3, ImageCards5_4, ImageCards5_5, ImageCards5_6, ImageCards5_7];
const protesisCementadaImages = [ImageCards4_1, ImageCards4_2, ImageCards4_3, ImageCards4_4, ImageCards4_5, ImageCards4_6, ImageCards4_7, ImageCards4_8, ImageCards4_9];
const oneToOneImages = [ImageCards9_1, ImageCards9_2, ImageCards9_3, ImageCards9_4, EmptyImage]

const widthImages_1 = ["45%", "45%", "45%", "43%", "55%", "55%", "45%", "68%", "57%", "60%", "68%", "85%"]
const widthImages_2 = ["21%", "88%", "50%"]	
const widthImages_3 = ["32%", "27%", "49%", "50%", "32%"]
const widthImages_4 = ["90%", "76%", "74%", "74%", "72%", "68%", "72%", "65%", "70%", "50%", "50%", "50%"]
const widthImages_5 = ["49%", "31%", "50%", "55%", "50%", "55%", "55%", "50%", "50%", "50%"]
const widthImages_6 = ["100%", "97%", "30%", "54%", "57%","89%" ,"80%", "40%"]
const widthImages_7 = ["58%", "34%", "31%"]
const widthImages_8 = ["30%", "29%", "50%", "48%", "29%", "65%", "52%", "90%", "51%"]
const widthImages_9 = ["14%", "7%", "33%", "13%", "20%"]

const ProstheticComponents = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function (lng) {
		setData(dataGenerator(lng))
	})

	function renderProtesicaGeneral(row) {
		let table = [];
		let i, finish
		if (row === 1) {
			i = 1;
			finish = 4;
			for (i; i <= finish; i++) {
				let cardData = data.cards_1[i - 1];
				let title = "IHCprostheticComponents.cards_1.card_" + i + ".title"
				table.push(
					<ProductCard
						cardData={cardData}
						image={protesicaGeneralImages[i - 1]}
						title={title}
						typeCard={1}
						widthImage={widthImages_1[i - 1]}
					></ProductCard>
				)
			}
		} else if (row === 2) {
			table.push(
				<ProductCard
					cardData={data.cards_1[4]}
					image={protesicaGeneralImages[4]}
					title={"IHCprostheticComponents.cards_1.card_5.title"}
					typeCard={1}
					widthImage={widthImages_1[4]}

				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_1[5]}
					carddata_2={data.cards_1[6]}
					image={protesicaGeneralImages[5]}
					image_2={protesicaGeneralImages[6]}
					widthImage={widthImages_1[5]}
					widthImage_2={widthImages_1[6]}
					typeCard={2}
					title={"IHCprostheticComponents.cards_1.card_6.title"}

				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_1[7]}
					carddata_2={data.cards_1[8]}
					image={protesicaGeneralImages[7]}
					image_2={protesicaGeneralImages[8]}
					widthImage={widthImages_1[7]}
					widthImage_2={widthImages_1[8]}
					typeCard={2}
					title={"IHCprostheticComponents.cards_1.card_7.title"}

				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_1[9]}
					image={protesicaGeneralImages[9]}
					title={"IHCprostheticComponents.cards_1.card_8.title"}
					typeCard={1}
					widthImage={widthImages_1[9]}
				></ProductCard>
			)
		} else if (row === 3) {
			for (let i = 10; i <= 11; i++) {
				let cardData = data.cards_1[i];
				let title = "IHCprostheticComponents.cards_1.card_" + (i - 1) + ".title"
				table.push(
					<ProductCard
						cardData={cardData}
						image={protesicaGeneralImages[i]}
						title={title}
						typeCard={1}
						widthImage={widthImages_1[i]}
					></ProductCard>
				)
			}
		}

		return table;
	}
	function renderTBase() {
		let table = [];
		table.push(
			<ProductCard
				cardData={data.cards_3[0]}
				image={tBaseImages[0]}
				typeCard={3}
				col={3}
				title={<Trans>IHCprostheticComponents.cards_3.card_1.title</Trans>}
				rowTwo={true}
				widthImage={widthImages_3[0]}
			></ProductCard>
		)
		table.push(
			<ProductCard
				cardData={data.cards_3[1]}
				image={tBaseImages[1]}
				typeCard={3}
				col={3}
				title={<Trans>IHCprostheticComponents.cards_3.card_2.title</Trans>}
				rowTwo={true}
				widthImage={widthImages_3[1]}
			></ProductCard>
		)
		table.push(
			<ProductCard
				cardData={data.cards_3[2]}
				image={tBaseImages[2]}
				title={<Trans>IHCprostheticComponents.cards_3.card_3.title</Trans>}
				typeCard={1}
				widthImage={widthImages_3[2]}
			/>
		)
		table.push(
			<ProductCard
				cardData={data.cards_3[3]}
				carddata_2={data.cards_3[4]}
				image={tBaseImages[3]}
				image_2={tBaseImages[4]}
				typeCard={2}
				widthImage={widthImages_3[3]}
				widthImage_2={widthImages_3[4]}
				title={<Trans>IHCprostheticComponents.cards_3.card_4.title</Trans>}

			/>
		)
		return table;
	}
	function renderRowTwo(row) {
		let table = []
		if (row === 1) {
			table.push(
				<ProductCard
					cardData={data.cards_2[0]}
					image={ImageCards2_2}
					typeCard={3}
					title={"IHCprostheticComponents.cards_2_1.title"}
					rowTwo={true}
					widthImage={widthImages_2[0]}
				></ProductCard>
			)
		} else {
			table.push(
				<Col md={6} className="pl-0">
					<ProductCard
						cardData={data.cards_2[1]}
						image={ImageCards2_1}
						title={"IHCprostheticComponents.cards_2_2.card_" + 1 + ".title"}
						typeCard={1}
						col={12}
						rowTwo={true}
						widthImage={widthImages_2[1]}
					></ProductCard>
				</Col>
			)
		}
		return table;
	}
	function renderProtesisCementada(row) {
		let table = [];
		let i, finish
		if (row === 1) {
			i = 1;
			finish = 6;
		} else {
			i = 7;
			finish = 9;
		}
		for (i; i <= finish; i++) {
			let cardData = data.cards_4[i - 1];
			let title = "IHCprostheticComponents.cards_4.card_" + i + ".title"
			table.push(
				<ProductCard
					cardData={cardData}
					image={protesisCementadaImages[i - 1]}
					title={title}
					typeCard={1}
					widthImage={widthImages_4[i - 1]}
				></ProductCard>
			)
		}
		return table;
	}
	function renderProtesisRoscada(row) {
		let table = [];
		let i, finish
		if (row === 1) {
			table.push(
				<ProductCard
					cardData={data.cards_5[0]}
					image={protesisRoscadaImages[0]}
					title={"IHCprostheticComponents.cards_5.card_1.title"}
					typeCard={1}
					widthImage={widthImages_5[0]}
				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_5[1]}
					image={protesisRoscadaImages[1]}
					typeCard={3}
					col={3}
					title={"IHCprostheticComponents.cards_5.card_2.title"}
					rowTwo={true}
					widthImage={widthImages_5[1]}
				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_5[2]}
					image={protesisRoscadaImages[2]}
					title={"IHCprostheticComponents.cards_5.card_3.title"}
					typeCard={1}
					widthImage={widthImages_5[2]}
				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_5[3]}
					image={protesisRoscadaImages[3]}
					title={"IHCprostheticComponents.cards_5.card_4.title"}
					typeCard={1}
					widthImage={widthImages_5[3]}
				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_5[4]}
					image={protesisRoscadaImages[4]}
					title={"IHCprostheticComponents.cards_5.card_5.title"}
					typeCard={1}
					widthImage={widthImages_5[4]}
				></ProductCard>
			)
		} else {
			i = 6;
			finish = 7;
			for (i; i <= finish; i++) {
				let cardData = data.cards_5[i - 1];
				let title = "IHCprostheticComponents.cards_5.card_" + i + ".title"
				table.push(
					<ProductCard
						cardData={cardData}
						image={protesisRoscadaImages[i - 1]}
						title={title}
						typeCard={1}
						widthImage={widthImages_5[i - 1]}
					></ProductCard>
				)
			}
		}

		return table;
	}
	function renderProtesisConometrica(row) {
		var table = [];
		if (row === 1) {
			let i = 1;
			for (i; i <= 2; i++) {
				let cardData = data.cards_6[i - 1];
				let title = "IHCprostheticComponents.cards_6.card_" + i + ".title"
				table.push(
					<ProductCard
						cardData={cardData}
						image={protesisConometricaImages[i - 1]}
						title={title}
						typeCard={1}
						widthImage={widthImages_6[i - 1]}
					></ProductCard>
				)
			}
			/* table.push(
				<ProductCard
					cardData={data.cards_6[2]}
					image={protesisConometricaImages[2]}
					typeCard={3}
					col={3}
					title={"IHCprostheticComponents.cards_6.card_3.title"}
					rowTwo={true}
					widthImage={widthImages_6[2]}
				></ProductCard>
			) */
			table.push(
				<ProductCard
					cardData={[data.cards_6[3], data.cards_6[4], data.cards_6[5]]}
					images={[protesisConometricaImages[3], protesisConometricaImages[4], protesisConometricaImages[5]]}
					widthImages={[widthImages_6[3], widthImages_6[4], widthImages_6[5]]}
					subtitles={["IHCprostheticComponents.cards_6.card_4.subtitle_1", "IHCprostheticComponents.cards_6.card_4.subtitle_2", "IHCprostheticComponents.cards_6.card_4.subtitle_3"]}
					title={"IHCprostheticComponents.cards_6.card_4.title"}
					/* tapones */
					cardInformation={"IHCprostheticComponents.cards_6.card_4.cardInformation"}
					typeCard={4}
					cantElements={3}
					col={4}
				></ProductCard>
			)
		} else {
			let i = 6;
			for (i; i <= 7; i++) {
				let cardData = data.cards_6[i];
				let title = "IHCprostheticComponents.cards_6.card_" + (i - 1) + ".title"
				table.push(
					<ProductCard
						cardData={cardData}
						image={protesisConometricaImages[i]}
						title={title}
						typeCard={1}
						widthImage={widthImages_6[i]}
					></ProductCard>
				)
			}
		}
		return table;
	}
	function renderProtesisOverdenture() {
		let table = [];
		table.push(
			<ProductCard
				cardData={data.cards_7[0]}
				image={ImageCards7_1}
				title={"IHCprostheticComponents.cards_7.card_1.title"}
				typeCard={1}
				widthImage={widthImages_7[0]}
			></ProductCard>
		)
		table.push(
			<ProductCard
				cardData={data.cards_7[1]}
				image={ImageCards7_2}
				typeCard={3}
				col={3}
				title={"IHCprostheticComponents.cards_7.card_2.title"}
				rowTwo={true}
				widthImage={widthImages_7[1]}
			></ProductCard>
		)
		table.push(
			<ProductCard
				cardData={data.cards_7[2]}
				image={ImageCards7_3}
				title={"IHCprostheticComponents.cards_7.card_3.title"}
				typeCard={1}
				widthImage={widthImages_7[2]}
			></ProductCard>
		)
		return table;
	}
	function renderOneToOne(firstRow) {
		let table = []
		if (firstRow) {
			table.push(
				<ProductCard
					cardData={data.cards_9[0]}
					title={"IHCprostheticComponents.cards_9.card_1.title"}
					subtitle={""}
					subtitles={["IHCprostheticComponents.cards_9.card_1.subtitle_1", "IHCprostheticComponents.cards_9.card_1.subtitle_2", "IHCprostheticComponents.cards_9.card_1.subtitle_3"]}
					image={oneToOneImages[0]}
					widthImage={widthImages_9[0]}
					typeCard={5}
					col={6}
					cantElements={3}
				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_9[1]}
					title={"IHCprostheticComponents.cards_9.card_2.title"}
					subtitle={""}
					subtitles={["IHCprostheticComponents.cards_9.card_2.subtitle_1", "IHCprostheticComponents.cards_9.card_2.subtitle_2", "IHCprostheticComponents.cards_9.card_2.subtitle_3"]}
					image={oneToOneImages[1]}
					widthImage={widthImages_9[1]}
					typeCard={5}
					col={6}
					cantElements={3}
				></ProductCard>
			)
		} else {
			table.push(
				<ProductCard
					cardData={data.cards_9[2]}
					image={oneToOneImages[2]}
					typeCard={1}
					title={"IHCprostheticComponents.cards_9.card_3.title"}
					widthImage={widthImages_9[2]}
				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_9[3]}
					title={"IHCprostheticComponents.cards_9.card_4.title"}
					subtitle={""}
					subtitles={["IHCprostheticComponents.cards_9.card_4.subtitle_1", "IHCprostheticComponents.cards_9.card_4.subtitle_2", "IHCprostheticComponents.cards_9.card_4.subtitle_3"]}
					image={oneToOneImages[3]}
					widthImage={widthImages_9[3]}
					typeCard={5}
					col={5}
					cantElements={3}
				></ProductCard>
			)
			/* table.push(
				<ProductCard
					cardData={data.cards_9[4]}
					title={"IHCprostheticComponents.cards_9.card_5.title"}
					subtitle={""}
					subtitles={["IHCprostheticComponents.cards_9.card_5.subtitle_1", "IHCprostheticComponents.cards_9.card_5.subtitle_2", "IHCprostheticComponents.cards_9.card_5.subtitle_3"]}
					image={oneToOneImages[4]}
					widthImage={widthImages_9[4]}
					typeCard={5}
					col={5}
					cantElements={3}
				></ProductCard>
			) */
		}
		return table;
	}
	function renderPiezasDeRepuesto(firstRow) {
		let table = [];
		if (firstRow) {
			let i = 1;
			for (i; i <= 5; i++) {
				let cardData = data.cards_8[i - 1];
				let title = "IHCprostheticComponents.cards_8.card_" + i + ".title"
				table.push(
					<ProductCard
						cardData={cardData}
						image={piezasDeRepuestoImages[i - 1]}
						title={title}
						typeCard={1}
						widthImage={widthImages_8[i - 1]}
					></ProductCard>
				)
			}
		} else {
			table.push(
				<ProductCard
					cardData={data.cards_8[5]}
					carddata_2={data.cards_8[6]}
					image={piezasDeRepuestoImages[5]}
					image_2={piezasDeRepuestoImages[6]}
					widthImage={widthImages_8[5]}
					widthImage_2={widthImages_8[6]}
					typeCard={2}
					title={"IHCprostheticComponents.cards_8.card_6.title"}

				></ProductCard>
			)
			table.push(
				<ProductCard
					cardData={data.cards_8[7]}
					carddata_2={data.cards_8[8]}
					image={piezasDeRepuestoImages[7]}
					image_2={piezasDeRepuestoImages[8]}
					widthImage={widthImages_8[7]}
					widthImage_2={widthImages_8[8]}
					typeCard={2}
					title={"IHCprostheticComponents.cards_8.card_7.title"}

				></ProductCard>
			)
		}
		return table;
	}
	return (
		<Styles>
			<div className="content-wave">
				<Image src={VioletWaveImg} className="onda-gris img-fluid first"></Image>
				<Image src={VioletWaveImg} className="onda-gris img-fluid second"></Image>
				<Image src={VioletWaveImg} className="onda-gris img-fluid third"></Image>
				<Image src={VioletWaveImg} className="onda-gris img-fluid four"></Image>
			</div>
			<h1 class="title"><Trans>IHCprostheticComponents.self</Trans></h1>
			<Container fluid={true} className="container-page">
				<h1>
					<Trans>IHCprostheticComponents.title_1</Trans>
				</h1>
				<Row className="first-row">{renderProtesicaGeneral(1)}</Row>
				<Row className="first-row">{renderProtesicaGeneral(2)}</Row>
				<Row className="first-row">{renderProtesicaGeneral(3)}</Row>
			</Container>
			<Container fluid={true} className="container-page">
				<Row>
					<Col xl={4} className="first-row">
						<h1>
							<Trans>IHCprostheticComponents.title_2_2</Trans>
						</h1>
						<Row className="mt-5">{renderRowTwo(2)}</Row>
					</Col>
					<Col xl={4} className="first-row">
						<h1>
							<Trans>IHCprostheticComponents.title_2_1</Trans>
						</h1>
						<Row className="mt-5">{renderRowTwo(1)}</Row>
					</Col>
				</Row>
			</Container>
			<Container fluid={true} className="container-page">
				<h1>
					<Trans>IHCprostheticComponents.title_3</Trans>
				</h1>
				<Row className="first-row">{renderTBase()}</Row>
			</Container>
			<Container fluid={true} className="container-page">
				<h1>
					<Trans>IHCprostheticComponents.title_4</Trans>
				</h1>
				<Row className="first-row">{renderProtesisCementada(1)}</Row>
				<Row className="first-row">{renderProtesisCementada(2)}</Row>
			</Container>
			<Container fluid={true} className="container-page">
				<h1>
					<Trans>IHCprostheticComponents.title_5</Trans>
				</h1>
				<Row className="first-row">{renderProtesisRoscada(1)}</Row>
				<Row className="first-row">{renderProtesisRoscada(2)}</Row>
			</Container>
			<Container fluid={true} className="container-page">
				<h1>
					<Trans>IHCprostheticComponents.title_6</Trans>
				</h1>
				<Row className="first-row">{renderProtesisConometrica(1)}</Row>
				<Row className="first-row">{renderProtesisConometrica(2)}</Row>
			</Container>
			<Container fluid={true} className="container-page">
				<div className="content-title-OneToOne">
					<Image src={ImageTitleOneToOne}></Image>
					<h1><Trans>IHCprostheticComponents.title_9</Trans></h1>
				</div>
				<Row className="first-row">{renderOneToOne(true)}</Row>
				<Row className="first-row">{renderOneToOne(false)}</Row>
			</Container>
			<Container fluid={true} className="container-page">
				<h1>
					<Trans>IHCprostheticComponents.title_7</Trans>
				</h1>
				<Row className="first-row">{renderProtesisOverdenture()}</Row>
			</Container>
			<Container fluid={true} className="container-page">
				<h1>
					<Trans>IHCprostheticComponents.title_8</Trans>
				</h1>
				<Row className="first-row">{renderPiezasDeRepuesto(true)}</Row>
				<Row className="first-row">{renderPiezasDeRepuesto(false)}</Row>
				<div className="mt-5">
					<Subtitle content={<Trans>IHCprostheticComponents.note</Trans>} color={"White"} />
				</div>
				<div className="content-buttons">
					<Button onClick={() => navigate('/dental/internalHexagonConnection/smartImplants')}><Trans>dental.conexionHexagono_modal.card_1.title</Trans></Button>
					<Button onClick={() => navigate('/dental/internalHexagonConnection/surgicalInstruments')}> <Trans>dental.conexionHexagono_modal.card_3.title</Trans></Button>
				</div>
			</Container>
		</Styles>
	);

}

const ProductCard = (props) => {
	function generateCodesThree(cardData) {
		let table = [];
		for (let i = 0; i < cardData.products_cod.length; i++) {
			if (cardData.products_cod[i] !== "") {
				table.push(
					<tr>
						<td>{cardData.products_cod[i]}</td>
						<td>{cardData.products_detail[i]}</td>
						<td className="last">{cardData.products_type[i]}</td>
					</tr>
				)
			}
		}
		return table;
	}
	function generateCodesSimple(cardData) {
		let table = [];
		for (let i = 0; i < cardData.products_cod.length; i++) {
			if (cardData.products_cod[i] !== "") {
				table.push(
					<div>
						<div className="card-text">
							<div><p>{cardData.products_cod[i]}</p></div>
							<div><p>{cardData.products_type[i]}</p></div>
						</div>
						{cardData.products_detail[i] !== "" ?
							<div className="card-text-detail">
								<p classs>{cardData.products_detail[i]}</p>
							</div>
							:
							null
						}
					</div>
				)
			} else {
				table.push(
					<div className="card-text-empty">
						<p>&nbsp;&nbsp;</p>
					</div>
				)
			}
		}
		return table;
	}
	function generateCodesSecond(products_cod, products_type, products_detail) {
		let table = [];
		for (let i = 0; i < products_cod.length; i++) {
			if (products_cod[i] !== "") {
				table.push(
					<>
						<tr>
							<td>{products_cod[i]}</td>
							{products_detail ? <td className="">{products_detail[i]}</td> : ""}
							{products_type !== "" ? <td className={products_detail ? "text-right" : ""}>{products_type[i]}</td> : null}
						</tr>
						{props.productDetail ? <p className="product-detail"><Trans>{props.productDetail[i]}</Trans></p> : null}
					</>
				)
			}
		}
		return table;
	}
	function redenderCardSimple() {
		return (
			<div className="card-content">
				<div style={{ width: props.widthImage }} className="content-image">
					<Image src={props.image} className="card-image img-fluid" ></Image>
				</div>
				<h2 className="card-title"><Trans>{props.title}</Trans></h2>
				{generateCodesSimple(props.cardData)}
				<p className="information-text"><Trans>{props.cardData.information_text}</Trans></p>
			</div>
		)
	}
	function renderCardDoble() {
		return (
			<div className="cards-content-doble">
				<h2 className="card-title"><Trans>{props.title}</Trans></h2>
				<div className="card-content-info">
					<div className="card-content-doble "  style={{width:"50%"}}>
						<div className="content-image">
							<Image style={{ width: props.widthImage }} className="card-image" src={props.image}></Image>
						</div>
						{generateCodesSimple(props.cardData)}
					</div>
					<div className="card-content-doble" style={{width:"50%"}}>
						<div className="content-image">
							<Image style={{ width: props.widthImage_2 }} src={props.image_2} className="card-image" ></Image>
						</div>
						{generateCodesSimple(props.carddata_2)}
					</div>
				</div>
				<p className="information-text"><Trans>{props.cardData.information_text}</Trans></p>
			</div>
		)
	}
	function renderCardThree() {
		return (
			<div className="cards-content-doble">
				<div className="card-content-doble">
					<div style={{ width: props.widthImage }} className="content-image">
						<Image src={props.image} className="card-image img-fluid" ></Image>
					</div>
					<h2 className="card-title"><Trans>{props.title}</Trans></h2>
					<Table className="table-content">
						<tbody>
							{generateCodesThree(props.cardData)}
						</tbody>
					</Table>
					<p className="information-text"><Trans>{props.cardData.information_text}</Trans></p>
				</div>
			</div>
		)
	}
	function renderCard_4() {
		let renderTable = [];
		let renderImages = []
		let i;
		for (i = 0; i < props.images.length; i++) {
			renderImages.push(
				<div className="content-image-type-4" >
					<Image src={props.images[i]} style={{ width: props.widthImages[i] }} className="card-image img-fluid" ></Image>
				</div>
			)
		}
		for (i = 0; i < props.images.length; i++) {
			renderTable.push(
				<div class="col-xl-4">
					<Table className="table-content ml-1">
						<thead className="thead-type-4">
							<tr>
								<td className="td-header"><Trans>{props.subtitles[i]}</Trans></td>
							</tr>
						</thead>
						<tbody>
							{generateCodesSecond(props.cardData[i].products_cod, props.cardData[i].products_type)}
						</tbody>
					</Table>
				</div>
			)
		}
		return (
			<div className="card-content">
				<h2 className="card-title mb-0"><Trans>{props.title}</Trans></h2>
				<h2 className="card-title"><Trans>{props.subtitle}</Trans></h2>
				<div className="d-flex">
					{renderImages}
				</div>
				<div className="d-flex">
					{renderTable}
				</div>
				{props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
			</div>
		)
	}
	function renderTable() {
		let render = []
		for (let i = 0; i < props.cantElements; i++) {
			render.push(
				<div class="col-xl-4 mr-2">
					<Table className="table-content">
						<thead>
							<h3 className="card-subtitle"><Trans>{props.subtitles ? props.subtitles[i] : null}</Trans></h3>
							<tr style={{ border: "none" }}>
								<td style={{ border: "none" }}></td>
								<td style={{ border: "none" }}></td>
								{props.cardData.products_type[0][0] !== "" ? <td style={{ border: "none" }}></td> : null}
							</tr>
						</thead>
						<tbody>
							{generateCodesSecond(props.cardData.products_cod[i], props.cardData.products_type[i], props.cardData.products_detail[i])}
						</tbody>
					</Table>
				</div>
			)
		}
		return render
	}
	function renderCard_5() {
		return (
			<div className="card-content">
				<h2 className="card-title"><Trans>{props.title}</Trans></h2>
				<div className="content-image" style={{ width: props.widthImage }}><Image src={props.image} className="card-image img-fluid" ></Image></div>
				<div className="d-flex">
					{renderTable()}
				</div>
				{props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
			</div>
		)
	}
	switch (props.typeCard) {
		case 5:
			return (
				<Col xl={props.col !== "" ? props.col : ""} >
					<ProductCardStyles>
						{renderCard_5()}
					</ProductCardStyles>
				</Col>
			)
		case 4:
			return (
				<Col xl={props.col !== "" ? props.col : ""} >
					<ProductCardStyles>
						{renderCard_4()}
					</ProductCardStyles>
				</Col>
			)
		case 3:
			return (
				<Col xl={props.col !== "" ? props.col : ""} >
					<ProductCardStyles>
						{renderCardThree()}
					</ProductCardStyles>
				</Col>
			)
		case 2:
			return (
				<Col xl={props.col ? props.col : "4"} >
					<ProductCardStyles
					>
						{renderCardDoble()}
					</ProductCardStyles>
				</Col>
			)
		default:
			return (
				<Col xl={props.col ? props.col : "2"} >
					<ProductCardStyles
					>
						{redenderCardSimple()}
					</ProductCardStyles>
				</Col>
			)
	}
}
export default (ProstheticComponents);
