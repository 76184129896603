/*----- Core -----*/
import React from "react";
import { connect } from "react-redux";

/*----- Assets -----*/
import Image from "../../assets/images/beach.jpg";

/*----- Actions -----*/

/*----- Selectors -----*/

/*----- Components -----*/
import LandscapeCard from "../../components/card/LandscapeCard";
import DescriptionCard from "../../components/card/DescriptionCard";


/*----- Commons -----*/
import "../../commons/i18n";
import { Trans } from "react-i18next";
import Styles from "../../commons/styles/ProductsMainPage.js";
import RegisterMark from "../../components/registerMark";

const Dental = () => {
	return (
		<Styles>
			<React.Fragment>
				<LandscapeCard
					title={<Trans>hexa.title</Trans>}
					body={<Trans>hexa.landscape_body</Trans>}
					imageUrl={Image}
				/>
				<div className="row padding-cards">
					<DescriptionCard
						title={<Trans>hexa.implantes</Trans>}
						body={<Trans>dental.body_card_one</Trans>}
						imageUrl={Image}
						button={true}
					/>
					<DescriptionCard
						title={<Trans>hexa.protesis_dentales</Trans>}
						body={<RegisterMark>dental.body_card_two</RegisterMark>}
						imageUrl={Image}
						button={true}
					/>
					<DescriptionCard
						title={<Trans>hexa.instrumental</Trans>}
						body={<RegisterMark>dental.body_card_three</RegisterMark>}
						imageUrl={Image}
						button={true}
					/>
				</div>
			</React.Fragment>
		</Styles>
	);
};

export default connect()(Dental);
