import { createStore         , applyMiddleware, combineReducers, compose } from "redux"                            ;
import { connectRouter       , routerMiddleware }                          from "connected-react-router"           ;
import   createSagaMiddleware                                              from 'redux-saga'                       ;
import { all                  }                                            from 'redux-saga/effects'
import { createBrowserHistory }                                            from "history"                          ;
import   home                                                              from "../modules/home/Reducer"          ;
import   news                                                              from "../modules/news/redux/NewReducers";
import   team                                                              from "../modules/team/Reducer"          ;
import   newSagas                                                          from "../modules/news/redux/NewSagas"   ;

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();


const rootReducer = combineReducers({
	home,
	team,
	news,
	router: connectRouter(history)
});

const middlewares = [routerMiddleware(history), sagaMiddleware];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

export const store = createStore(
	rootReducer,
	composeEnhancer(applyMiddleware(...middlewares))
)

export default function* rootSagas() {
	yield all([
		newSagas(),
	])
} 

sagaMiddleware.run(rootSagas)