import styled from 'styled-components'


export const Styles = styled.div`
	position:relative;
	background: #1C1C1C;
	.container-page{
		background:#1C1C1C;
		padding:2%;
	}
	.content-title-OneToOne{
		border-bottom: 2px solid #545454;
		width:100%;
		display:flex;
		align-items:flex-end;
		img{
			width:5%;
			margin-right:5px;
			z-index:100;
		}
		h1{
			border:none
		}
	}
	.title{
		font-weight:700;
		margin:0;
		font-family: 'Montserrat', sans-serif;
		color:white;
		text-align:center;
		z-index:100;
		border:0px;
    font-size: 2.5rem;
    padding-top: 2%;
	}
	.content-image-type-4{
		margin-right:auto;
		margin-left:auto;
		margin-bottom:5%;
		margin-top:5%;
		padding:0 0 0 3%;
		width:fit-content;
		z-index:100;
	}
	.td-header{
		text-align:left;
		font-weight:700;
	}
	.thead-type-4{
		td{
			border:none;
		}
	}
	.card-subtitle{
		color:white;
		font-family: 'Montserrat', sans-serif;
		font-size:0.7vw;
		font-weight:700;
		margin-bottom:10px;
		z-index:100;
	}
	.content-buttons{
			display:flex;
			width:100%;
			justify-content:center;
			padding-top:20px;
			z-index:10;
			button{
				background: transparent !important;
				width: 186px;
				height: 41px;
				z-index:15;
				color: white;
				border: 2.5px solid white;
				font-size:12px;
				font-weight:700;
				border-radius: 25px !important;
				font-family: 'Montserrat',sans-serif;
				padding:10px;
				margin:0 10%;
			}
			button:hover {
					background:white !important;
					border:1px solid black !important;
					color:black !important;
					transform: scale(1.03);
			}
			button:active{
					border: 1px solid white;
					box-shadow:none !important;
			}
			button:focus{
					border: 1px solid white;
					box-shadow:none !important;
			}
	}
	.content-wave{
		position:absolute;
		width:100%;
		height:100%;
		.first{
    	position: relative;
			transform: rotate(12deg);
		}
		.second{
    	position: relative;
			transform: rotate(192deg);
		}
		.third{
			position:relative;
			transform: rotate(221deg);
		}
		.four{
			position:relative;
			transform: rotate(197deg);
		}
	}
	.first-row{
		margin-top:3%;
		align-items:flex-end;
	}
	h1{
		font-size:32px;
		font-weight: 700;
		margin:0;
		font-family: 'Montserrat', sans-serif;
		color:white;
		z-index: 10;
    	position: relative;
		border-bottom:0.5px solid #EBEBEB;
	}
`
export const ProductCardStyles = styled.div`
	.card-content{
		background:linear-gradient(45deg, rgba(0,0,0,0.69) , rgba(74,73,73,0) 85%);
		border-radius:5px;
		padding:10px;
		padding-bottom:15px;
		.content-image{
			margin:auto;
			margin-top:5%;
			margin-bottom:5%;
		}
	}
	.card-content-doble{
		margin:10px;
		padding-bottom:0px;
		.content-image{
			margin:auto;
			margin-top:5%;
			margin-bottom:5%;
		}
	}
	.cards-content-doble{
		background:linear-gradient(45deg, rgba(0,0,0,0.69) , rgba(74,73,73,0) 85%);
		border-radius:5px;
		display:flex;
		flex-direction:column;
		padding:12px;
		.content-image{
			margin:auto;
			margin-top:5%;
			margin-bottom:5%;
		}
	}
	.rotate{
		transform:rotate(90deg)
	}
	.card-content-info{
		display:flex;
		justify-content:space-around;
	}
	.card-image{
		position:relative;
		display:flex;
		flex-direction:row;
		margin:auto;
	}
	.card-title{
		color:white;
		font-family: 'Montserrat', sans-serif;
		font-size:0.7vw;
		font-weight: 700;
	}
	.card-text{
		grid-template-columns: 2.5fr 1.2fr;
		display: grid;
		font-size:0.7vw;
		border-bottom:1px solid #D6D6D6;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			/* margin-bottom: 0; */
			letter-spacing:0;
		}
	}
	.card-text-empty{
		display:flex;
		justify-content:space-between;
		font-size:0.7vw;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			/* margin-bottom: 0; */
			letter-spacing:0;
		}
	}
	.card-text-detail{
		display:flex;
		justify-content:space-between;
		font-size:0.7vw;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			/* margin-bottom:0; */
			letter-spacing:0;
		}
	}
	.table-content{
		margin:0;
		padding:0;
		font-size:0.7vw;
		color:#D6D6D6;
		td{
			margin:0;
			padding:0;
		}
		.last{
			text-align:right
		}
	}
	.information-text{
		color:#D6D6D6;
		text-align: right;
		margin: 10px 0px 0px 0px;
		font-size: 14px;
		z-index:100;
	}
`