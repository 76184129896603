import styled from 'styled-components'

const Styles = styled.div`
  p{
    font-size:13px;
    font-weight:400;
    line-height:16px;
    text-align:left;
  }
  .item-list{
    display:flex;
    flex-direction:column;
    .item{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin:5px 0px;
      z-index:100;
      .dot{
        margin-top:5px;
        height: 6px;
        width: 6px;
        background-color:#7161FF;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }
      p{
        color:white;
        margin:0;
        font-size:13px;
        z-index:100;
        font-weight:400;
        line-height:16px;
        display:flex;
        flex:1;
      }
    }
  }
`

export default Styles