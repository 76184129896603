import React from 'react'
import { Col, Image, Row, Button } from "react-bootstrap"
import { Styles, ProductCardStyles } from "./Styles.js";
import { Trans } from "react-i18next";
import { navigate } from 'hookrouter';

import VioletWaveImg from "../../../../assets/images/onda-violeta-3.png";

const Instruments = (props) => {
	function renderCards(data) {
		let render = []
		for (let card = 0; card < data[0].length; card++) {
			data.length === 4 ?
				render.push(
					<Col md={2}>
						<ProductCard
							productCod={data[0][card]}
							productDescription={data[1][card]}
							productDescription2={data[2][card]}
							productImage={data[3][card]}
						/>
					</Col>
				) :
				render.push(
					<Col className="card-col" md={2}>
						<ProductCard
							productCod={data[0][card]}
							productDescription={data[1][card]}
							productImage={data[2][card]}
						/>
					</Col>
				)

		}
		return render;
	}

	function renderRow(row) {
		let render = [];
		render.push(
			<Row className="w-100" >
				{/* <h1><Trans>{props.titles[row]}</Trans></h1> */}
				<Row className="content-row-cards">
					{renderCards(props.data[row])}
				</Row>
			</Row>
		)
		return render;
	}
	function renderRows() {
		let render = [];
		for (let row = 0; row < props.titles.length; row++) {
			render.push(
				renderRow(row)
			)
		}
		return render;
	}
	return (
		<Styles>
			<div class="content-images-wave">
				<Image src={VioletWaveImg} className="image-wave img-fluid"></Image>
			</div>
			<div className="instruments-content">
				<h1 className="title"><Trans>{props.self}.products.3.name</Trans></h1>
				<h2 className="subtitle"><Trans>{props.self}.products.3.description</Trans></h2>
				{renderRows()}
				<div className="content-buttons pb-5">
					<Button onClick={() => navigate('/maxillofacial/' + props.navigate + '/screws')}><Trans>maxillofacial.button.screws</Trans></Button>
					<Button onClick={() => navigate('/maxillofacial/' + props.navigate + '/plates')}><Trans>maxillofacial.button.plates</Trans></Button>
					<Button onClick={() => navigate('/maxillofacial/' + props.navigate + '/sets')}><Trans>maxillofacial.button.sets</Trans></Button>
				</div>
			</div>
		</Styles>
	)

}

const ProductCard = (props) => {
	return (
		<ProductCardStyles>
			<div className="content-image">
				<Image src={props.productImage} className="img-fluid" ></Image>
			</div>
			<div className="content-text">
				<h1>{props.productCod}</h1>
				<div className="description">
					<p>{props.productDescription}</p>
					<p>{props.productDescription2}</p>
				</div>
			</div>
		</ProductCardStyles>
	)
}
export default (Instruments);
