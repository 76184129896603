/*----- Core -----*/
import React from 'react'
import { Row, Col } from 'react-bootstrap';
import ContactFormMobile from './components/ContactForm';
import ContactInformationMobile from './components/ContactInformation'
import StylesContact from './Styles'


const Contact = () => {
	return (
		<StylesContact>
			<div className="gradient"/>
			<Row className="first-row">
				<Col span={22} className="col-form" >
					<ContactFormMobile/>
				</Col>
			</Row>
			<Row className="second-row">
				<Col md={22} className="col-information">
					<ContactInformationMobile/>
				</Col>
			</Row>
		</StylesContact>
	);
}

export default Contact