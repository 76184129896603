import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Trans } from "react-i18next";
import { navigate } from 'hookrouter';
/*Styles*/
import { Styles, FaicoSystemCranioStyles, DotStyles } from "./Styles";

/*Assests*/
import VioletWaveImg from "../../../assets/images/CranioMaxiloFacial/onda-violeta.png";
import CARD_1 from "../../../assets/images/CranioMaxiloFacial/tornillos.svg";
import CARD_2 from "../../../assets/images/CranioMaxiloFacial/placas.svg";
import CARD_3 from "../../../assets/images/CranioMaxiloFacial/instrumental.svg";
import MiniKit from '../../../assets/images/CranioMaxiloFacial/mini-kit.png'

import Fractureplating from '../../../assets/images/CranioMaxiloFacial/Cranios/fractureplating.png'
import ImfSystem from '../../../assets/images/CranioMaxiloFacial/Cranios/imf.png'
import Mandibular from '../../../assets/images/CranioMaxiloFacial/Cranios/mandibular.png'
import Mediumplating from '../../../assets/images/CranioMaxiloFacial/Cranios/mediumplating.png'
import MiniplatingLow from '../../../assets/images/CranioMaxiloFacial/Cranios/microplating-low-profile.png'
import Microplating from '../../../assets/images/CranioMaxiloFacial/Cranios/microplating.png'
import Miniplating from '../../../assets/images/CranioMaxiloFacial/Cranios/miniplating.png'
import RegisterMark from "../../../components/registerMark";

const cardsImage = [CARD_1,CARD_2,CARD_3];

const MaxiloFacial = (props) => {
	const CANTIDAD_CARDS = 3;

	const renderCard = (i) => {
		return(
			<Col className="card">
				<Col md={2} className="content-image p-0">
					<div className="circle"></div>
					<Image alt="" className="img-card" src={`${cardsImage[i]}`}></Image>
				</Col>
				<Col md={10} className="info p-0">
					<div className="info-title">
						<h1>
							<Trans>maxillofacial.cards.{`${i + 1}`}.title</Trans>
							<Dot/>
						</h1>
						<h2><Trans>maxillofacial.cards.{`${i + 1}`}.subtitle</Trans></h2>
					</div>
					<div className="info-text">
						<h3>
							<RegisterMark>{`maxillofacial.cards.${i + 1}.content`}</RegisterMark>
							<RegisterMark>{`maxillofacial.cards.${i + 1}.content_extended`}</RegisterMark>
							<RegisterMark>{`maxillofacial.cards.${i + 1}.content_extended_extra`}</RegisterMark>
						</h3>
					</div>
				</Col>
			</Col>
		);
	}

	const renderCards = () => {
		let render = []
		let i;
		for (i = 1; i < CANTIDAD_CARDS; i++) {
			render.push(
				renderCard(i)
			)
		}
		return render;
	}

	return (
		<Styles>
			<div id="container">
				{/* <div className="gradient-violet-one"></div>
				<div className="gradient-two"></div>
				<div className="gradient-three"></div>
				<div className="gradient-four"></div> */}

				<div id="section1" className="section section1">
					<div className="first-gradient"></div>
					<Image alt="" className="violet-wave" src={`${VioletWaveImg}`}></Image>
					<div className="content-info">
						<div className="col-6 content-text">
							<Row >
								<h1>
									<Trans>maxillofacial.self</Trans>
									<Dot/>
								</h1>
								<p> <Trans>maxillofacial.presentation_text</Trans> </p>
							</Row >
							<Row className="content-cards">
								{renderCard(0)}
							</Row>
						</div>
						<div className="col-6 img-content">
							<Image alt="" className="img-kit" src={`${MiniKit}`}></Image>
						</div>
					</div>
				</div>

				<div className="last-section">
					<div className="second-gradient"></div>
					<div id="section2" className="section section2">
						{renderCards()}
					</div>

					<div id="section3" className="section section3">
						<FaicoSystemCranio></FaicoSystemCranio>
					</div>
				</div>
			</div>
		</Styles>
	)

}
function FaicoSystemCranio(props) {

	const dataCard = [
		{
			image : Microplating,
			url   :'/maxillofacial/microplating_system'
		},
		{
			image : Mediumplating,
			url   :'/maxillofacial/mediumplating_system'
		},
		{
			image : MiniplatingLow,
			url   :'/maxillofacial/miniplating_low_system'
		},
		{
			image : Miniplating ,
			url   : '/maxillofacial/miniplating_standard_system'
		},
		{
			image : Fractureplating,
			url   : '/maxillofacial/fracture_system'
		},
		{
			image : Mandibular,
			url   : '/maxillofacial/mandibular_reconstruction'
		},
		{
			image : ImfSystem,
			url   : '/maxillofacial/imf_system'
		}
	]

	const cantCards = 8;
	function renderCards() {
		let render = []

		for (let i = 1; i < cantCards; i++) {
			render.push(
				<Col md={3} key={i} className="content-card zoomCard"
					onClick={() => navigate(dataCard[i-1].url)}
				>
					<div className="card">
						<div className="card-titles">
							<div className="title-container">
								<div className="col-8 p-0">
									<h1>
										<Trans>maxillofacial.systems.products.{`${i}`}.title</Trans>
										<br/>
										<Trans>maxillofacial.systems.products.{`${i}`}.title2</Trans>
									</h1>
								</div>
								<div className="col-4 content-img p-0">
									<div className="img-square"></div>
									<img alt="" src={dataCard[i-1].image} />
								</div>
							</div>

							<h2><Trans>maxillofacial.systems.products.{`${i}`}.subtitle</Trans></h2>
						</div>
					</div>
				</Col>
			)
		}
		return render;
	}
	return (
		<FaicoSystemCranioStyles>
			<div className="content">
				<div className="titles">
					<h1 className="h1-flex">
						<RegisterMark>maxillofacial.systems.title</RegisterMark>
						<Dot/>
					</h1>
					<h2>
						<Trans>maxillofacial.systems.subtitle</Trans><br/>
						<RegisterMark>maxillofacial.systems.subtitle_extended</RegisterMark>
					</h2>
				</div>
				<Row className="content-cards">
					{renderCards()}
				</Row>
			</div>
		</FaicoSystemCranioStyles>
	)
}

function Dot() {
	return ( <DotStyles>.</DotStyles> );
}

export default (MaxiloFacial);