import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_1.png"
import Image_1_2 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_2.png"
import Image_1_3 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_3.png"
import Image_1_4 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_4.png"
import Image_1_5 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_5.png"
import Image_1_6 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_6.png"
import Image_1_7 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_7.png"
import Image_1_8 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_8.png"
import Image_1_9 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_9.png"
import Image_1_10 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_10.png"
import Image_1_11 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_11.png"
import Image_1_12 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_12.png"
import Image_1_13 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_13.png"
import Image_1_14 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_14.png"
import Image_1_15 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_15.png"
import Image_1_16 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Plates/image_16.png"

export default function dataGenerator(lng) {
//titulo
	const platesData_1_description_en = ["Straight Plate. 8-holes.", "Straight Plate. 11-holes.", "Straight Plate. 14-holes.", "Straight Plate. 17-holes."]
	const platesData_1_description_es = ["Placa Recta. 8-agujeros.", "Placa Recta. 11-agujeros.", "Placa Recta. 14-agujeros.", "Placa Recta. 17-agujeros."]

	const platesData_1_details_en = ['','','','','','','','']
	const platesData_1_details_es = ['','','','','','','','']
//titulo
	const platesData_2_description_en = ["L Plate - 65°.", "L Plate - 65°."]
	const platesData_2_description_es = ["Placa L - 65°.", "Placa L - 65°."]

	const platesData_2_detail_en = ["4+8 holes. Left.","4+8 holes. Right."]
	const platesData_2_detail_es = ["4+8 agujeros. Izquierda.", "4+8 agujeros. Derecha."]

	const platesData_3_description_en = ["L Plate - 65°.","L Plate - 65°."]
	const platesData_3_description_es = ["Placa L - 65°.", "Placa L - 65°."]

	const platesData_3_detail_en = ["4+11 holes. Left.","4+11 holes. Right."]
	const platesData_3_detail_es = ["4+11 agujeros. Izquierda.", "4+11 agujeros. Derecha."]
	
	const platesData_4_description_en = ["L Plate - 65°.","L Plate - 65°.","L Plate - 65°.","L Plate - 65°."]
	const platesData_4_description_es = ["Placa L - 65°.", "Placa L - 65°.", "Placa L - 65°.", "Placa L - 65°."]

	const platesData_4_detail_en = ["4+14 holes. Left.","4+14 holes. Right.","6+14 holes. Left.","6+14 holes. Right."]
	const platesData_4_detail_es = ["4+14 agujeros. Izquierda.", "4+14 agujeros. Derecha.", "6+14 agujeros. Izquierda.", "6+14 agujeros. Derecha."]
//titulo
	const platesData_5_description_en = ["Double L Plate - 65°.","Double L Plate - 65°.", "Double L Plate - 65°.","Double L Plate - 65°."]
	const platesData_5_description_es =   ["Doble Placa L - 65°.", "Doble Placa L - 65°.","Doble Placa L - 65°.", "Doble Placa L - 65°."]

	const platesData_5_detail_en = ["4+17+4 holes.","4+19+4 holes.","6+17+6 holes.","6+19+6 holes."]
	const platesData_5_detail_es =   ["4+17+4 agujeros.", "4+19+4 agujeros.", "6+17+6 agujeros.", "6+19+6 agujeros."]


	const platesData_1 = [
		["MRP-2.2-8-S", "MRP-2.2-11-S", "MRP-2.2-14-S", "MRP-2.2-17-S"],
		[Image_1_1, Image_1_2, Image_1_3, Image_1_4],
		lng === "en" ? platesData_1_description_en : platesData_1_description_es,
		lng === "en" ? platesData_1_details_en : platesData_1_details_es,
		[2,3,4,5]
	]
	const platesData_2 = [
		["MRP-2.2-4+8-L-L", "MRP-2.2-4+8-L-R"],
		[Image_1_5, Image_1_6],
		lng === "en" ? platesData_2_description_en : platesData_2_description_es,
		lng === "en" ? platesData_2_detail_en : platesData_2_detail_es,
		[4,3]
	]
	const platesData_3 = [
		["MRP-2.2-4+11-L-L", "MRP-2.2-4+11-L-R"],
		[Image_1_7, Image_1_8],
		lng === "en" ? platesData_3_description_en : platesData_3_description_es,
		lng === "en" ? platesData_3_detail_en : platesData_3_detail_es,
		[5,4]
	]
	const platesData_4 = [
		["MRP-2.2-4+14-L-L", "MRP-2.2-4+14-L-R", "MRP-2.2-6+14-L-L", "MRP-2.2-6+14-L-R"],
		[Image_1_9, Image_1_10, Image_1_11, Image_1_12],
		lng === "en" ? platesData_4_description_en : platesData_4_description_es,
		lng === "en" ? platesData_4_detail_en : platesData_4_detail_es,
		[6,5,7,5]
	]
	const platesData_5_1 = [
		["MRP-2.2-4+17+4-LL", "MRP-2.2-4+19+4-LL","MRP-2.2-6+17+6-LL", "MRP-2.2-6+19+6-LL"],
		[Image_1_13, Image_1_14,Image_1_15, Image_1_16],
		lng === "en" ? platesData_5_description_en : platesData_5_description_es,
		lng === "en" ? platesData_5_detail_en : platesData_5_detail_es,
		[12,10,11,10]
	]

	let data = {
		platesData_1,
		platesData_2,
		platesData_3,
		platesData_4,
		platesData_5_1
	}
	return data;
}
