import React,{useState} from 'react'
import Plates from "../Plates"
import dataGenerator from "./data.js";
import i18n from "i18next";

const MandibularReconstructionPlates = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language));
	i18n.on('languageChanged', function (lng) {
		setData(dataGenerator(lng))
	})
	return (
		<Plates
			self={"mandibularReconstruction"}
			navigate={"mandibular_reconstruction"}
			titles={["mandibularReconstruction.plates.titles.1","mandibularReconstruction.plates.titles.2","mandibularReconstruction.plates.titles.4","mandibularReconstruction.plates.titles.3","mandibularReconstruction.plates.titles.5_1"]}
			data={[data.platesData_1,data.platesData_2,data.platesData_3,data.platesData_4,data.platesData_5_1]}
		/>
	)
}

export default (MandibularReconstructionPlates)