export const dataSection_1 = [
  ["FRMS-2.3-C-4.0-TI-SC", "FRMS-2.3-C-5.0-TI-SC", "FRMS-2.3-C-6.0-TI-SC", "FRMS-2.3-C-7.0-TI-SC", "FRMS-2.3-C-8.0-TI-SC", "FRMS-2.3-C-9.0-TI-SC", "FRMS-2.3-C-10.0-TI-SC", "FRMS-2.3-C-11.0-TI-SC", "FRMS-2.3-C-12.0-TI-SC", "FRMS-2.3-C-13.0-TI-SC", "FRMS-2.3-C-14.0-TI-SC", "FRMS-2.3-C-15.0-TI-SC", "FRMS-2.3-C-16.0-TI-SC", "FRMS-2.3-C-17.0-TI-SC", "FRMS-2.3-C-18.0-TI-SC", "FRMS-2.3-C-19.0-TI-SC", "FRMS-2.3-C-20.0-TI-SC"],
  ["Ø2.3 x 4 mm. ", "Ø2.3 x 5 mm. ", "Ø2.3 x 6 mm. ", "Ø2.3 x 7 mm. ", "Ø2.3 x 8 mm. ", "Ø2.3 x 9 mm. ", "Ø2.3 x 10 mm. ", "Ø2.3 x 11 mm. ", "Ø2.3 x 12 mm. ", "Ø2.3 x 13 mm. ", "Ø2.3 x 14 mm. ", "Ø2.3 x 15 mm. ", "Ø2.3 x 16 mm. ", "Ø2.3 x 17 mm. ", "Ø2.3 x 18 mm. ", "Ø2.3 x 19 mm. ", "Ø2.3 x 20 mm. "]
]
export const dataSection_2 = [
  ["FRMS-2.3-H-4.0-TI-SC", "FRMS-2.3-H-5.0-TI-SC", "FRMS-2.3-H-6.0-TI-SC", "FRMS-2.3-H-7.0-TI-SC", "FRMS-2.3-H-8.0-TI-SC", "FRMS-2.3-H-9.0-TI-SC", "FRMS-2.3-H-10.0-TI-SC", "FRMS-2.3-H-11.0-TI-SC", "FRMS-2.3-H-12.0-TI-SC", "FRMS-2.3-H-13.0-TI-SC", "FRMS-2.3-H-14.0-TI-SC", "FRMS-2.3-H-15.0-TI-SC", "FRMS-2.3-H-16.0-TI-SC", "FRMS-2.3-H-17.0-TI-SC", "FRMS-2.3-H-18.0-TI-SC", "FRMS-2.3-H-19.0-TI-SC", "FRMS-2.3-H-20.0-TI-SC"],
  ["Ø2.3 x 4 mm. ", "Ø2.3 x 5 mm. ", "Ø2.3 x 6 mm. ", "Ø2.3 x 7 mm. ", "Ø2.3 x 8 mm. ", "Ø2.3 x 9 mm. ", "Ø2.3 x 10 mm. ", "Ø2.3 x 11 mm. ", "Ø2.3 x 12 mm. ", "Ø2.3 x 13 mm. ", "Ø2.3 x 14 mm. ", "Ø2.3 x 15 mm. ", "Ø2.3 x 16 mm. ", "Ø2.3 x 17 mm. ", "Ø2.3 x 18 mm. ", "Ø2.3 x 19 mm. ", "Ø2.3 x 20 mm. "]
]
export const dataSection_3 = [
  ["FRMS-2.7-EC-6.0-TI-SC", "FRMS-2.7-EC-8.0-TI-SC", "FRMS-2.7-EC-10.0-TI-SC", "FRMS-2.7-EC-12.0-TI-SC", "FRMS-2.7-EC-14.0-TI-SC", "FRMS-2.7-EC-16.0-TI-SC", "FRMS-2.7-EC-18.0-TI-SC", "FRMS-2.7-EC-20.0-TI-SC"],
  ["Ø2.7 x 6 mm.", "Ø2.7 x 8 mm.", "Ø2.7 x 10 mm.", "Ø2.7 x 12 mm.", "Ø2.7 x 14 mm.", "Ø2.7 x 16 mm.", "Ø2.7 x 18 mm.", "Ø2.7 x 20 mm."]
]