import React from 'react';
import Styles from './Styles';
import { Trans } from 'react-i18next';

import systems from '../../systems';
const Screws = ({ system }) => {
  return (
    <Styles>
      <p><Trans>{systems[system].screws.text}</Trans></p>
      <div className="item-list">
        {systems[system].screws.itemsCollection.map((item) => {
          return (
            <div>
              <div className="item">
                <span className="dot"></span>
                <p>{<Trans>{item.item}</Trans>}</p>
              </div>
              <div className="item-size">
                <p>{<Trans>{item.size}</Trans>}</p>
              </div>
            </div>
          )
        })}
      </div>
    </Styles>
  )
}

export default Screws