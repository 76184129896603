import { BASE_URL } from './BaseUrl'

export default ( id ) => {
  return fetch(`${ BASE_URL }/posts/${ id }`,
   {
     method: 'GET'
  })
    .then(response => response.json())
    .catch( (error) => {
     console.log('Error al hacer el request: ', error)
    })
}
