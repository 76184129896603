import styled from 'styled-components'
import {ContactFormStyles as Styles, ContactInformationStyles as InformationStyles} from '../../desktop/components/Styles'

export const ContactFormStyles = styled(Styles)`
    padding-top: 20px;
    width:100%;
    h1{
        font-size:24px;
        line-height:28px;
        padding-right:0px;
    }
    .contact-form{
        margin-top:20px;
        padding-right:0px;
    }
    .contact-form-button{
        width: 100%;
        font-size:15px;
    }

    .form-group input, .form-group textarea{
        font-size:15px;
    }

    .form-message{
        font-size: 17px;
    }
`
export const ContactInformationStyles = styled(InformationStyles)`
    width: 100%;
    padding:0px;
    padding-right:0px;
    
    .title{
        display:flex;
        justify-content: space-between;
        align-items:center;
        h1{
            font-size:15px;
        }
        img{
            width:29px;
            height:25px;
            padding-left:2px;
        }
        img:nth-child(1n) {
            margin-left:0px;
        }
        img:nth-child(2n) {
            width:26px;
            height:22px;
        }
        img:nth-child(3n) {
            width:27px;
            height:27px;
        }
    }
    .contact-about{
        display:none;
    }
`