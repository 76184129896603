import styled from 'styled-components'

export const Styles = styled.div`

`;

export const StyledHeader = styled.header`
	display: flex;
  align-items: center;
	width: 100%;
	height: 12vh;
	background:#1e1e1e;
  flex-direction: row;
  justify-content: space-between;
  position: sticky; top: 0;
  z-index: 999;
  .logo-container{
    margin-left: 24px;
    z-index:999;
    .img{
      width: 125px;
      height: 36px;
    }
  }
  &:focus, button:focus, .language-content:focus, .bm-item-list:focus{
    outline: none;
}
`;

export const StyledSideBar = styled.div`
/* Position and sizing of burger button */
&:focus{
  border: none;
}
.menu-content{
  background: #202020D3 0% 0% no-repeat padding-box;
  opacity: 1;
  border:none;
  display:flex;
  flex-flow: wrap column;
  padding-left: 27px;
  height: 100vh;

}
.button-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 23px;
  border:none;
  a{
    display: flex;
    justify-content: flex-start;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    padding: 0px;
    text-decoration: none;
  }
}
.language-content{
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  background: transparent;
  opacity: 1;
  flex: 1;
  background: transparent;
  .accordion:hover section:hover > .accordion_content,
	.accordion section.default > .accordion_content, .accordion_content {
    background: transparent;
  }
}

.bm-burger-button {
  width: 30px;
  height: 30px;
  margin-right: 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  top: 35px !important;
  right: 24px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: transparent;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  margin-top: 11.9vh;
  top: 0;
  height: 90vh !important;
  background: #202020D3 0% 0% no-repeat padding-box;
  opacity: 1;
  border:none;
}

/* General sidebar styles */
.bm-menu {
  height: 90vh;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Individual item */
.bm-item {
}

header{
  background: #202020D3 0% 0% no-repeat padding-box !important;
  .title{
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}
`;