import React from 'react'
import SmartImplant from '../../../../dental/mobile/LevelTwo/SmartImplant';
import image from "../../../../../assets/images/Is four.png";

const IsFour = (props) => {
	return (
		<SmartImplant
			image={image}
			reference={2}
			system='conicalConnection'
		/>
	)
}

export default IsFour