import React from 'react';
import { Trans } from 'react-i18next'
import { Image } from 'react-bootstrap';
import { navigate } from 'hookrouter';
import { FooterMobileStyles } from "./Styles";
import { useTranslation } from 'react-i18next'
import appInfo from '../../../../package.json'

/* Assets */
import faicoLogo from "../../../assets/images/logo.png";
import EmailImage from "../../../assets/icons/mail-icon.svg";
import PhoneImage from "../../../assets/icons/phone-icon-footer.svg";
import PointerImage from "../../../assets/icons/map-icon.svg";
import FacebookImage from "../../../assets/icons/facebook-white-icon.png";
import InstagramImage from "../../../assets/icons/instagram-icon.svg";
import TwitterImage from "../../../assets/icons/twitter-white-icon.png";
import ImageCustomer from "../../../assets/icons/customer-icon-footer.svg";

const FooterMobile = () => {
	const { t } = useTranslation()
	return (
		<FooterMobileStyles>
			<Image className="footer-logo" src={faicoLogo} alt="logo" />
			<div className="information-content">
				<div className="information">
					<div className="content-image">
						<Image src={EmailImage} />
					</div>
					<p><a className="link-email" href={`mailto:${t('footer.email')}`}><Trans>footer.email</Trans></a></p>
				</div>
				<div className="information">
					<div className="content-image">
						<Image src={PhoneImage} />
					</div>
					<p><Trans>general.phone</Trans></p>
				</div>
				<div className="information">
					<div className="content-image">
						<Image src={ImageCustomer} />
					</div>
					<p><Trans>general.customer_service</Trans></p>
				</div>
				<div className="information">
					<div className="content-image">
						<Image src={PointerImage} />
					</div>
					<div className="content-image">
						<p><Trans>footer.address_mobile1</Trans></p>
						<p><Trans>footer.address_mobile2</Trans></p>
					</div>
				</div>
				<div className="social-networks">
					<a href={"https://www.facebook.com/FAICO-105830101160831/"} target="_blank"><Image src={FacebookImage}></Image></a>
					<a href={"https://www.instagram.com/faico.us/"} target="_blank"><Image src={InstagramImage}></Image></a>				
					<a href={"https://twitter.com/FaicoMedical"} target="_blank"><Image src={TwitterImage}></Image></a>
				</div>
				<div className="copyright-content">
					<p><Trans>footer.faico_copyright</Trans></p>
				</div>
				<div className="legal-and-privacity-notice">
					<p onClick={() => navigate('/termsConditions')}><Trans>footer.terminos_y_condiciones</Trans></p>
					<p onClick={() => navigate('/noticePrivacy')}><Trans>footer.privacy_police</Trans></p>
					<p onClick={() => navigate('/legalNotice')}><Trans>footer.legal_notice</Trans></p>
				</div>
			</div>
			<br></br>
			<p className="version-tag">V-1.04</p>
		</FooterMobileStyles>
	)
}
export default FooterMobile;