import React from 'react'
import LevelOne from "../maxillofacial/desktop/LevelOne";
import Tornillos from "../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/level1/mini-tornillos.png"
import Placas from "../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/level1/mini-placa.png"
import Instrumental from "../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/level1/mini-instrumental.png"
import Sets from "../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/level1/mini-set.png"
const MiniplatingStandardSystem = (props) => {
	return (
		<LevelOne
			self={"miniplating_standard_system"}
			title={"maxillofacial.miniplatingStandardSystem.self"}
			subtitle={"maxillofacial.miniplatingStandardSystem.subtitle"}
			productImages={[Tornillos, Placas, Instrumental, Sets]}
			titles={["MINI-PLATING - MEDIUM-PROFILE SYSTEM - 2.0 mm. / 1.0 mm.", "MINI-PLATING - MEDIUM-PROFILE SYSTEM - 2.0 mm. / 0.75 mm.	"]}
			productTitle={"maxillofacial.miniplatingStandardSystem.products"}
			productDescription={"maxillofacial.miniplatingStandardSystem.products"}
			openPage={["/maxillofacial/miniplating_standard_system/screws", "/maxillofacial/miniplating_standard_system/plates", "/maxillofacial/miniplating_standard_system/instruments","/maxillofacial/miniplating_standard_system/sets"]}
			currentPage={4}
		/>
	)
}

export default (MiniplatingStandardSystem)