import React from 'react'
import { Image } from 'react-bootstrap';
import { Trans } from "react-i18next";

import { SystemListStyles } from './Styles'
import systems from '../systems';
import { navigate } from 'hookrouter';

const SystemList = () => {

  const SystemCard = ({ title, title_2, image, url }) => {
    return (
      <div className="system-card" onClick={() => navigate(url)}>
        <div className="title-container">
          <h1><Trans>{title}</Trans></h1>
          <h1><Trans>{title_2}</Trans></h1>
        </div>
        <div className="image-container">
          <Image src={image}></Image>
        </div>
      </div>
    )
  }
  const getLevelOneList = () => {
    return Object.keys(systems).map((system) => {
      return (
        <SystemCard
          title={systems[system].title}
          title_2={systems[system].title_2}
          image={systems[system].image}
          url={systems[system].url}
        />
      )
    })
  }

  return (
    <SystemListStyles>
      {getLevelOneList()}
    </SystemListStyles>
  )
}

export default SystemList