import styled from 'styled-components'

export const ImplantologiaModalStyles = styled.div`
		position:relative;
		width:100%;
		height:100%;

  .content-image{
    position:absolute;
    height:100%;
    width:100%;
    display: flex;
    align-items: center;
    img{
      position:relative;
      height:min-content;
      width:100%;
    }
  }
  .main-content{
    width: 100%;
    height: 100%;
    position:relative;
    .container-button{
      display: flex;
      justify-content: flex-end;
      margin: 30px 64px 0px 0px;
      @media screen and (max-width:1440px){
        margin: 5px 64px 0px 0px;
      }
      height:7%;
      button{
        background:none;
        border:none;
        font-size:24px;
        width:20px;
        height:20px;
        font-weight:700;
        padding:0px;
      }
    }
    .information-content{
      display:flex;
      margin-top:43px;
      padding-left: 90px;
      @media screen and (max-width:1440px){
        margin-top:15px;
      }
      .image-content{
        width:40%;
        justify-content: center;
        align-items: center;
        display: flex;
        img{
          width:78%;
        }
      }
      .information{
        width: 725px;
        height: 234px;
        padding-left: 37px;
        h1{
          padding-bottom: 14px;
          font-size:28px;
          font-family: 'Montserrat', sans-serif;
          @media screen and (max-width:1440px){
            font-size:24px;
          }
        }
      }
    }
    .title{
      display:flex;
      justify-content:flex-end;
      padding-left: 152px;
      h1{
        width:60%;
        font-size:34px;
        font-family: 'Montserrat', sans-serif;
        @media screen and (max-width:1440px){
          font-size:30px;
        }
      }
    }
    h1{
      color:white;
      font-weight:700;
	    font-family: 'Montserrat', sans-serif;
    }
    p{
      color:white;
      font-size:18px;
      line-height: 21px;
      font-family: 'Montserrat', sans-serif;
      @media screen and (max-width:1440px){
        font-size:16px;
      }
    }
  }
`