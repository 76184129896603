import styled from 'styled-components'

export const DentalLevelOneCardStyles = styled.div`
    width:max-content;
    height:max-content;
    background:black;

`
const Styles = styled.section`
    width: 100%;
    height:100%;
    position:relative;
    background: transparent linear-gradient(to  bottom, #6F3CEA 0%, #25144D 46%, #000000 100%) 0% 0% no-repeat padding-box;

    .my-card{
        margin-top:20px;
    }
    .container-page{
        padding: 20px;
        position:relative;
        z-index:2;
    }

    .container-image {
        width:100%;
        height: 345px;
        position:absolute;
        img{
            right:0;
            width: 69%;
            height: 100%;
            position:absolute;
            mix-blend-mode: soft-light;
        }
    }
    .microscpe-image{
        position:absolute;
        width:100%;
        height:470px;
        left:0;
        overflow:hidden;
        top: 45%;
        img{
            height:470px;
            width:70%;
            position:absolute;
            right:-85px;
            mix-blend-mode: soft-light;
        }
    }

    .container-title{
        padding: 7% 5% 12% 5%;

        h1 {
            color:white;
            font-size: 32px;
            line-height:54px;
            font-weight:700;
        }
        h3{
            color:white;
            font-size:15px;
            line-height:19px;
            font-weight:300;
        }

        h5{
            padding-left:2px;
            color: #49D3EA;
            line-height:13px;
            font-weight:bold;
            font-size:12px;
            margin-bottom:20px;
        }
        p{
            color:white;
            font-size:15px;
            font-weight:500;
            line-height:19px;
        }
    }

    .container-cards > div{
        margin-bottom:5%;
    }

    .container-surface{

        .image-title{
            .card-image{
                height:55px;
            }
            .first-col{
                padding-right:0px;
                padding-left:1px;
            }
            .second-col{
                padding: 1px 0px 0px 6px;
            }
            h2{
                width:90%;
                color:white;
                font-weight:700;
                font-size:22px;
                line-height:24px;
            }
            margin-bottom:16px;
        }

    }

    .container-dental{
        h2{
            font-weight:700;
            font-size:30px;
            line-height:34px;
            color:white;
        }
        p{
            margin-bottom:30px;
        }
    }

    .container-implants{
        h3{
            font-size:20px;
            line-height:25px;
            font-weight:700;
            color:white;
            margin-bottom:14px;
        }
        p{
            font-weight:300;
            margin-bottom:16px;
        }
    }

    /* accordion card prop */
    .card-description{
        padding-top:11px;
    }
    .card .border-title {
        width:95%;
        border-color:#49D3EA;
    }
    .card-title{
        h3{
            text-transform:none;
        }
    }
    .cardimplantologia{
        .card-title{
            position:relative;
            span{
                padding-bottom:5px;
                position: absolute;
                top:0px;
            }
        }
    }

`

export default Styles