import React from 'react'
import { Image } from 'react-bootstrap';


/* assets*/
import ImagePosition from "../../../assets/icons/location.svg";
import ImageEmail from "../../../assets/icons/email.svg";
import ImagePhone from "../../../assets/icons/phone.svg";
import { Trans } from "react-i18next";
import { withTranslation } from 'react-i18next';

const ContactInformation = ({socialNetworks}) => {
	const getSocialNetworks = () => {
		return socialNetworks.map( logo=> <Image src={`${logo}`} className="icon" ></Image>)
	}

	return (
		<>
			<div className="title">
				<div>
					<h1>
						<Trans>contact.contactInformation.title</Trans>
					</h1>
				</div>
				<div>
					{ getSocialNetworks() }
				</div>
			</div>

			<div className="contact-about">
				<div className="item">
					<Image src={`${ImageEmail}`} className="icon" ></Image>
					<p><Trans>contact.contactInformation.email</Trans></p>
				</div>
				<div className="item">
					<Image src={`${ImagePhone}`} className="icon-phone" ></Image>
					<p><Trans>general.phone</Trans></p>
				</div>
				<div className="item item-location">
					<Image src={`${ImagePosition}`} className="icon" ></Image>
					<div className="address-container">
						<p><Trans>contact.contactInformation.location.1</Trans></p>
						<p><Trans>contact.contactInformation.location.2</Trans></p>
					</div>
				</div>
			</div>
		</>
	);
}

export default withTranslation(['es']['contact'])(ContactInformation)