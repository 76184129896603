import React from "react";
import styled from 'styled-components'
import { css } from "styled-components";

/*----- Styles-----*/
const ArrowStyles = styled.div`
		${props =>
		props.width && css`
			width:${props.width};
			height:${props.height};
			margin:auto;
			.svg{
				transform:${props.rotateDeg};
			}
		`}

`


const Arrow = ({ rotateDeg, color, width, heigth, strokeWidth, id }) => {
	const rotate = "rotate(" + rotateDeg + "deg)"
	return (
		<ArrowStyles
			id="Arrow-Style"
			className='arrow-content'
			width={width}
			height={heigth}
			rotateDeg={rotate}
		>
			<svg
				className='svg'
				id={id}
				xmlns="http://www.w3.org/2000/svg"
				x="0"
				y="0"
				fill={color}
				width={width}
				height={heigth}
				stroke={color}
				strokeWidth={strokeWidth}
				enableBackground="new 0 0 477.175 477.175"
				version="1.1"
				viewBox="0 0 477.175 477.175"
				xmlSpace="preserve"
			>
				<path d="M145.188 238.575l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1l-215.4-215.5z"></path>
			</svg>
		</ArrowStyles>
	);
};
export default Arrow;
