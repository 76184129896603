/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { css } from "styled-components";

/*----- Commons -----*/
import Montserrat from "../../commons/mixins/fonts/Montserrat";

/*----- Styles -----*/

const H1 = styled.h1`
@media (min-width:1500px){
	${props => props.color && css`
		${Montserrat(50, 600, props.color)}
	`}
}
@media (max-width:1500px){
	${props => props.color && css`
	${Montserrat(28, 600, props.color)}
	`}
}
`;
const BigBackground = styled.h1`
  ${props =>
		props.color &&
		css`
	  ${Montserrat(350, 600, props.color)}
	`}
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 1;
  top: 0px;
  opacity: 0.33;
  margin-top: -145px;
  margin-left: -45px;
  letter-spacing: -15px;
`;

const H2 = styled.h2`
${props => props.color && css`
	${Montserrat(35, 600, props.color)}
`}
`;
const H3 = styled.h3`
${props => props.color && css`
	${Montserrat(18, 600, props.color)}
`}
`;
const H4 = styled.h4`
${props => props.color && css`
	${Montserrat(15, 600, props.color)}
`}
`;

const Home = styled.h1`
	${Montserrat(62, 700, 'white')};
	text-align: left;
	letter-spacing: 0px;
	line-height: 74px;
`;

const HomeFaico = styled.h2`
	${Montserrat(25, 700, '#49D3EA')};
	letter-spacing: 0px;
	line-height: 30px;
`;

const Dental = styled.h1`
margin: 0px;
padding: 0px;
position: relative;

opacity: 0.33;
margin-top: -145px;
margin-left: -45px;
letter-spacing: -15px;

@media (min-width:1500px){

	top: 0px;
	${props =>
		props.color &&
		css`
	${Montserrat(350, 700, props.color)}
	`}
}
@media (min-width:1280px) and (max-width:1500px){
	top:10%;
	${props =>
		props.color &&
		css`
	${Montserrat(280, 700, props.color)}
	`}
}
@media (max-width:1280px){
	top:20%;
	${props =>
		props.color &&
		css`
	${Montserrat(240, 700, props.color)}
	`}
}
`;
const FaicoMaxiloFacial = styled.h1`
margin: 0px;
padding: 0px;
position: relative;
z-index: 1;

opacity: 0.33;
margin-top: -145px;
margin-left: -45px;
letter-spacing: -15px;

@media (min-width:1500px){

	margin-top: 0%;
	${props =>
		props.color &&
		css`
	${Montserrat(350, 700, props.color)}
	`}
}
@media (max-width:1500px){
	top:10%;
	${props =>
		props.color &&
		css`
	${Montserrat(280, 700, props.color)}
	`}
}
`;

const Cranio = styled.h1`
margin: 0px;
padding: 0px;
position: relative;
z-index: 1;

opacity: 0.33;
margin-top: -145px;
margin-left: -45px;
letter-spacing: 0px;

@media (min-width:1500px){
	top: 30px;
	${props =>
		props.color &&
		css`
	${Montserrat(250, 700, props.color)}
	`}
}
@media (max-width:1500px){
	top:10%;
	${props =>
		props.color &&
		css`
	${Montserrat(280, 700, props.color)}
	`}
}
`;
const Maxilo = styled.h1`
margin: 0px;
padding: 0px;
position: relative;
z-index: 1;

opacity: 0.33;
margin-top: -145px;
margin-left: -45px;
letter-spacing: 0px;

@media (min-width:1500px){
	top: 82px;
	${props =>
		props.color &&
		css`
	${Montserrat(250, 700, props.color)}
	`}
}
@media (max-width:1500px){
	top:10%;
	${props =>
		props.color &&
		css`
	${Montserrat(280, 700, props.color)}
	`}
}
`;
const Facial = styled.h1`
margin: 0px;
padding: 0px;
position: relative;
z-index: 1;

opacity: 0.33;
margin-top: -145px;
margin-left: -45px;
letter-spacing: 0px;

@media (min-width:1500px){
	top: 135px;
	${props =>
		props.color &&
		css`
	${Montserrat(250, 700, props.color)}
	`}
}
@media (max-width:1500px){
	top:10%;
	${props =>
		props.color &&
		css`
	${Montserrat(280, 700, props.color)}
	`}
}
`;
/*----- Component -----*/
function Title({ content, size, color }) {
	switch (size) {
	case 1:
		return <H1 color={color}>{content}</H1>;
	case 2:
		return <H2 color={color}>{content}</H2>;
	case 3:
		return <H3 color={color}>{content}</H3>;
	case 4:
		return <H4 color={color}>{content}</H4>;
	case 'home':
		return <Home>{content}</Home>
	case 'home-faico':
		return <HomeFaico>{content}</HomeFaico>
	case "BigBackground":
		return <BigBackground color={color}>{content}</BigBackground>;
	case 'dental':
		return <Dental color={color}>{content}</Dental>
	case 'cranio':
		return <Cranio color={color}>{content}</Cranio>
	case 'maxilo':
		return <Maxilo color={color}>{content}</Maxilo>
	case 'facial':
		return <Facial color={color}>{content}</Facial>
	case 'faicomaxilofacial':
		return <FaicoMaxiloFacial color={color}>{content}</FaicoMaxiloFacial>
	default:
		return <H1 color={color}>{content}</H1>;
	}
}


export default Title;

/*----- PropTypes -----*/
Title.propTypes = {
	size: PropTypes.any.isRequired,
	content: PropTypes.oneOfType([
		PropTypes.object,PropTypes.string]).isRequired
};
