/*----- Imports -----*/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/*----- Styles -----*/
const StyledPersonCard = styled.article`
	border-bottom: 1px solid black;
`

/*----- Component -----*/
const PersonCard = ({ name, email, onClick }) => {
	return (
		<StyledPersonCard onClick={onClick}>
			<div>{name}</div>
			<div>{email}</div>
		</StyledPersonCard>
	)
}
export default PersonCard

/*----- PropTypes -----*/
PersonCard.propTypes = {
	name: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
}
