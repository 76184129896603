import React from 'react'
import { Col, Image, Row, Button } from "react-bootstrap"
import { Styles, ProductCardStyles } from "./Styles.js";
import { Trans } from "react-i18next";
import { navigate } from 'hookrouter';

import VioletWaveImg from "../../../../assets/images/onda-violeta-2.png";

const Plates = (props) => {
	function renderCards(data) {
		let render = []

		for (let card = 0; card < data[0].length; card++) {
			render.push(
				card === 2 ?
				<Col md={3}>
					{data.length === 4 ?
						<ProductCard
							productCod={data[0][card]}
							productDescription={data[1][card]}
							productSubDescription={data[2][card]}
							productImage={data[3][card]}
						/>
						:
						<ProductCard
							productCod={data[0][card]}
							productDescription={data[1][card]}
							productImage={data[2][card]}
							productSize={data[3] ? data[3][card] : ''}
						/>
					}
				</Col> :
				
				<Col md={2}>
					{data.length === 4 ?
						<ProductCard
							productCod={data[0][card]}
							productDescription={data[1][card]}
							productSubDescription={data[2][card]}
							productImage={data[3][card]}
						/>
						:
						<ProductCard
							productCod={data[0][card]}
							productDescription={data[1][card]}
							productImage={data[2][card]}
							productSize={data[3] ? data[3][card] : ''}
						/>
					}
				</Col>
			)
		}
		return render;
	}

	function renderRow(row) {
		let render = [];
		render.push(
			<Row >
				<h3><Trans>{props.titles[row]}</Trans></h3>
				<Row className="content-row-cards">
					{renderCards(props.data[row])}
				</Row>
			</Row>
		)
		return render;
	}
	function renderRows() {
		let render = [];
		for (let row = 0; row < props.titles.length; row++) {
			render.push(
				renderRow(row)
			)
		}
		return render;
	}
	return (
		<Styles>
			<div class="content-images-wave">
				<Image src={VioletWaveImg} className="image-wave img-fluid"></Image>
				<Image src={VioletWaveImg} className="image-wave-reverse img-fluid"></Image>
				<Image src={VioletWaveImg} className="image-wave img-fluid"></Image>
				<Image src={VioletWaveImg} className="image-wave-reverse img-fluid"></Image>
				<Image src={VioletWaveImg} className="image-wave img-fluid"></Image>
				<Image src={VioletWaveImg} className="image-wave-reverse img-fluid"></Image>
				<Image src={VioletWaveImg} className="image-wave img-fluid"></Image>
			</div>
			<div className="titles-container">
				<h1 className="title"><Trans>{props.self}.products.2.name</Trans></h1>
				<h2 className="subtitle"><Trans>{props.self}.products.2.description</Trans></h2>
			</div>
			{renderRows()}
			<div className="content-buttons pb-5">
				<Button onClick={() => navigate('/maxillofacial/' + props.navigate + '/screws')}><Trans>maxillofacial.button.screws</Trans></Button>
				<Button onClick={() => navigate('/maxillofacial/' + props.navigate + '/instruments')}><Trans>maxillofacial.button.instrument</Trans></Button>
				<Button onClick={() => navigate('/maxillofacial/' + props.navigate + '/sets')}><Trans>maxillofacial.button.sets</Trans></Button>
			</div>
		</Styles>
	)

}

const ProductCard = (props) => {
	return (
		<ProductCardStyles>
			<div className="content-image">
				<Image src={props.productImage} className="img-fluid" ></Image>
			</div>
			<div className="content-text">
				<h1>{props.productCod}</h1>
				<div className="description">
					<p>
						{props.productDescription}<br />
						{props.productSize}
					</p>
				</div>
				<div className="description">
					<p>{props.productSubDescription}</p>
				</div>
			</div>

		</ProductCardStyles>
	)
}
export default (Plates);
