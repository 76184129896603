import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_1.png"
import Image_1_2 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_2.png"
import Image_1_3 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_3.png"
import Image_1_4 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_4.png"
import Image_1_5 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_5.png"
import Image_1_6 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_6.png"
import Image_1_7 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_7.png"
import Image_1_8 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_8.png"
import Image_1_9 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_9.png"
import Image_1_10 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_10.png"
import Image_1_11 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_11.png"
import Image_1_12 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_12.png"
import Image_1_13 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_13.png"
import Image_1_14 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_14.png"
import Image_1_15 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_15.png"
import Image_1_16 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_16.png"
import Image_1_17 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_17.png"
import Image_1_18 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_18.png"
import Image_1_19 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_19.png"
import Image_1_20 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_20.png"
import Image_1_21 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_21.png"
import Image_1_22 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_22.png"
import Image_1_23 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_23.png"
import Image_1_24 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_24.png"
import Image_1_25 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_25.png"
import Image_1_26 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_26.png"
import Image_1_27 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_27.png"
import Image_1_28 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_28.png"
import Image_1_29 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_29.png"
import Image_1_30 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_30.png"
import Image_1_31 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_31.png"
import Image_1_32 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_32.png"
import Image_1_33 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_33.png"
import Image_1_34 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_34.png"
import Image_1_35 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_35.png"
import Image_1_36 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_36.png"
import Image_1_37 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_37.png"
import Image_1_38 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_38.png"
import Image_1_39 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_39.png"
import Image_1_40 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_40.png"
import Image_1_41 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_41.png"
import Image_1_42 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_42.png"
import Image_1_43 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_43.png"
import Image_1_44 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_44.png"
import Image_1_45 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_45.png"
import Image_1_46 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_46.png"
import Image_1_47 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_47.png"
import Image_1_48 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_48.png"
import Image_1_49 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_49.png"
import Image_1_50 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_50.png"
import Image_1_51 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_51.png"
import Image_1_52 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_52.png"
import Image_1_53 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_53.png"
import Image_1_54 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_54.png"
import Image_1_55 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_55.png"
import Image_1_56 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_56.png"
import Image_1_57 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_57.png"
import Image_1_58 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_58.png"
import Image_1_59 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_59.png"
import Image_1_60 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_60.png"
import Image_1_61 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_61.png"
import Image_1_62 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_62.png"
import Image_1_63 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_63.png"
import Image_1_64 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_64.png"
import Image_1_65 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Plates/image_65.png"

export default function dataGenerator(lng) {
    const platesData_1_description_en = ["Straight Plate. 2-holes.", "Straight Plate. 4-holes.", "Straight Plate. 6-holes.", "Straight Plate. 8-holes.", "Straight Plate. 16-holes.", "Straight Plate. 4-holes.", "Straight Plate. 4-holes.", "Straight Plate. 6-holes."]
    const platesData_1_description_es = ["Placa Recta. 2-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 6-agujeros.", "Placa Recta. 8-agujeros.", "Placa Recta. 16-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 4-agujeros.", "Placa Recta. 6-agujeros."]

    const platesData_1_detail_en = ["9 mm stem.", "", "", "", "", "9 mm stem.", "12 mm stem.", "12 mm stem."]
    const platesData_1_detail_es = ["9 mm puente.", "", "", "", "", "9 mm puente.", "12 mm puente.", "12 mm puente."]

    const platesData_2_description_en = ["L Plate. 4-holes. Left.", "L Plate. 4-holes. Right.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 4-holes.", "L Plate. 5-holes.", "L Plate. 5-holes.", "L Plate. 6-holes.", "L Plate. 6-holes.", "L Plate. 6-holes.", "L Plate. 6-holes."]
    const platesData_2_description_es = ["Placa L. 4-agujeros. Izquierda.", "Placa L. 4-agujeros. Derecha.", "Placa L. 4-agujeros.", "Placa L. 4-agujeros.", "Placa L. 4-agujeros .", "Placa L. 4-agujeros.", "Placa L. 4-agujeros.", "Placa L. 4-agujeros.", "Placa L. 5-agujeros.", "Placa L. 5-agujeros.", "Placa L. 6-agujeros.", "Placa L. 6-agujeros.", "Placa L. 6-agujeros.", "Placa L. 6-agujeros."]

    const platesData_2_detail_en = ["", "", "8 mm stem. Left.", "8 mm stem. Right.", "10 mm stem. Left.", "10 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right.", "9 mm stem. Left.", "9 mm stem. Right.", "12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_2_detail_es = ["", "", "8 mm puente. Izquierda.", "8 mm puente. Derecha.", "10 mm puente. Izquierda.", "10 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha.", "9 mm puente. Izquierda.", "9 mm puente. Derecha.", "12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_3_description_en = ["L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes.", "L Plate - 112°. 4-holes."]
    const platesData_3_description_es = ["Placa L - 112°. 4-agujeros.", "Placa L - 112°. 4-agujeros.", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 4-agujeros. ", "Placa L - 112°. 5-agujeros. ", "Placa L - 112°. 5-agujeros. "]

    const platesData_3_detail_en = ["8 mm stem. Left.", "8 mm stem. Right.", "10 mm stem. Left.", "10 mm stem. Right."]
    const platesData_3_detail_es = ["8 mm puente. Izquierda.", "8 mm puente. Derecha.", "10 mm puente. Izquierda.", "10 mm puente. Derecha."]

    const platesData_4_description_en = ["T Plate. 6-holes.", "T Plate. 5-holes."]
    const platesData_4_description_es = ["Placa T. 6-agujeros.", "Placa T. 5-agujeros."]

    const platesData_4_detail_en = ["", "12 mm stem."]
    const platesData_4_detail_es = ["", "12 mm puente."]

    const platesData_5_description_en = ["T Plate - 80°. 5-holes.", "T Plate - 80°. 5-holes."]
    const platesData_5_description_es = ["Placa T - 80°. 5-agujeros.", "Placa T - 80°. 5-agujeros."]

    const platesData_5_detail_en = ["12 mm stem. Left.", "12 mm stem. Right."]
    const platesData_5_detail_es = ["12 mm puente. Izquierda.", "12 mm puente. Derecha."]

    const platesData_6_description_en = ["Double T Plate. 6-holes.", "Double T Plate. 4-holes."]
    const platesData_6_description_es = ["Doble Placa T. 6-agujeros.", "Doble Placa T. 4-agujeros."]

    const platesData_6_detail_en = ["", "12 mm stem."]
    const platesData_6_detail_es = ["", "12 mm puente."]

    const platesData_7_description_en = ["Y Plate. 5-holes.", "Y Plate. 5-holes."]
    const platesData_7_description_es = ["Placa Y. 5-agujeros.", "Placa Y. 5-agujeros."]

    const platesData_7_detail_en = ["", "12 mm stem."]
    const platesData_7_detail_es = ["", "12 mm puente."]

    const platesData_8_description_en = ["Double Y Plate. 6-holes.", "Double Y Plate. 7-holes.", "Double Y Plate. 6-holes.", "Double Y Plate. 8-holes ."]
    const platesData_8_description_es = ["Doble Placa Y. 6-agujeros.", "Doble Placa Y. 7-agujeros.", "Doble Placa Y. 6-agujeros.", "Doble Placa Y. 8-agujeros."]

    const platesData_8_detail_en = ["", "", "12 mm stem.", "12 mm stem."]
    const platesData_8_detail_es = ["", "", "12 mm puente.", "12 mm puente."]

    const platesData_9_description_en = ["Orbital Plate. 6-holes.", "Orbital Plate. 8-holes.", "Orbital Plate. 10-holes.", "Orbital Plate. 12-holes."]
    const platesData_9_description_es = ["Placa Orbital. 6-agujeros.", "Placa Orbital. 8-agujeros.", "Placa Orbital. 10-agujeros.", "Placa Orbital. 12-agujeros."]

    const platesData_10_description_en = ["Z Plate. 4-holes.", "Z Plate. 4-holes .", "Z Plate. 4-holes ", "Z Plate. 4-holes."]
    const platesData_10_description_es = ["Placa Z. 4-agujeros.", "Placa Z. 4-agujeros.", "Placa Z. 4-agujeros.", "Placa Z. 4-agujeros."]

    const platesData_10_detail_en = ["8 mm stem. Left.", "8 mm stem. Right.", "14 mm stem. Left.", "14 mm stem. Right."]
    const platesData_10_detail_es = ["8 mm puente. Izquierda.", "8 mm puente. Derecha.", "14 mm puente. Izquierda.", "14 mm puente. Derecha."]

    const platesData_11_description_en = ["Y Nose Plate. 10-holes."]
    const platesData_11_description_es = ["Placa Nasal Y. 10-agujeros."]

    const platesData_12_description_en = ["H Nose Plate. 12-holes."]
    const platesData_12_description_es = ["Placa Nasal H. 12-agujeros."]

    const platesData_13_description_en = ["Burr Hole Cover.", "Burr Hole Cover."]
    const platesData_13_description_es = ["Placa de Recubrimiento de Tépano.", "Placa de Recubrimiento de Tépano."]

    const platesData_14_description_en = ["Triangular Plate. 3-holes."]
    const platesData_14_description_es = ["Placa Triangular. 3-agujeros."]

    const platesData_15_description_en = ["Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate."]
    const platesData_15_description_es = ["Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática."]

    const platesData_15_detail_en = ["2x2-holes.", "3x2-holes.", "4x2-holes.", "4x4-holes.", "6x4-holes."]
    const platesData_15_detail_es = ["2x2-agujeros.", "3x2-agujeros.", "4x2-agujeros.", "4x4-agujeros.", "6x4-agujeros."]

    const platesData_16_description_en = ["Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate."]
    const platesData_16_description_es = ["Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular."]

    const platesData_16_detail_en = ["2x2-holes.", "3x2-holes.", "4x2-holes.", "4x4-holes.", "6x4-holes."]
    const platesData_16_detail_es = ["2x2-agujeros.", "3x2-agujeros.", "4x2-agujeros.", "4x4-agujeros.", "6x4-agujeros."]

    const platesData_17_description_en = ["Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate."]
    const platesData_17_description_es = ["Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática."]

    const platesData_17_detail_en = ["14x2-holes.", "14x9-holes.", "35x12-holes."]
    const platesData_17_detail_es = ["14x2-agujeros.", "14x9-agujeros.", "35x12-agujeros."]

    const platesData_18_description_en = ["Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes.", "Chin Plate. 4-holes."]
    const platesData_18_description_es = ["Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros.", "Placa de Mentón. 4-agujeros."]

    const platesData_18_detail_en = ["Depth 2 mm.", "Depth 4 mm.", "Depth 6 mm.", "Depth 8 mm.", "Depth 10 mm.", "Depth 12 mm.", "Depth 14 mm."]
    const platesData_18_detail_es = ["Profundidad 2 mm.", "Profundidad 4 mm.", "Profundidad 6 mm.", "Profundidad 8 mm.", "Profundidad 10 mm.", "Profundidad 12 mm.", "Profundidad 14 mm."]

    const platesData_1 = [
        ["MLPP-0.6-2-S-MB", "MLPP-0.6-4-S", "MLPP-0.6-6-S", "MLPP-0.6-8-S", "MLPP-0.6-16-S", "MLPP-0.6-4-S-MB", "MLPP-0.6-4-S-LB", "MLPP-0.6-6-S-LB"],
        lng === "en" ? platesData_1_description_en : platesData_1_description_es,
        lng === "en" ? platesData_1_detail_en : platesData_1_detail_es,
        [Image_1_1, Image_1_2, Image_1_3, Image_1_4, Image_1_5, Image_1_6, Image_1_7, Image_1_8],
    ]
    const platesData_2 = [
        ["MLPP-0.6-4-L-L", "MLPP-0.6-4-L-R", "MLPP-0.6-4-L-L-SB", "MLPP-0.6-4-L-R-SB", "MLPP-0.6-4-L-L-MB", "MLPP-0.6-4-L-R-MB", "MLPP-0.6-4-L-L-LB", "MLPP-0.6-4-L-R-LB", "MLPP-0.6-5-L-L-LB", "MLPP-0.6-5-L-R-LB", "MLPP-0.6-6-L-L-MB", "MLPP-0.6-6-L-R-MB", "MLPP-0.6-6-L-L-LB", "MLPP-0.6-6-L-R-LB"],
        lng === "en" ? platesData_2_description_en : platesData_2_description_es,
        lng === "en" ? platesData_2_detail_en : platesData_2_detail_es,
        [Image_1_9, Image_1_10, Image_1_11, Image_1_12, Image_1_13, Image_1_14, Image_1_15, Image_1_16, Image_1_17, Image_1_18, Image_1_19, Image_1_20, Image_1_21, Image_1_22],
    ]
    const platesData_3 = [
        ["MLPP-0.6-4-L-L-SB-112", "MLPP-0.6-4-L-R-SB-112", "MLPP-0.6-4-L-L-MB-112", "MLPP-0.6-4-L-R-MB-112"],
        lng === "en" ? platesData_3_description_en : platesData_3_description_es,
        lng === "en" ? platesData_3_detail_en : platesData_3_detail_es,
        [Image_1_23, Image_1_24, Image_1_25, Image_1_26],
    ]
    const platesData_4 = [
        ["MLPP-0.6-6-T", "MLPP-0.6-5-T-LB"],
        lng === "en" ? platesData_4_description_en : platesData_4_description_es,
        lng === "en" ? platesData_4_detail_en : platesData_4_detail_es,
        [Image_1_27, Image_1_28],
    ]
    const platesData_5 = [
        ["MLPP-0.6-5-T-L-LB", "MLPP-0.6-5-T-R-LB"],
        lng === "en" ? platesData_5_description_en : platesData_5_description_es,
        lng === "en" ? platesData_5_detail_en : platesData_5_detail_es,
        [Image_1_29, Image_1_30],
    ]
    const platesData_6 = [
        ["MLPP-0.6-6-TT", "MLPP-0.6-4-TT"],
        lng === "en" ? platesData_6_description_en : platesData_6_description_es,
        lng === "en" ? platesData_6_detail_en : platesData_6_detail_es,
        [Image_1_31, Image_1_32],

    ]
    const platesData_7 = [
        ["MLPP-0.6-5-Y", "MLPP-0.6-5-Y-LB"],
        lng === "en" ? platesData_7_description_en : platesData_7_description_es,
        lng === "en" ? platesData_7_detail_en : platesData_7_detail_es,
        [Image_1_33, Image_1_34],
    ]
    const platesData_8 = [
        ["MLPP-0.6-6-YY", "MLPP-0.6-7-YY", "MLPP-0.6-6-YY-LB", "MLPP-0.6-8-YY-LB"],
        lng === "en" ? platesData_8_description_en : platesData_8_description_es,
        lng === "en" ? platesData_8_detail_en : platesData_8_detail_es,
        [Image_1_35, Image_1_36, Image_1_37, Image_1_38],
    ]
    const platesData_9 = [
        ["MLPP-0.6-6-O", "MLPP-0.6-8-O", "MLPP-0.6-10-O", "MLPP-0.6-12-O"],
        lng === "en" ? platesData_9_description_en : platesData_9_description_es,
        [Image_1_39, Image_1_40, Image_1_41, Image_1_42]
    ]
    const platesData_10 = [
        ["MLPP-0.6-4-Z-L", "MLPP-0.6-4-Z-R", "MLPP-0.6-4-Z-L-ELB", "MLPP-0.6-4-Z-R-ELB"],
        lng === "en" ? platesData_10_description_en : platesData_10_description_es,
        lng === "en" ? platesData_10_detail_en : platesData_10_detail_es,
        [Image_1_43, Image_1_44, Image_1_45, Image_1_46],
    ]
    const platesData_11 = [
        ["MLPP-0.6-10-NY"],
        lng === "en" ? platesData_11_description_en : platesData_11_description_es,
        [Image_1_47]
    ]
    const platesData_12 = [
        ["MLPP-0.6-12-NH"],
        lng === "en" ? platesData_12_description_en : platesData_12_description_es,
        [Image_1_48]
    ]
    const platesData_13 = [
        ["MLPP-0.6-6-12-B", "MLPP-0.6-6-17-B"],
        lng === "en" ? platesData_13_description_en : platesData_13_description_es,
        ["Ø 12 mm.", "Ø 17 mm."],
        [Image_1_49, Image_1_50],
    ]
    const platesData_14 = [
        ["MLPP-0.6-03-V"],
        lng === "en" ? platesData_14_description_en : platesData_14_description_es,
        [Image_1_51]
    ]
    const platesData_15 = [
        ["MLPP-0.6-4-Q", "MLPP-0.6-6-Q", "MLPP-0.6-8-Q", "MLPP-0.6-16-Q", "MLPP-0.6-24-Q"],
        lng === "en" ? platesData_15_description_en : platesData_15_description_es,
        lng === "en" ? platesData_15_detail_en : platesData_15_detail_es,
        [Image_1_52, Image_1_53, Image_1_54, Image_1_55, Image_1_56]
    ]
    const platesData_16 = [
        ["MLPP-0.6-4-RC", "MLPP-0.6-6-RC", "MLPP-0.6-8-RC", "MLPP-0.6-16-RC", "MLPP-0.6-24-RC"],
        lng === "en" ? platesData_16_description_en : platesData_16_description_es,
        lng === "en" ? platesData_16_detail_en : platesData_16_detail_es,
        [Image_1_57, Image_1_58, Image_1_59, Image_1_60, Image_1_61,]
    ]
    const platesData_17 = [
        ["MLPP-0.6-14x2", "MLPP-0.6-14x9", "MLPP-0.6-35x12"],
        lng === "en" ? platesData_17_description_en : platesData_17_description_es,
        lng === "en" ? platesData_17_detail_en : platesData_17_detail_es,
        [Image_1_62, Image_1_63, Image_1_64]
    ]
    const platesData_18 = [
        ["MLPP-0.6-4-2L-LCH", "MLPP-0.6-4-4L-LCH", "MLPP-0.6-4-6L-LCH", "MLPP-0.6-4-8L-LCH", "MLPP-0.6-4-10L-LCH", "MLPP-0.6-4-12L-LCH", "MLPP-0.6-4-14L-LCH"],
        lng === "en" ? platesData_18_description_en : platesData_18_description_es,
        lng === "en" ? platesData_18_detail_en : platesData_18_detail_es,
        [Image_1_65, Image_1_65, Image_1_65, Image_1_65, Image_1_65, Image_1_65, Image_1_65]
    ]

    let data = {
        platesData_1,
        platesData_2,
        platesData_3,
        platesData_4,
        platesData_5,
        platesData_6,
        platesData_7,
        platesData_8,
        platesData_9,
        platesData_10,
        platesData_11,
        platesData_12,
        platesData_13,
        platesData_14,
        platesData_15,
        platesData_16,
        platesData_17,
        platesData_18
    }
    return data;
}