import React from 'react'
import LevelOne from '../../dental/mobile/LevelOne';

const GuidedSurgery = (props) => {
	return (
		<LevelOne
			system={"SurgicalGuided"}
		/>
	)
}

export default GuidedSurgery