import styled from "styled-components";
import Device from "../../commons/Device";

export default styled.main`
	.padding-cards {
		display: flex;
		margin-right: 0px;
		margin-left: 0px;
		justify-content: center;
	}
	.left-pos {
		padding-bottom: 2%;
		margin-left: 7.5%;
		@media ${Device.md} {
			margin-left: 4.5%;
		}
		@media ${Device.xs} {
			margin-left: 2.5%;
		}
	}
	.ml-5 {
		@media ${Device.xs} {
			margin-left: 1.5rem !important;
		}
	}
	h5{
		text-align:justify;
	}

`;
