export const dataSection_1 = [
  ["MICS-1.2-S-3.0-TI","MICS-1.2-S-4.0-TI","MICS-1.2-S-5.0-TI","MICS-1.2-S-6.0-TI","MICS-1.2-S-7.0-TI","MICS-1.2-S-8.0-TI","MICS-1.2-S-9.0-TI","MICS-1.2-S-10.0-TI","MICS-1.2-S-11.0-TI","MICS-1.2-S-12.0-TI","MICS-1.2-S-13.0-TI","MICS-1.2-S-14.0-TI","MICS-1.2-S-15.0-TI"],
  [" Ø1.2 x 3 mm."," Ø1.2 x 4 mm."," Ø1.2 x 5 mm."," Ø1.2 x 6 mm."," Ø1.2 x 7 mm."," Ø1.2 x 8 mm."," Ø1.2 x 9 mm."," Ø1.2 x 10 mm."," Ø1.2 x 11 mm."," Ø1.2 x 12 mm."," Ø1.2 x 13 mm."," Ø1.2 x 14 mm."," Ø1.2 x 15 mm."]
]
export const dataSection_2 = [
  ["MICS-1.2-C-3.0-TI-SC","MICS-1.2-C-4.0-TI-SC","MICS-1.2-C-5.0-TI-SC","MICS-1.2-C-6.0-TI-SC","MICS-1.2-C-7.0-TI-SC","MICS-1.2-C-8.0-TI-SC","MICS-1.2-C-9.0-TI-SC","MICS-1.2-C-10.0-TI-SC","MICS-1.2-C-11.0-TI-SC","MICS-1.2-C-12.0-TI-SC","MICS-1.2-C-13.0-TI-SC","MICS-1.2-C-14.0-TI-SC","MICS-1.2-C-15.0-TI-SC"],
  [" Ø1.2 x 3 mm."," Ø1.2 x 4 mm."," Ø1.2 x 5 mm."," Ø1.2 x 6 mm."," Ø1.2 x 7 mm."," Ø1.2 x 8 mm."," Ø1.2 x 9 mm."," Ø1.2 x 10 mm."," Ø1.2 x 11 mm."," Ø1.2 x 12 mm."," Ø1.2 x 13 mm."," Ø1.2 x 14 mm."," Ø1.2 x 15 mm."]
]
export const dataSection_3 = [
  ["MICS-1.4-ES-3.0-TI","MICS-1.4-ES-4.0-TI","MICS-1.4-ES-5.0-TI","MICS-1.4-ES-6.0-TI","MICS-1.4-ES-7.0-TI","MICS-1.4-ES-8.0-TI","MICS-1.4-ES-9.0-TI","MICS-1.4-ES-10.0-TI","MICS-1.4-ES-11.0-TI","MICS-1.4-ES-12.0-TI","MICS-1.4-ES-13.0-TI","MICS-1.4-ES-14.0-TI","MICS-1.4-ES-15.0-TI"],
  [" Ø1.4 x 3 mm."," Ø1.4 x 4 mm."," Ø1.4 x 5 mm."," Ø1.4 x 6 mm."," Ø1.4 x 7 mm."," Ø1.4 x 8 mm."," Ø1.4 x 9 mm."," Ø1.4 x 10 mm."," Ø1.4 x 11 mm."," Ø1.4 x 12 mm."," Ø1.4 x 13 mm."," Ø1.4 x 14 mm."," Ø1.4 x 15 mm."]
]
export const dataSection_4 = [
  ["MICS-1.4-EC-3.0-TI-SC","MICS-1.4-EC-4.0-TI-SC","MICS-1.4-EC-5.0-TI-SC","MICS-1.4-EC-6.0-TI-SC","MICS-1.4-EC-7.0-TI-SC","MICS-1.4-EC-8.0-TI-SC","MICS-1.4-EC-9.0-TI-SC","MICS-1.4-EC-10.0-TI-SC","MICS-1.4-EC-11.0-TI-SC","MICS-1.4-EC-12.0-TI-SC","MICS-1.4-EC-13.0-TI-SC","MICS-1.4-EC-14.0-TI-SC","MICS-1.4-EC-15.0-TI-SC"],
  [" Ø1.4 x 3 mm."," Ø1.4 x 4 mm."," Ø1.4 x 5 mm."," Ø1.4 x 6 mm."," Ø1.4 x 7 mm."," Ø1.4 x 8 mm."," Ø1.4 x 9 mm."," Ø1.4 x 10 mm."," Ø1.4 x 11 mm."," Ø1.4 x 12 mm."," Ø1.4 x 13 mm."," Ø1.4 x 14 mm."," Ø1.4 x 15 mm."]
]