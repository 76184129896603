export function dataGenerator(lng) {

  const dataSet_en = [" IMF System, Sterilization Case. (Empty)", " IMF Screw. Cross Head. Ø1.6 x 8mm. (Self-Tapping / Self-Drilling).", " IMF Screw. Cross Head. Ø1.6 x 9mm. (Self-Tapping / Self-Drilling).", " IMF Screw. Cross Head. Ø1.6 x 10mm. (Self-Tapping / Self-Drilling).", " IMF Screw. Cross Head. Ø1.6 x 11mm. (Self-Tapping / Self-Drilling)."," IMF Screw. Cross Head. Ø1.6 x 12mm. (Self-Tapping / Self-Drilling).", " Ligature wire blunt 0.5 x 160 mm. Pack of 10 Pcs.", " Ligature wire blunt 0.6 x 160 mm. Pack of 10 Pcs.", " Screwdriver detachable complete. Self Fitting. Cross Head."]
  const dataSet_es = [" IMF System, Estuche de Esterilización. (Vacío)", " IMF Tornillo. Cross Head. Ø1.6 x 8mm. (Autorroscante / Autoperforante).", " IMF Tornillo. Cross Head. Ø1.6 x 9mm. (Autorroscante / Autoperforante).", " IMF Tornillo. Cross Head. Ø1.6 x 10mm. (Autorroscante / Autoperforante).", " IMF Tornillo. Cross Head. Ø1.6 x 11mm. (Autorroscante / Autoperforante).", " IMF Tornillo. Cross Head. Ø1.6 x 12mm. (Autorroscante / Autoperforante).", " Alambre de Ligadura Romo 0.5 x 160 mm. Pack de 10 Pcs.", " Alambre de Ligadura Romo 0.6 x 160 mm. Pack de 10 Pcs.", " Destornillador desmontable completo. Autoajustable. Cross Head."]

  const dataSet = [
    ["IMF-998-100", "IMF-1.6-C-8.0-TI-SCD", "IMF-1.6-C-9.0-TI-SCD", "IMF-1.6-C-10.0-TI-SCD", "IMF-1.6-C-11.0-TI-SCD", "IMF-1.6-C-12.0-TI-SCD", "IMF-118-60", "IMF-101-66", "IMF-27218S"],
    lng === "en" ? dataSet_en : dataSet_es,
    ["1", "10", "10", "10", "10", "10", "5", "5", "1"]
  ]

  const dataScrew = [
    ["IMF-1.6-C-8.0-TI-SCD", "IMF-1.6-C-9.0-TI-SCD", "IMF-1.6-C-10.0-TI-SCD", "IMF-1.6-C-11.0-TI-SCD", "IMF-1.6-C-12.0-TI-SCD"],
    [" Ø1.6 x 8 mm. ", " Ø1.6 x 9 mm. ", " Ø1.6 x 10 mm.", " Ø1.6 x 11 mm. ", " Ø1.6 x 12 mm."]
  ]

  let data = {
    dataScrew,
    dataSet
  }
  return data;
}