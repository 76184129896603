import React, {useState} from 'react'
import Plates from "../../maxillofacial/desktop/Plates"
import dataGenerator from "./data.js";
import i18n from "i18next";

const MediumPlatingSystemPlates = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<Plates
			self={"mediumplatingSystem"}
			navigate={"mediumplating_system"}
      		titles={["mediumplatingSystem.plates.titles.1","mediumplatingSystem.plates.titles.2","mediumplatingSystem.plates.titles.3","mediumplatingSystem.plates.titles.4","mediumplatingSystem.plates.titles.5","mediumplatingSystem.plates.titles.6","mediumplatingSystem.plates.titles.7","mediumplatingSystem.plates.titles.8","mediumplatingSystem.plates.titles.9","mediumplatingSystem.plates.titles.10","mediumplatingSystem.plates.titles.11","mediumplatingSystem.plates.titles.12","mediumplatingSystem.plates.titles.13","mediumplatingSystem.plates.titles.14","mediumplatingSystem.plates.titles.15","mediumplatingSystem.plates.titles.16"]}
      		data={[data.platesData_1,data.platesData_2,data.platesData_3,data.platesData_4,data.platesData_5,data.platesData_6,data.platesData_7,data.platesData_8,data.platesData_9,data.platesData_10,data.platesData_11,data.platesData_12,data.platesData_13,data.platesData_14,data.platesData_15,data.platesData_16]}
		/>
	)
}

export default (MediumPlatingSystemPlates)