import React from 'react'
import Screws from "../../maxillofacial/desktop/Screws"
import * as data from "./data.js";

/* Assets */
import Image_1_0 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Screws/01_MRS-2.7-C-22 1.png"
import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Screws/image_1_1.png"
import Image_2_0 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Screws/02_MRS-3.0-EC-14.0-TI-SC (2)C.png"
import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/MandibularReconstruction/Screws/02_MRS-3.0-EC-14.0-TI-SC.png"

const MandibularReconstructionScrews = (props) => {
	return (
		<Screws
			self={"mandibular_reconstruction"}
			navigate={"mandibular_reconstruction"}
			title={"mandibularReconstruction.products.1.name"}
			subtitle={"mandibularReconstruction.products.1.description"}
			sectionTitles={["mandibularReconstruction.screws.1", "mandibularReconstruction.screws.2"]}
			sectionImagesFirst={[Image_1_0, Image_2_0]}
			sectionImagesSecond={[Image_1_1, Image_2_1]}
			sectionData={[data.dataSection_1, data.dataSection_2]}
		/>
	)
}

export default (MandibularReconstructionScrews)