import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_1.png"
import Image_2_2 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_2.png"
import Image_2_3 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_3.png"
import Image_2_4 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_4.png"
import Image_2_5 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_5.png"
import Image_2_6 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_6.png"
import Image_2_7 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_7.png"
import Image_2_8 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_8.png"
import Image_2_9 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_9.png"
import Image_2_10 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_10.png"
import Image_2_11 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_11.png"
import Image_2_12 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_12.png"
import Image_2_13 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_13.png"
import Image_2_14 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_14.png"
import Image_2_15 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_15.png"
import Image_2_16 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_16.png"
import Image_2_17 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_17.png"
import Image_2_18 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_18.png"
import Image_2_19 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_19.png"
import Image_2_20 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_20.png"
import Image_2_21 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_21.png"
import Image_2_22 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_22.png"
import Image_2_23 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_23.png"
import Image_2_24 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_24.png"
import Image_2_25 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_25.png"
import Image_2_27 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Instruments/image_27.png"

export default function dataGenerator(lng) {

  const instrumentsData_1_en = [" Plate and Wire cutting Plier with TC 18 cm.", " Plate bending plier with pin 13.5 cm.", " ADERER Plate bending plier 12.5 cm.", " Flat plier 13.5 cm.",]
  const instrumentsData_1_es = [" Alicate de corte de placa y alambre con TC 18 cm.", "Alicate para doblar placas con pin 13.5 cm.", "ADERER Alicate para doblar placas 12.5 cm.", "Alicate plano 13.5 cm."]

  const instrumentsData_2_en = [" Plate holding Clamps 14 cm. Straight.", " Plate holding Fork 18 cm. Straight.", " CUSHING Plate/Screw holding forcep 17.5 cm. Straight.", " CUSHING Plate/Screw holding forcep 17.5 cm. Angled."]
  const instrumentsData_2_es = [" Clamps de sujeción de placa 14 cm. Recta.", "Instrumento para sujeción de placa 18 cm. Recta.", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Recta.", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Angulada."]

  const instrumentsData_3_en = [" Screwdriver detachable complete. Standard Head.", " Screwdriver detachable complete. Cross Head.", " Screwdriver detachable complete. Hexagon Head.", " Screwdriver detachable complete. Square Head."]
  const instrumentsData_3_es = [" Destornillador desmontable completo. Standard Head.", " Destornillador desmontable completo. Cross Head.", " Destornillador desmontable completo. Hexagon Head.", " Destornillador desmontable completo. Square Head."]

  const instrumentsData_4_en = [ " Screwdriver detachable complete. Self Fitting. Cross Head.", " Screwdriver detachable complete. Self Fitting. Hexagon Head.", " Screwdriver detachable complete. Self Fitting. Square Head."]
  const instrumentsData_4_es = [" Destornillador desmontable completo. Autoajustable. Cross Head.", " Destornillador desmontable completo. Autoajustable. Hexagon Head.", " Destornillador desmontable completo. Autoajustable. Square Head."]

  const instrumentsData_5_en = [" Screwdriver fixed complete. Standard Head.", " Screwdriver fixed complete. Cross Head.", " Screwdriver fixed complete. Hexagon Head.", " Screwdriver fixed complete. Square Head."]
  const instrumentsData_5_es = [" Destornillador fijo completo. Standard Head.", " Destornillador fijo completo. Cross Head.", " Destornillador fijo completo. Hexagon Head.", " Destornillador fijo completo. Square Head."]

  const instrumentsData_6_en = [" Screwdriver fixed complete. Self Fitting. Cross Head.", " Screwdriver fixed complete. Self Fitting. Hexagon Head.", " Screwdriver fixed complete. Self Fitting. Square Head."]
  const instrumentsData_6_es = [" Destornillador fijo completo. Autoajustable. Cross Head.", " Destornillador fijo completo. Autoajustable. Hexagon Head.", " Destornillador fijo completo. Autoajustable. Square Head."]

  const instrumentsData_7_en = [" Depth Gauge 18 cm.", " Drill Guide with handle for 2.0 mm screws."]
  const instrumentsData_7_es = [" Medidor de profundidad 18 cm.", "Guía de perforación con mango para tornillos 2.0 mm."]

  const instrumentsData_8_en = [" Spiral-Drill with Sk-connection, Ø 1.5 mm. 7 mm stop. 50 mm."," Spiral-Drill with Sk-connection, Ø 1.5 mm. 7 mm stop. 70 mm."," Spiral-Drill with Sk-connection, Ø 1.5 mm. 22 mm stop. 50 mm."," Spiral-Drill with Sk-connection, Ø 1.5 mm. 22 mm stop. 70 mm."," Spiral-Drill with Sk-connection, Ø 1.5 mm. 22 mm stop. 105 mm."]
  const instrumentsData_8_es = [" Spiral-Drill con conexión-Sk, Ø 1.5 mm. 7 mm stop. 50 mm.", " Spiral-Drill con conexión-Sk, Ø 1.5 mm. 7 mm stop. 70 mm.", " Spiral-Drill con conexión-Sk, Ø 1.5 mm. 22 mm stop. 50 mm.", " Spiral-Drill con conexión-Sk, Ø 1.5 mm. 22 mm stop. 70 mm.", " Spiral-Drill con conexión-Sk, Ø 1.5 mm. 22 mm stop. 105 mm."]

  const instrumentsData_9_en = [" Transbuccal drilling guide complete for screwdriver without holding device / Ø 3.0 mm."," Transbuccal drilling guide complete for screwdriver with holding device / Ø 6.0 mm.s"]
  const instrumentsData_9_es = ["Guía de perforación transbucal completa para destornillador con dispositivo de sujeción / Ø 6.0 mm.", "Guía de perforación transbucal completa para destornillador sin dispositivo de sujeción / Ø 3.0 mm."]

  const instrumentsData_1 = [
    ["F2.0-70418", "F2.0-70213", "F2.0-70112", "F2.0-70313"],
    lng === "en" ? instrumentsData_1_en : instrumentsData_1_es,
    [Image_2_1, Image_2_2, Image_2_3, Image_2_4]
  ]
  const instrumentsData_2 = [
    ["F2.0-50013", "F2.0-22118", "F2.0-40018", "F2.0-45018"],
    lng === "en" ? instrumentsData_2_en : instrumentsData_2_es,
    [Image_2_5, Image_2_6, Image_2_7, Image_2_8]
  ]
  const instrumentsData_3 = [
    ["F2.0-20118", "F2.0-20218", "F2.0-20318", "F2.0-20418"],
    lng === "en" ? instrumentsData_3_en : instrumentsData_3_es,
    [Image_2_9, Image_2_10, Image_2_11, Image_2_12]
  ]
  const instrumentsData_4 = [
    ["F2.0-20218S", "F2.0-20318S", " F2.0-20418S"],
    lng === "en" ? instrumentsData_4_en : instrumentsData_4_es,
    [Image_2_13, Image_2_14, Image_2_15]
  ]
  const instrumentsData_5 = [
    ["F2.0-2F118", "F2.0-2F218", "F2.0-2F318", "F2.0-2F418"],
    lng === "en" ? instrumentsData_5_en : instrumentsData_5_es,
    [Image_2_16, Image_2_17, Image_2_18, Image_2_19]
  ]
  const instrumentsData_6 = [
    ["F2.0-2F218S", "F2.0-2F318S", "F2.0-2F418S"],
    lng === "en" ? instrumentsData_6_en : instrumentsData_6_es,
    [Image_2_20, Image_2_21, Image_2_22]
  ]
  const instrumentsData_7 = [
    ["F2.0-20018", "F2.0-28100"],
    lng === "en" ? instrumentsData_7_en : instrumentsData_7_es,
    [Image_2_23, Image_2_24]
  ]
  const instrumentsData_8 = [
    ["F2.0-15050", "F2.0-15070", "F2.0-15950", "F2.0-15970", "F2.0-15105"],
    lng === "en" ? instrumentsData_8_en : instrumentsData_8_es,
    [Image_2_25, Image_2_25, Image_2_25, Image_2_25, Image_2_25]
  ]
  const instrumentsData_9 = [
    ["F2.0-60000", "F2.0-30000"],
    lng === "en" ? instrumentsData_9_en : instrumentsData_9_es,
    [Image_2_27, Image_2_27]
  ]


  let data = {
    instrumentsData_1,
    instrumentsData_2,
    instrumentsData_3,
    instrumentsData_4,
    instrumentsData_5,
    instrumentsData_6,
    instrumentsData_7,
    instrumentsData_8,
    instrumentsData_9
  }
  return data;
}