import styled from 'styled-components'

const SmartImplantStyles = styled.div`
	width:100%;
	background: transparent linear-gradient(0deg, #6F3CEA 0%, #25144D 46%, #000000 100%) 0% 0% no-repeat padding-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px;
	h1, h2, h3, h4, h5, p{
		color: white;
	}
	.navigation{
		display: flex;
		flex-direction: column;
		width: 100%;
		.title-content{
      display:flex;
      align-items:flex-end;
      margin:61px 0px 32px 0px;
      h1{
        font-size:32px;
        color:white;
        font-weight:700;
        margin:0;
      }
      .dot{
        height: 6px;
        width: 6px;
        background-color: #49D3EA;
        border-radius: 50%;
        display: inline-block;
        margin:0px 0px 8px 3px;
      }
    }
		.button-content{
			margin-top: 60px;
			margin-bottom: 0px;
		}
	}
	.title-info{
		margin-top: 60px;
    padding: 0px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		h1{
			text-align: center;
			font-size: 22px;
			font-weight: 700;
			line-height: 27px;
		}
	}
	.image-content{
		width: 100%;
		margin-top: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		h2{
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 40px;
		}
		.image{
			width:30%;
			z-index: 2;
			margin-left: 21px;
		}
		.image-short{
			width:40%;
			z-index: 2;
			margin-left: 21px;
		}
		.image-isFour{
			width:35%;
			z-index: 2;
			margin-left: 21px;
		}
		.wave{
			position: absolute;
			width: -webkit-fill-available;
			left: 0;
			top: 20%;
			margin: 0px -20px;
			z-index: 1;
			max-height:200px;
		}
	}
	.description-content{
		margin-top: 45px;
		display: flex;
		flex-direction: column;
		align-items: center;
		h3{
			font-size: 15px;
			font-weight: 500;
			line-height: 17px;
		}
	}
	.references{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 50px;
		.reference-content{
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
			margin-bottom: 20px;
		}
		.circles{
			width: 15px;
			height: 15px;
			border-radius: 100%;
			font-size: 10px;
			font-weight: 700;
			color: white;
			text-align: center;
			background: #7161FF;
		}
		.reference-title{
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			h4{
				font-size: 14px;
				margin-left: 10px;
				font-weight: 700 !important;
			}
		}
		h5{
			font-size: 14px;
			font-weight: 500;
			line-height: 16px;
			margin-left: 25px;
		}
	}
	.button-content{
		margin-top: 40px;
		margin-bottom: 60px;
	}
`;

export default SmartImplantStyles;